<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <div class="row justify-content-center">
            <div class="col-md-8 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="card-title mb-0">Form Course</div>
                            <router-link :to="{name: 'manage_course'}" class="btn btn-light">Back</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="card-subtitle mt-0">Please complete the form below.</div>
                        <form class="forms-sample" v-on:submit.prevent="onSubmit">                            
                            <div class="form-group">
                                <label for="name">Name</label>
                                <input type="text" class="form-control" id="name" name="name" :class="{ 'is-invalid': submitted && $v.form.name.$error }" v-model="form.name" placeholder="Enter name ...">
                                <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                            </div>

                            <div class="form-group">
                                <label for="periode_id">Choose Year Period</label>
                                <multiselect v-model="form.periode_id" :options="list_periode" placeholder="Choose Year Period" :show-labels="false" label="name" track-by="name" :class="{ 'is-invalid': submitted && $v.form.periode_id.$error }">
                                    <template slot="option" slot-scope="props"><strong>{{ props.option.name }} {{ props.option.active == '1' ? '(Aktif)':'' }} </strong></template>
                                </multiselect>
                                <div v-if="submitted && !$v.form.periode_id.required" class="invalid-feedback">Year Period is required!</div>
                            </div>

                            <div class="form-group">
                                <label for="program_study_id">Choose Study Program</label>
                                <multiselect v-model="form.program_study_id" :options="list_study_program" placeholder="Choose Study Program" :show-labels="false" label="name" track-by="name" :class="{ 'is-invalid': submitted && $v.form.program_study_id.$error }" @input="getCourseCategory();getStudyGroup()">
                                    <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && !$v.form.program_study_id.required" class="invalid-feedback">Study Program is required!</div>
                            </div>

                            <div class="form-group">
                                <label for="courses_category_id">Choose Subject</label>
                                <multiselect v-model="form.courses_category_id" :options="list_category" placeholder="Choose Subject" :show-labels="false" label="name" track-by="name" :class="{ 'is-invalid': submitted && $v.form.courses_category_id.$error }">
                                    <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && !$v.form.courses_category_id.required" class="invalid-feedback">Subject is required!</div>
                            </div>

                            <div class="form-group">
                                <label for="users_id">Choose Teacher</label>
                                <multiselect v-model="form.users_id" :options="list_teacher" placeholder="Choose Teacher" :show-labels="false" label="fullname" track-by="fullname" :class="{ 'is-invalid': submitted && $v.form.users_id.$error }">
                                    <template slot="option" slot-scope="props"><strong>{{ props.option.fullname }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && !$v.form.users_id.required" class="invalid-feedback">Teacher is required!</div>

                                <!-- Add team teaching -->
                                <div class="badge badge-light rounded mt-2 p-3 w-100">
                                    <div class="d-block mb-3" v-for="(row, key) in form.team_teacher_id" :key="key">
                                        <div class="tx-14 text-muted text-left my-2">Choose Teacher {{key + 1}}</div>
                                        <div class="d-flex mb-1">
                                            <multiselect v-model="form.team_teacher_id[key]" :options="list_teacher" placeholder="Choose teacher" :show-labels="false" label="fullname" track-by="fullname">
                                                <template slot="option" slot-scope="props"><strong>{{ props.option.fullname }}</strong></template>
                                            </multiselect>
                                            <button type="button" class="btn btn-white ml-2 btn-xs" v-on:click="removeTeacher(key)"><i class="feather icon-trash"></i></button>
                                        </div>
                                    </div>
                                    <a href="javascript:void(0)" v-on:click="addTeacher()">
                                        <div class="tx-14 text-dark font-weight-bold text-left"><i class="feather icon-user-plus mr-2"></i>Add Team Teaching</div>
                                    </a>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="d-block">
                                    <div class="form-check form-check-inline">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="options_group_members" id="options_group_members" value="1" v-model="form.group_members" v-on:change="getStudyGroup()">
                                            Select Members Group
                                            <i class="input-frame"></i></label>
                                    </div>
                                    <div class="form-check form-check-inline ml-2">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input" name="options_group_members" id="options_group_members" value="0" v-model="form.group_members" v-on:change="getStudyGroup();getStudyGroupMember()">
                                            Select Members
                                            <i class="input-frame"></i></label>
                                    </div>
                                </div>

                            </div>


                            <div class="form-group">
                                <label for="status">Choose Study Group</label>
                                <multiselect v-model="form.study_group_id" :options="list_study_group" placeholder="Choose Study Group" :show-labels="false" label="name" track-by="name" :class="{ 'is-invalid': submitted && $v.form.study_group_id.$error }" @input="getStudyGroupMember()" :multiple="true">
                                    <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && !$v.form.study_group_id.required" class="invalid-feedback">Study Group is required!</div>                                
                            </div>

                            <div class="form-group" v-if="form.group_members == 0">
                                <label for="status">Choose Members</label>
                                <multiselect v-model="form.members_id" :options="list_study_group_members" placeholder="Choose Members" :show-labels="false" label="fullname" track-by="fullname" :class="{ 'is-invalid': submitted && $v.form.members_id.$error }" :multiple="true" style="height:auto !important;">
                                    <template slot="option" slot-scope="props"><strong>{{ props.option.fullname }}</strong></template>
                                </multiselect>
                                <div v-if="submitted && !$v.form.members_id.required" class="invalid-feedback">Member is required!</div>
                            </div>

                            <div class="form-group" v-if="!form.id">
                                <label for="total_sections">Total Sections</label>
                                <input type="number" class="form-control" id="total_sections" name="total_sections" autocomplete="off" v-model="form.total_sections" placeholder="Enter total_sections ..." min=1>
                            </div>

                            <div class="form-group">
                                <input type="checkbox" id="schedule" name="schedule" v-model="form.schedule" true-value="1"  false-value="0">
                                <label for="schedule" style="margin-top:-3px;margin-left:5px"> Add Schedule</label><br>
                            </div>

                            <div class="form-group" v-if="form.schedule == 1">
                                <label for="total_sections">Start date</label>
                                <date-picker v-model="form.start_date" class="w-100" valueType="format"></date-picker>
                            </div>

                            <div class="form-group" v-if="form.schedule == 1">
                                <label for="total_sections">Start Time</label>
                                <date-picker v-model="form.start_time" :showSecond="false" class="w-100" type="time" format="HH:mm" placeholder="HH:mm" valueType="format"></date-picker>
                            </div>

                            <div class="form-group" v-if="form.schedule == 1">
                                <label for="total_sections">End Time</label>
                                <date-picker v-model="form.end_time" :showSecond="false" class="w-100" type="time" format="HH:mm" placeholder="HH:mm" valueType="format"></date-picker>
                            </div>

                            <div class="form-group">
                                <label for="status">Status</label>
                                <select class="form-control" id="status" name="status" :class="{ 'is-invalid': submitted && $v.form.status.$error }" v-model="form.status">
                                    <option value="publish">Publish</option>
                                    <option value="draft">Draft</option>
                                </select>
                                <div v-if="submitted && !$v.form.status.required" class="invalid-feedback">Status is required!</div>
                            </div>

                            <div class="d-flex justify-content-end">
                                <button type="submit" class="btn btn-primary mr-2 mt-2">Save</button>
                                <router-link :to="{name: 'manage_course'}" class="btn btn-light mt-2">Back</router-link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'CoursesForm',
    metaInfo: {
        title: 'Form Courses',
        titleTemplate: '%s - Unfari E-learning'
    },
    components: {},
    created() {
        this.getDataByID(this.$route.params.id);
        this.getStudyProgram();
        this.getYear();
        this.listTeacher();
    },
    data() {
        return {
            id: this.$route.params.id,
            form: {
                id: "",
                code: "-",
                name: "",
                program_study_id: [],
                periode_id: [],
                courses_category_id: [],
                users_id: [],
                study_group_id: [],
                team_teacher_id: [],
                status: "draft",
                total_sections : 1,
                schedule : 0,
                start_date  : '',
                start_time  : '',
                end_time    : '',
                group_members : 1,
                members_id : []
            },
            list_study_program: [],
            list_periode: [],
            list_category: [],
            list_study_group: [],
            list_study_group_members: [],
            list_teacher: [],
            submitted: false,
            waiting: false
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            program_study_id: {
                required
            },
            periode_id: {
                required
            },
            courses_category_id: {
                required
            },
            study_group_id: {
                required
            },
            users_id: {
                required
            },
            status: {
                required
            },
            members_id: {
                required
            }
        }
    },
    methods: {
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let allowSubmit = true;

                if(this.form.schedule) {
                    if(this.form.start_date == '' || this.form.start_time == '' || this.form.end_time == '') {
                        allowSubmit = false;
                    }
                }
                if(allowSubmit) {
                    let formData = new FormData();

                    // get list study group id
                    var listStudyGroupId = [];
                    for (var keyStudyGroup in this.form.study_group_id) {
                        listStudyGroupId.push(this.form.study_group_id[keyStudyGroup].id)
                    }

                    this.form.study_group_id = listStudyGroupId;

                    // get id teacher
                    this.form.users_id = this.form.users_id.users_id;

                    // get id team teacher
                    var listTeamTeacherId = [];
                    for (var keyTeamTeacher in this.form.team_teacher_id){
                        listTeamTeacherId.push(this.form.team_teacher_id[keyTeamTeacher].id);
                    }
                    this.form.team_teacher_id = listTeamTeacherId;

                    this.form.program_study_id = this.form.program_study_id.id;
                    this.form.periode_id = this.form.periode_id.id;
                    this.form.courses_category_id = this.form.courses_category_id.id;
                    
                    var listMemberId = [];
                    for (var keyStudyGroup in this.form.members_id) {
                        listMemberId.push(this.form.members_id[keyStudyGroup].id)
                    }

                    this.form.members_id = JSON.stringify(listMemberId);

                    for (var index in this.form) {
                        formData.append(index, this.form[index]);
                    }

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/courses/save', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'manage_course'
                                    });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });
                    this.waiting = false;
                    return;
                }

            }
        },
        getDataByID: function (id) {
            if (typeof (id) !== 'undefined' && id !== null) {
                this.label = "Update";

                let uri = process.env.VUE_APP_APIHOST + 'admin/courses/detail';

                this.dataFetch = true;
                this.$http.get(uri, {
                    params: {
                        id: id
                    }
                }).then(res => {
                    if (res.data.status) {
                        let getResponse = res.data.data;
                        this.form = getResponse;

                        this.getStudyGroupMember();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'manage_course'
                                });
                            }
                        });
                    }
                });
            }
        },
        getYear: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/periode';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_periode = res.data.data;
                }
            });
        },
        getCourseCategory: function () {
            if(this.form.program_study_id.id) {
                let uri = process.env.VUE_APP_APIHOST + 'general/list/courses-category';
                let queryParams = {}
                queryParams.program_study_id = this.form.program_study_id.id
                this.$http.get(uri, {
                    params : queryParams                
                }).then(res => {
                    if (res.data.status) {
                        this.list_category = res.data.data;
                    }
                });
            }
        },
        getStudyGroup: function () {
            if(this.form.program_study_id.id) {
                let uri = process.env.VUE_APP_APIHOST + 'general/list/study-group';

                let queryParams = {}
                queryParams.program_study_id = this.form.program_study_id.id
                queryParams.group_members    = this.form.group_members

                this.$http.get(uri, {
                    params : queryParams                    
                }).then(res => {
                    if (res.data.status) {
                        this.list_study_group = res.data.data;
                    }
                });
            }
        },
        getStudyGroupMember: function () {
            if(this.form.study_group_id.length > 0) {
                if(this.form.id == '') {
                    this.form.members_id = [];
                }

                let uri = process.env.VUE_APP_APIHOST + 'general/list/study-group-member';

                var listStudyGroupId = [];
                for (var keyStudyGroup in this.form.study_group_id) {
                    listStudyGroupId.push(this.form.study_group_id[keyStudyGroup].id)
                }

                let queryParams = {}
                queryParams.study_group_id = JSON.stringify(listStudyGroupId)

                this.$http.get(uri, {
                    params : queryParams                    
                }).then(res => {
                    if (res.data.status) {
                        this.list_study_group_members = res.data.data;
                        if(this.form.group_members == 1) {
                            this.form.members_id = this.list_study_group_members;
                        } else {
                            if(this.form.id == '') {
                                this.form.members_id = [];
                            }
                        }
                    }
                });
            } else {
                this.list_study_group_members = [];
            }
        },
        listTeacher: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/teacher';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_teacher = res.data.data;
                }
            });
        },
        getStudyProgram: function() {
            let uri     = process.env.VUE_APP_APIHOST+'admin/program-study/list';

            this.$http.get(uri,{
            }).then(res => {
                if(res.data.status) {
                    this.list_study_program = res.data.data;
                    this.getCourseCategory();
                    this.getStudyGroup();

                }
            });
        },
        addTeacher: function () {
            this.form.team_teacher_id.push(0);
        },
        removeTeacher: function (index) {
            this.form.team_teacher_id.splice(index, 1);
        },
    }
}
</script>
