<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 mb-3">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-0">
                                <i class="mdi mdi-clipboard-search-outline"></i>
                                Search Filters</h4>
                            <p class="card-title-desc mb-0 text-muted">Please use the filter below to search data.</p>
                            <div class="row">
                                <div class="col-md-4 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <label for="exampleFormControlSelect1">Choose Curriculum</label>
                                        <multiselect v-model="params.curriculum_id" :options="dataCurriculum" placeholder="Choose Curriculum" :show-labels="false" label="name" track-by="name"  @input="filterData">
                                            <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <label for="exampleFormControlSelect1">Choose Study Program</label>
                                        <multiselect v-model="params.program_study_id" :options="dataProgramStudy" placeholder="Choose Study Program" :show-labels="false" label="name" track-by="name"  @input="filterData">
                                            <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                        </multiselect>
                                    </div>
                                </div>
                                <div class="col-md-4 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <label for="exampleFormControlSelect1">Keywords</label>
                                        <input type="text" class="form form-control" placeholder="Search data by code or name here ..." v-model="params.keywords" @input="debouncedHandler"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <h6 class="card-title">Subject</h6>
                                <router-link :to="{name: 'courses_category_add'}" class="btn btn-primary">Add Data</router-link>
                            </div>
                            <p class="card-description">Manage master data subject</p>
                            <div class="table-responsive" v-if="!dataFetch">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="3%">#</th>
                                            <th>Code</th>
                                            <th>Name</th>
                                            <th>Total SKS</th>
                                            <th>Study Program</th>
                                            <th>Curriculum</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataList.length > 0">
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td class="text-center" width="3%">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>{{item.code}}</td>
                                            <td>{{item.name}}</td>
                                            <td>{{item.sks}}</td>
                                            <td>{{item.program_study}}</td>
                                            <td>{{item.curriculum}}</td>
                                            <td>
                                                <label class="badge badge-success" v-if="item.status == 'publish'">Publish</label>
                                                <label class="badge badge-secondary" v-if="item.status == 'draft'">Draft</label>
                                            </td>
                                            <td>
                                                <router-link :to="{name: 'courses_category_edit', params: {id:item.id}}" class="btn btn-outline-primary">Edit</router-link>
                                                <button class="btn btn-danger ml-2" v-on:click="deleteData(item.id)">Delete</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="dataList.length == 0">
                                        <tr>
                                            <td colspan=8>
                                                <div class="d-block p-3 rounded bg-light">
                                                    <div class="text-center">Data not found</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                                <div class="text-center">loading data...</div>
                            </div>
                            <div class="d-block mt-3" v-if="dataList.length > 0">
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    
    import {debounce} from 'lodash'
    
    export default {
        name: 'CoursesCategory',
        metaInfo: {
            title: 'Subject',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination
        },
        data() {
            return {
                dataFetch: true,
                dataList: [],
                dataProgramStudy: [],
                dataCurriculum: [],
                params: {
                    program_study_id: '',
                    curriculum_id: '',
                    keywords: '',
                },
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
            }
        },
        created() {
            this.debouncedHandler = debounce(() => {
                this.filterData()
            }, 500);
        },
        mounted() {
            this.getData();
            this.getDataProgramStudy()
            this.getDataCurriculum()
        },
        beforeUnmount() {
            this.debouncedHandler.cancel();
        },
        methods: {
            getData: function() {
                // get curriculum id
                let uri         = process.env.VUE_APP_APIHOST+'admin/courses-category/list';
                let queryParams = {}

                if (this.params.curriculum_id)
                    queryParams.curriculum_id = this.params.curriculum_id.id
                if (this.params.program_study_id)
                    queryParams.program_study_id = this.params.program_study_id.id
                
                queryParams.keywords = this.params.keywords
                queryParams.page = this.pagination.currentPage
                queryParams.limit = this.pagination.limit

                this.dataList = []
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : queryParams
                }).then(res => {
                    this.dataFetch              = false;
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                }).catch(() => {
                    this.dataFetch = false
                });
            },
            filterData() {
                this.pagination.currentPage = 1
                this.getData()
            },
            getDataProgramStudy: function() {
                this.dataProgramStudy = []
                let uri         = process.env.VUE_APP_APIHOST+'general/list/program-study';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataProgramStudy.push({
                        id: '',
                        name: 'See All Study Program'
                    })
                    res.data.data.forEach(element => {
                        this.dataProgramStudy.push(element);
                    });
                });
            },
            getDataCurriculum: function() {
                this.dataCurriculum = []
                let uri         = process.env.VUE_APP_APIHOST+'general/list/curriculum';
                this.dataFetch  = true;                
                this.$http.get(uri).then(res => {
                    this.dataCurriculum.push({
                        id: '',
                        name: 'See All Curriculum'
                    })
                    res.data.data.forEach(element => {
                        this.dataCurriculum.push(element);
                    });
                });
            },
            deleteData: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Delete Data',
                    text: 'Are you sure you want to delete this data?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/courses-category/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
        }
    }
</script>