<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <div class="row justify-content-center">
            <div class="col-md-10 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-block">
                                <div class="card-title mb-0">Form Teacher</div>
                                <div class="card-subtitle mb-0 mt-0">{{description}}</div>
                            </div>
                            <router-link :to="{name: 'teacher'}" class="btn btn-light">Back</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-2 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="d-block mb-3 pb-2 text-center">
                            <div>
                                <img :src="preview_image" class="border-radius-1 w-100 border" />
                            </div>
                            <input type="file" @change="previewFiles" ref="file" accept="image/png, image/gif, image/jpeg" class="d-none" />
                            <button type="button" class="btn btn-outline-secondary btn-block mt-2" @click="$refs.file.click()">Choose Picture</button>
                        </div>
                        <div class="d-block mb-3 pb-2">
                            <ul class="nav nav-fill nav-pills flex-column">
                                <li class="nav-item">
                                    <a class="nav-link tx-15" href="javascript:void(0)" v-bind:class="{'active': currentTabComponent == 'profile', 'text-dark': currentTabComponent != 'profile', 'text-muted bg-light': !isFillComplete.profile && currentTabComponent != 'profile'}" v-on:click="changeTab('profile')"><i class="mdi mdi-account mr-2"></i>Profile</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link tx-15" href="javascript:void(0)" v-bind:class="{'active': currentTabComponent == 'academic', 'text-dark': currentTabComponent != 'academic', 'text-muted bg-light': !isFillComplete.academic && currentTabComponent != 'academic'}" v-on:click="changeTab('academic')"><i class="mdi mdi-tag mr-2"></i>Academic</a>
                                </li>
                                <li class="nav-item" v-if="!form.id">
                                    <a class="nav-link tx-15" href="javascript:void(0)" v-bind:class="{'active': currentTabComponent == 'password', 'text-dark': currentTabComponent != 'password', 'text-muted bg-light': !isFillComplete.password && currentTabComponent != 'password'}" v-on:click="changeTab('password')"><i class="mdi mdi-lock mr-2"></i>Password</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 grid-margin stretch-card">
                <keep-alive>
                    <component :form="form" v-on:listenerChild="listenerChild" :is="currentTabComponent"></component>
                </keep-alive>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
// import tab components
import profile from '@/components/tabs/admin/manage_teacher/Profile.vue';
import academic from '@/components/tabs/admin/manage_teacher/Academic.vue';
import password from '@/components/tabs/admin/manage_teacher/Password.vue';

export default {
    name: 'TeacherForm',
    metaInfo: {
        title: 'Form Teacher',
        titleTemplate: '%s - Unfari E-learning'
    },
    components: {
        profile,
        academic,
        password
    },
    created() {
        this.getDataByID(this.$route.params.id);
    },
    data() {
        return {
            currentTabComponent: 'profile',
            form: {
                // Tab profile
                id: "",
                identity_number: "",
                nidn: "",
                fullname: "",
                phone: "",
                email: "",
                place_birth: "",
                date_birth: "",
                gender: "male",
                religion: "",
                address: "",
                role_id: "3",

                // Tab academic
                program_study_id: "",
                education_level_id: "",
                status_teacher: "permanent",

                // Tab password
                password: "",
                confirm_password: "",
            },
            photo: '',
            isFillComplete: {
                profile: false,
                academic: false,
                password: false
            },
            preview_image: 'https://via.placeholder.com/513x342',
            submitted: false,
            waiting: false,
            description: "Created new teacher"
        }
    },
    methods: {
        listenerChild(response) {
            for (var index in response) {
                this.form[index] = response[index];
            }

            if (!this.isFillComplete.profile || !this.isFillComplete.academic || !this.isFillComplete.password) {
                if (this.currentTabComponent == 'profile') {
                    this.isFillComplete.profile = true;
                    this.changeTab('academic');
                } else if (this.currentTabComponent == 'academic' && !this.form.id) {
                    this.isFillComplete.academic = true;
                    this.changeTab('password');
                } else {
                    this.saveData();
                }
            } else {
                this.saveData();
            }
        },
        saveData: function () {
            this.waiting = true;
            this.submitted = true;
            let formData = new FormData();

            for (var index in this.form) {
                formData.append(index, this.form[index]);
            }
            formData.append('username', this.form.identity_number);
            formData.append('photo', this.photo);

            this.$http.post(process.env.VUE_APP_APIHOST + 'admin/account/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'teacher'
                                });
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
        },
        getDataByID: function (id) {
            if (typeof (id) !== 'undefined' && id !== null) {
                this.description = "Update current teacher";

                let uri = process.env.VUE_APP_APIHOST + 'admin/account/detail';

                this.dataFetch = true;
                this.$http.get(uri, {
                    params: {
                        id: id
                    }
                }).then(res => {
                    if (res.data.status) {
                        let getResponse = res.data.data;
                        this.form = getResponse;
                        if (this.form.photo)
                            this.preview_image = this.form.photo;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'teacher'
                                });
                            }
                        });
                    }
                });
            }
        },

        changeTab: function (tabActive) {
            var isEmpty = false
            var message = ""
            if (this.currentTabComponent != tabActive) {
                if (tabActive == 'academic' && !this.isFillComplete.profile) {
                    isEmpty = true
                    message = "Please complete the form tab profile and save the data first";
                } else if (tabActive == 'password') {
                    if (!this.isFillComplete.profile) {
                        isEmpty = true
                        message = "Please complete the form tab profile and save the data first";
                    } else if (!this.isFillComplete.academic) {
                        isEmpty = true
                        message = "Please complete the form tab academic and save the data first";
                    }
                } else if (this.currentTabComponent == 'password') {
                    if (!this.isFillComplete.password && !this.isFillComplete[tabActive]) {
                        isEmpty = true
                        message = "Please complete the form and save the data first";
                    }
                }
            }

            if (isEmpty) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: message,
                });
            } else {
                this.currentTabComponent = tabActive;
            }
        },

        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.preview_image = URL.createObjectURL(file);
                this.photo = file;
            }
        }
    }
}
</script>
