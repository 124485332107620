<template>
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="card-title mb-0">List of Member</div>
                <div class="card-subtitle"></div>
                <div class="d-flex justify-content-between">
                    <div class="card-subtitle">Manage class members below</div>                    
                    <div class="p-2">
                        <button class="btn btn-danger mr-2" v-if="checkID.length > 0" v-on:click="deleteMembers">Kick Members</button>
                    </div>
                </div>
                <div class="d-block mt-4">
                    <div class="form-group">
                        <input type="text" class="form-control" v-model="keywords" placeholder="Search member here ..." v-on:keyup.enter="getData()" />
                    </div>
                    <div class="table-responsive" v-if="!dataFetch">                    
                        <table class="table">
                            <thead>
                                <tr>
                                    <th width="3%">#</th>
                                    <th width="3%">No</th>
                                    <th>Identity Number</th>
                                    <th>Name</th>
                                    <th>Role</th>
                                    <th>Join Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in list_member" :key="row.id">
                                    <td>
                                        <input type="checkbox" class="form-control" :id="'id-'+row.id" :value="row.id" v-model="checkID">
                                    </td>
                                    <td>
                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                    </td>
                                    <td>{{row.identity_number}}</td>
                                    <td>{{row.name}}</td>
                                    <td class="text-capitalize">{{row.role}}</td>
                                    <td>{{row.join_date | formatDate()}}</td>
                                    <td><button class="btn btn-white" @click="deleteData(row.id)"><i class="feather icon-trash mr-2"></i>Delete</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                        <div class="text-center">loading data...</div>
                    </div>
                    <div class="d-block mt-3" v-if="list_member.length > 0">
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vPagination from 'vue-plain-pagination'

export default {
    name: 'Member',
    metaInfo: {
        title: 'Member of Class',
        titleTemplate: '%s - Elearning'
    },
    components: {
        vPagination,
    },
    data() {
        return {
            dataFetch: true,
            checkID: [],
            list_member: [],
            keywords: '',
            pagination: {
                currentPage : 1,
                totalPages  : 1,
                limit       : 10,
                bootstrapPaginationClasses: {
                    ul: 'pagination justify-content-end',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                paginationAnchorTexts: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            },
        }
    },
    props: ['params', 'fetchDetailClass'],
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/list-members';
            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    keywords : this.keywords,
                    courses_id : this.$route.params.id,
                    page     : this.pagination.currentPage,
                    limit    : this.pagination.limit
                }
            }).then(res => {
                this.dataFetch              = false;
                this.list_member            = res.data.data;
                this.pagination.totalPages  = res.data.total_page;
            });
        },
        deleteData: function (id) {
            this.$swal({
                icon: 'info',
                title: 'Kick Member From Class',
                text: 'Are you sure you want to kick this member?',
                showCancelButton: true,
                confirmButtonText: 'Kick',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/delete-members', {
                            id: id,
                            courses_id: this.params.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.fetchDetailClass();
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteMembers: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Kick Member From Class',
                    text: 'Are you sure you want to kick the selected data?',
                    showCancelButton: true,
                    confirmButtonText: 'Kick',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/delete-all-members', {
                        id: this.checkID
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
        }
    }
}
</script>
