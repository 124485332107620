<template>
    <nav class="navbar">
        <loading-page v-if="loading" />
        <a href="javascript:void(0)" class="sidebar-toggler" @click="toggleSidebar()">
            <i class="feather icon-menu tx-20 text-muted"></i>
        </a>
        <div class="navbar-content">
            <!--form class="search-form">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <div class="input-group-text">
                            <i class="feather icon-search"></i>
                        </div>
                    </div>
                    <input type="text" class="form-control" id="navbarForm" placeholder="Search here...">
                </div>
            </form-->
            <ul class="navbar-nav">
                <li class="nav-item dropdown nav-notifications">
                    <a class="nav-link dropdown-toggle mt-1" href="#" id="notificationDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="feather icon-bell tx-20"></i>
                        <div class="indicator">
                            <div class="circle"></div>
                        </div>
                    </a>
                    <div class="dropdown-menu" aria-labelledby="notificationDropdown">
                        <div class="dropdown-header d-flex align-items-center justify-content-between">
                            <p class="mb-0 font-weight-medium">Notifications</p>
                            <!-- <a href="javascript:;" class="text-muted">Clear all</a> -->
                        </div>
                        <div class="dropdown-body">
                            <!-- <a href="javascript:;" class="dropdown-item">
                                <div class="icon">
                                    <i data-feather="user-plus"></i>
                                </div>
                                <div class="content">
                                    <p>New customer registered</p>
                                    <p class="sub-text text-muted">2 sec ago</p>
                                </div>
                            </a> -->
                            <div class="dropdown-item tx-8 p-3 text-muted">No notifications at this time</div>
                        </div>
                        <!-- <div class="dropdown-footer d-flex align-items-center justify-content-center">
                            <a href="javascript:;">View all</a>
                        </div> -->
                    </div>
                </li>
                <li class="nav-item dropdown nav-profile">
                    <a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="images/default_user.png" alt="profile">
                    </a>
                    <div class="dropdown-menu" aria-labelledby="profileDropdown">
                        <div class="dropdown-header d-flex flex-column align-items-center">
                            <div class="figure mb-3">
                                <img src="images/default_user.png" alt="">
                            </div>
                            <div class="info text-center">
                                <p class="name font-weight-bold mb-0">{{users.name}}</p>
                                <p class="email text-muted mb-3 text-capitalize">{{users.role}}</p>
                            </div>
                        </div>
                        <div class="dropdown-body">
                            <ul class="profile-nav p-0 pt-3">
                                <li class="nav-item" v-if="users.role != 'student'">
                                    <router-link class="nav-item" :to="{name: 'my_profile'}">
                                        <div class="nav-link">
                                            <i class="feather icon-user mr-2"></i>
                                            <span>My Profile</span>
                                        </div>
                                    </router-link>
                                </li>
                                <router-link class="nav-item" :to="{name: 'signout'}">
                                    <div class="nav-link">
                                        <i class="feather icon-log-out mr-2"></i>
                                        <span>Log Out</span>
                                    </div>
                                </router-link>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
import LoadingPage from './LoadingPage.vue'
export default {
    name: 'NavBarMenu',
    components : {
        LoadingPage
    },
    data() {
        return {
            users: {},
            loading : true
        }
    },
    created() {
        this.fetchLocalData();

        setTimeout(() => {
            this.loading = false
        }, 900);
    },
    methods: {
        fetchLocalData: function() {
            this.users.name = localStorage.getItem('name');
            this.users.role = localStorage.getItem('role');
        },
        toggleSidebar() {
            const el = document.body;
            const sidebar = document.getElementById('sidebar-toggler');
              const className = 'sidebar-open';

            if (!el.classList.contains(className)) {
                el.classList.add(className);
                sidebar.classList.add('active');
            } else {
                el.classList.remove(className);
                sidebar.classList.remove('active');
            }
        },
    },
}
</script>