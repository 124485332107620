<template>
    <div class="card">
        <div class="card-body">
            <div class="card-subtitle mt-0">Please complete the form below.</div>
            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card bg-primary">
                                <div class="card-body text-white">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center rounded-circle bg-white wd-40 ht-40 text-secondary mr-4">
                                            <i class="my-0 mdi mdi-account-key tx-20"></i>
                                        </div>
                                        <div class="d-block">
                                            <label>Primary Username</label>
                                            <div class="font-weight-bold tx-20">{{form.identity_number}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card bg-secondary">
                                <div class="card-body text-white">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center rounded-circle bg-white wd-40 ht-40 text-secondary mr-4">
                                            <i class="my-0 mdi mdi-account-key tx-20"></i>
                                        </div>
                                        <div class="d-block">
                                            <label>Secondary Username</label>
                                            <div class="font-weight-bold tx-20">{{form.email}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="password">New Password</label>
                    <input type="password" class="form-control" id="password" name="password" autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.password.$error }" v-model="form.password" placeholder="Enter new password ...">
                    <div v-if="submitted && !$v.form.password.required" class="invalid-feedback">Password is required!</div>
                    <div class="alert alert-info mt-2"><i class="mdi mdi-information mr-2"></i>Use a minimum of 8 or more characters with a combination of letters (A-Z) and numbers (0-9)</div>
                </div>
                <div class="form-group">
                    <label for="confirm_password">Confirmation Password</label>
                    <input type="password" class="form-control" id="confirm_password" name="confirm_password" :class="{ 'is-invalid': submitted && $v.form.confirm_password.$error }" v-model="form.confirm_password" placeholder="Confirm new password ...">
                    <div v-if="submitted && !$v.form.confirm_password.required" class="invalid-feedback">Confirm Password is required!</div>
                    <div class="alert alert-info mt-2"><i class="mdi mdi-information mr-2"></i>Use a minimum of 8 or more characters with a combination of letters (A-Z) and numbers (0-9)</div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save</button>
                    <router-link :to="{name: 'teacher'}" class="btn btn-light mt-2">Back</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Password',
        metaInfo: {
            title: 'Form Student',
            titleTemplate: '%s - Unfari E-learning'
        },
        props: {
            form: Object
        },
        components : {
        },
        created() {
        },
        data() {
            return {
                submitted           : false,
                waiting             : false,
            }
        },
        validations: {
            form: {
                password: {
                    required
                },
                confirm_password: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else if (this.form.password != this.form.confirm_password) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Password not match!',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.$emit("listenerChild", this.form);
                }
            },
        }
    }
</script>