<template>
    <div id="preloader">
        <div class="preloader1"></div>
    </div>
</template>
<script>
export default {
    name : 'LoadingPage',
}
</script>
<style scoped>
    #preloader {
        position: fixed;
        z-index: 20000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fffffffb !important;
        background-position:  center;
        background-repeat: no-repeat;
        background-size: 20% !important;
        text-align: center;
    }
    .preloader1 {
        width: 60px;
        height: 60px;
        display: inline-block;
        padding: 0px;
        border-radius: 100%;
        border: 8px solid;
        border-top-color: rgba(255, 30, 0, 0.65);
        border-bottom-color: rgba(0, 0, 0, 0.1);
        border-left-color: rgba(0, 0, 0, 0.1);
        border-right-color: rgba(0, 0, 0, 0.1);
        -webkit-animation: preloader1 0.9s ease-in infinite;
        animation: preloader1 0.9s ease-in infinite;
        position:absolute;
        margin-left:-30px;
        top:39%;
    }
    @keyframes preloader1 {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes preloader1 {
        from {
            -webkit-transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
        }
    }
</style>