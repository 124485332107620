<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <!-- List of courses -->
        <div v-if="!detail.data_presence.id" class="alert alert-warning mt-2">
            <p><i class="mdi mdi-information mr-2"></i> You haven't made an presence.</p>
        </div>

        <div v-if="detail.data_presence.id" class="alert alert-success mt-2">            
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <p><i class="feather icon-check"></i> You've been presence in this lesson.</p>
                </div>
                <div>
                    <router-link class="btn btn-light ml-2" :to="{name: 'view_presence_lessons', params: {courses_id: detail.courses_id, id:detail.id}}">Check Presence</router-link>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-xl-12">
                <div class="d-block mb-3">
                    <div class="card">
                        <div class="card-body pb-1">
                            <div class="d-flex align-items-center border-bottom pb-2">
                                <router-link class="btn btn-light" :to="{name: 'courses_section', params: {id: params.courses_id}}">Back</router-link>
                                <div class="d-block ml-3">
                                    <h5 class="font-weight-bold">{{detail.name}}</h5>
                                    <p class="text-muted">{{detail.courses_name}}</p>
                                </div>
                            </div>
                            <ul class="nav mt-2">
                                <li class="nav-item">
                                    <a class="nav-link pl-0 tx-16" href="javascript:void(0)" @click="changeTab('Result')" :class="{'active': currentTabComponent == 'Result', 'text-dark': currentTabComponent != 'Result'}">Assignment Grade</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link tx-16" href="javascript:void(0)" @click="changeTab('Description')" :class="{'active': currentTabComponent == 'Description', 'text-dark': currentTabComponent != 'Description'}">Assignment Description</a>
                                </li>
                                <li class="nav-item d-none">
                                    <a class="nav-link tx-16" href="javascript:void(0)" @click="changeTab('Discussion')" :class="{'active': currentTabComponent == 'Discussion', 'text-dark': currentTabComponent != 'Discussion'}">Assignment Discussion</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- row -->
        <keep-alive>
            <component :params="params" :is="currentTabComponent"></component>
        </keep-alive>
    </div>
    <foot></foot>
</div>
</template>

<script>
// import tabs components
import Result from '@/components/tabs/teacher/assignment/Result.vue';
import Description from '@/components/tabs/teacher/assignment/Description.vue';
import Discussion from '@/components/tabs/teacher/assignment/Discussion.vue';
export default {
    name: 'Assignment',
    metaInfo: {
        title: 'Assignment',
        titleTemplate: '%s - Elearning'
    },
    components: {
        Result,
        Description,
        Discussion
    },
    data() {
        return {
            currentTabComponent: 'Result',
            detail: {},
            params: this.$route.params,
            dataFetch: true,
        }
    },
    created() {
        this.fetchDetail();
    },
    methods: {
        changeTab: function (tabActive) {
            this.currentTabComponent = tabActive;
        },
        fetchDetail: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/detail';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_id: this.params.courses_id,
                    courses_lessons_id: this.params.id,
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.detail = getResponse;
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            history.back();
                        }
                    });
                }
            });
        },
    }
}
</script>
