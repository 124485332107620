<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="d-block mb-3">
                <div class="card">
                    <div class="card-body">
                        <input type="text" class="form-control w-100" placeholder="Enter the keyword then press enter ..." />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-xl-12">
                    <div class="col-md-12">
                        <div class="rounded bg-light p-3 mb-3 text-center">
                            FAQ not yet available.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    export default {
        name: 'FAQ',
        metaInfo: {
            title: 'FAQ',
            titleTemplate: '%s - Elearning'
        },
        components: {},
        data() {
            return {
                dataFetch: true,
            }
        },
        created() {},
        methods: {}
    }
</script>