<template>
<div class="page-wrapper">
    <div class="page-content" v-if="dataFetch">
        <p>Loading...</p>
    </div>
    <div class="page-content" v-if="!dataFetch">
        <!-- List of courses -->
        <div v-if="!detail.data_presence.id" class="alert alert-warning">
            <p><i class="mdi mdi-information mr-2"></i> You haven't made an presence.</p>
        </div>

        <div v-if="detail.data_presence.id">
            <div class="alert" :class="detail.data_presence.presence == '1' ? 'alert-success':'alert-danger'">            
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <p v-if="detail.data_presence.presence == '1'"><i class="feather icon-check"></i> You've been presence in this lesson.</p>
                        <p v-if="detail.data_presence.presence == '0'"><i class="feather icon-x"></i> You are not present in this lesson.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-xl-12">
                <div class="d-block ">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-block">
                                    <h6 id="default">{{detail.name}}</h6>
                                    <p class="text-muted tx-14 text-uppercase">{{detail.format}}</p>
                                </div>
                                <div class="d-flex align-items-center justify-content-end w-25">
                                    <router-link class="btn btn-light" :to="{name: 'learningpath', params: {id: courses_id}}">Back</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12 mt-3">
                        <div class="alert alert-info" v-if="detail.note"><span class="font-weight-bold">Note : </span> {{detail.note}}</div>

                        <div class="card">
                            <div class="card-body">
                                <p class="text-justify" v-if="detail.format == 'text'" v-html="detail.description"></p>
                                <iframe :src="detail.attachment" v-if="detail.format == 'file'" width="100%" height="1000"></iframe>
                                <div v-if="detail.format == 'youtube'" class="col-md-12">
                                    <vue-plyr>
                                        <div class="plyr__video-embed">
                                            <iframe
                                            :src="detail.description"
                                            allowfullscreen
                                            allowtransparency
                                            allow="autoplay"
                                            ></iframe>
                                        </div>
                                    </vue-plyr>
                                </div>                                
                                <div v-if="detail.format == 'video'" class="col-md-12">
                                    <vue-plyr>
                                        <video
                                            controls
                                            crossorigin
                                            playsinline>
                                            <source
                                                size="1080"
                                                :src="detail.attachment"
                                                type="video/mp4"
                                            />
                                            </video>
                                    </vue-plyr>
                                </div>
                                <div v-if="detail.format == 'audio'" class="col-md-12">
                                    <vue-plyr>
                                        <audio controls crossorigin playsinline>
                                            <source
                                                size="1080"
                                                :src="detail.attachment"
                                            />
                                        </audio>
                                    </vue-plyr>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 col-sm-12 mt-3">
                        <div class="card" v-if="!assignment.scored">
                            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                                <div class="card-header pb-0">
                                    <div class="card-title mt-2 pb-0">Fill Answer</div>
                                </div>
                                <div class="card-body">
                                    <div class="form-group">
                                        <label>Description</label>
                                        <textarea v-model="form.description" class="form-control" rows="6" :class="{ 'is-invalid': submitted && $v.form.description.$error }"></textarea>
                                        <div v-if="submitted && !$v.form.description.required" class="invalid-feedback">Description is required!</div>
                                    </div>
                                    <div class="form-group">
                                        <label class="label">File Attachment</label>
                                        <input type="file" class="form-control" placeholder="Choose File" @change="previewFiles" />
                                        <a :href="assignment.attachment" class="d-block bg-light rounded text-dark mt-3 p-3" v-if="assignment.attachment" target="_blank"><i class="feather icon-eye mr-3"></i>See Prevous Attachment</a>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <div class="d-flex justify-content-end">
                                        <router-link class="btn btn-light" :to="{name: 'learningpath', params: {id: courses_id}}">Back</router-link>
                                        <button type="submit" class="btn btn-primary ml-2" :disabled="fetch_save">{{fetch_save ? 'Saving data...':'Save'}}</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="alert alert-warning text-center mt-2" v-if="!assignment.scored && assignment.id ">
                            You have successfully submitted an answer.<br/>Waiting score from lecturer
                        </div>

                        <div class="card" v-if="assignment.scored">
                            <div class="card-body">
                                <div class="form-group">
                                    <label>Description</label>
                                    <div class="d-block form-control bg-light p-3" style="min-height: 200px">{{assignment.description}}</div>
                                </div>

                                <div class="form-group" v-if="assignment.attachment">
                                    <label>File Attachment</label>
                                    <a :href="assignment.attachment" class="d-block bg-light rounded text-dark p-3" v-if="assignment.attachment" target="_blank"><i class="feather icon-eye mr-3"></i>See Prevous Attachment</a>
                                </div>

                                <div class="form-group">
                                    <label>Your Score</label>
                                    <div class="d-block form-control bg-light p-3 text-center tx-20" style="min-height: 50px">
                                        <b>{{assignment.scored}}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Assignment',
    metaInfo: {
        title: 'Assignment',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            courses_id: this.$route.params.courses_id,
            detail: {},
            assignment: {},
            form: {
                courses_lessons_id: this.$route.params.id,
                description: '',
                attachment: ''
            },
            configs: {
                spellChecker: false
            },
            dataFetch : true,
            submitted: false,
            waiting: false,
            fetch_save : false
        }
    },
    created() {
        this.getDataByID(this.courses_id, this.form.courses_lessons_id);
        this.checkAssignment(this.form.courses_lessons_id);
    },
    validations: {
        form: {
            description: {
                required
            },
        }
    },
    methods: {
        getDataByID: function (courses_id, id) {
            let uri = process.env.VUE_APP_APIHOST + 'student/courses-lessons/detail';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_id: courses_id,
                    courses_lessons_id: id
                }
            }).then(res => {
                if (res.data.status) {
                    this.dataFetch = false;
                    let getResponse = res.data.data;
                    this.detail = getResponse;
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            history.back();
                        }
                    });
                }
            });
        },

        checkAssignment: function (id) {
            let uri = process.env.VUE_APP_APIHOST + 'student/courses-lessons/assignment/check';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_lessons_id: id
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.assignment = getResponse;
                    this.form.description = getResponse.description;
                    this.form.attachment = getResponse.attachment;
                }
            });
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.fetch_save = true;
                this.$http.post(process.env.VUE_APP_APIHOST + 'student/courses-lessons/assignment/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    location.reload();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                        this.fetch_save = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.form.attachment = file;
            }
        }
    }
}
</script>
