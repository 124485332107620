<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <!-- List of courses -->
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="d-block mb-3">
                        <div v-if="!dataFetchPresence" class="alert mt-2 mb-3" :class="presenceData.id ? 'alert-success':'alert-warning'">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <i class="mdi mdi-information mr-2 text-muted tx-20"></i>
                                    {{presenceData.id ? "You've been presence in this lesson":"You haven't made an presence."}} 
                                </div>
                                <div>
                                    <button v-if="!presenceData.id" class="btn btn-primary" type="button" v-on:click="absentHere(detail.status)"><i class="feather icon-user-check"></i> Presence Here</button>
                                    <router-link class="btn btn-light ml-2" :to="{name: 'courses_section', params: {id: this.courses_id}}">Back</router-link>
                                </div>
                            </div>
                        </div>

                        <div class="card rounded">
							<div class="card-body">
								<div class="d-flex align-items-center justify-content-between mb-2">
									<h6 class="card-title mb-0">Lessons Information</h6>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Lessons Title:</label>
									<p class="text-muted">{{detail.name}}</p>
								</div>
								<!--div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Lessons Type:</label>
                                    <div class="d-flex align-items-center">
                                        <label class="badge badge-primary mr-2 tx-12 my-0 text-uppercase">{{detail.type}}</label>
                                        <label class="badge badge-light mr-2 tx-12 my-0 text-uppercase" v-if="detail.type != 'quiz'">{{detail.format}}</label>
                                    </div>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Status:</label>
                                    <div class="d-flex align-items-center">
                                        <label class="badge badge-success tx-12 my-0" v-if="detail.status == 'publish'">Publish</label>
                                        <label class="badge badge-secondary tx-12 px-2 my-0" v-if="detail.status == 'draft'">Draft</label>
                                    </div>                                    
								</div-->
								<div class="mt-3" v-if="detail.can_expired">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Study Time:</label>
									<p class="text-muted">
                                        <ul>
                                            <li>Start : {{detail.text_start_date}}</li>
                                            <li>End : {{detail.text_end_date}}</li>
                                        </ul>
                                    </p>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Total Members:</label>
									<p class="text-muted"><i class="feather icon-users"></i> {{detail.total_members}}</p>
								</div>
							</div>
						</div>                        
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-block">
                                        <div class="d-flex mb-3">
                                            <input type="text" class="form-control" v-model="keywords" placeholder="Search member here ..." v-on:keyup.enter="getData()" />
                                            <button class="btn btn-primary ml-3" type="button" v-on:click="getData()">Reload</button>
                                        </div>

                                        <div v-if="!dataFetch">
                                            <div class="table-responsive" v-if="list_member.length > 0">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th width="3%">No</th>
                                                            <th>Identity Number</th>
                                                            <th>Name</th>
                                                            <th class="text-center">Presence Status</th>
                                                            <!-- <th>Presence Date</th> -->
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(row, index) in list_member" :key="row.id">
                                                            <td>
                                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                            </td>
                                                            <td>{{row.identity_number}}</td>
                                                            <td>{{row.name}}</td>
                                                            <td v-if="row.presence_id" class="text-center">
                                                                <label v-if="row.presence_status" class="badge badge-success"><i class="feather icon-check"></i></label>
                                                                <label v-if="!row.presence_status" class="badge badge-danger"><i class="feather icon-x"></i></label>
                                                            </td>
                                                            <!-- <td v-if="row.presence_id">
                                                                {{row.presence_date}}
                                                            </td> -->
                                                            <td v-if="!row.presence_id" colspan="1">
                                                                <div class="bg-light rounded p-3 text-center text-muted">Not Presence</div>
                                                            </td>
                                                            
                                                            <!-- <td>
                                                                <button type="button" class="btn btn-primary" v-on:click="setAction(row)">Action</button>
                                                            </td> -->
                                                            <td>
                                                                <div class="d-block">
                                                                    <div class="form-check form-check-inline">
                                                                        <label class="form-check-label">
                                                                            <input type="radio" class="form-check-input" :name="'optionsRadios'+index" :id="'optionsRadios'+index" v-on:change="changePresence(row, '1')" :checked="row.presence_status == '1'">
                                                                            Present
                                                                            <i class="input-frame"></i></label>
                                                                    </div>
                                                                    <div class="form-check form-check-inline ml-2">
                                                                        <label class="form-check-label">
                                                                            <input type="radio" class="form-check-input" :name="'optionsRadios'+index" :id="'optionsRadios'+index" v-on:change="changePresence(row, '0')" :checked="row.presence_status == '0'">
                                                                            Not Present
                                                                            <i class="input-frame"></i></label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div class="d-block p-3 rounded bg-light" v-if="list_member.length < 1">
                                                <div class="text-center">Data not found</div>
                                            </div>
                                        </div>

                                        <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                                            <div class="text-center">loading data...</div>
                                        </div>

                                        <div class="d-block mt-3" v-if="list_member.length > 0">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ViewPresence',
        metaInfo: {
            title: 'Detail Presence',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination,
        },
        data() {
            return {
                dataFetch: true,
                dataFetchPresence: true,
                courses_id: this.$route.params.courses_id,
                courses_lessons_id: this.$route.params.id,
                detail: {},
                list_member: [],
                keywords: '',
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 30,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                presenceData: {
                    id : '',
                    presence : ''
                }
            }
        },
        created() {
            this.getDataByID(this.courses_id, this.courses_lessons_id);
        },
        methods: {
            getDataByID: function(courses_id, id) {
                let uri     = process.env.VUE_APP_APIHOST+'teacher/courses-lessons/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        courses_id : courses_id,
                        courses_lessons_id : id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse     = res.data.data;
                        this.detail         = getResponse;
                        this.getData();
                        this.checkPresence();
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                history.back();
                            }
                        });
                    }
                });
            },
            checkPresence: function() {
                let uri     = process.env.VUE_APP_APIHOST+'teacher/courses/check-presence-users';

                this.dataFetchPresence  = true;                
                this.$http.get(uri,{
                    params : {
                        user_id             : this.detail.users_id,
                        courses_lessons_id  : this.courses_lessons_id
                    }
                }).then(res => {
                    this.dataFetchPresence  = false;
                    let getResponse     = res.data.data;
                    this.presenceData   = getResponse;
                });
            },
            getData: function () {
                let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/list-presence-users';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords            : this.keywords,
                        courses_id          : this.courses_id,
                        courses_lessons_id  : this.courses_lessons_id,
                        page                : this.pagination.currentPage,
                        limit               : this.pagination.limit
                    }
                }).then(res => {
                    this.dataFetch              = false;
                    this.list_member            = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                });
            },
            setAction: function(item) {
                this.$swal({
                    icon: 'info',
                    text: 'Select presence',
                    input : 'select',
                    inputOptions: {
                        '1': 'Present',
                        '0': 'Not Present'
                    },
                    inputPlaceholder: 'Select a presence',
                    showCancelButton: true,
                    confirmButtonText: 'Submit',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                    if (result.value) {
                        this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/save-presence-users', {
                            user_id             : item.user_id,
                            courses_lessons_id  : this.courses_lessons_id,
                            presence            : result.value
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            }
                        })
                    }
                });
            },
            absentHere: function (status="") {
                let etc_desc = "";
                if(status != 'open') {
                    etc_desc = "<br/>You will be presence outside lessons hours";
                }
                this.$swal({
                    icon: 'info',
                    title: 'Presence',
                    html: 'Do you want to be presence now?'+etc_desc,
                    showCancelButton: true,
                    confirmButtonText: 'Present',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/save-presence-users', {
                        user_id: this.detail.users_id,
                        presence: 1,
                        courses_lessons_id: this.courses_lessons_id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.checkPresence();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            changePresence: function(item, status=0) {
                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/save-presence-users', {
                    user_id             : item.user_id,
                    courses_lessons_id  : this.courses_lessons_id,
                    presence            : status
                })
                .then(response => {
                    if (response.data.status) {
                        console.log(response.data.message);
                        // this.$swal({
                        //     icon: 'success',
                        //     title: 'Success',
                        //     text: response.data.message,
                        //     confirmButtonText: 'OK'
                        // }).then((result) => {
                        //     if (result.value) {
                        //         this.getData();
                        //     }
                        // });
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                    }
                })                
            }
        }
    }
</script>