<template>
<div class="page-wrapper">
    <div class="page-content">
        <!-- List of courses -->
        <div class="row">
            <div class="col-lg-3 col-xl-3 grid-margin grid-margin-xl-0 stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Filter</h6>

                        <div class="form-group">
                            <select class="form-control" id="periode_id" v-model="params.periode_id">
                                <option value="">Choose Year Period</option>
                                <option v-for="row in list_year" :key="row.id" :value="row.id">{{row.name + (row.active == '1' ? '(Active)' : '')}}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <select class="form-control" id="program_study_id" v-model="params.program_study_id">
                                <option value="">Choose Study Program</option>
                                <option v-for="row in list_program_study" :key="row.id" :value="row.id">{{row.name + (row.active == '1' ? '(Active)' : '')}}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" id="keywords" v-model="params.keywords" autocomplete="off" placeholder="Kata kunci pencarian">
                        </div>

                        <button type="button" class="btn btn-primary btn-block mr-2" @click="getCourses(true)">Apply Filter</button>
                    </div>
                </div>
            </div>
            <div class="col-lg-9 col-xl-9" v-if="dataFetch">
                <div class="rounded bg-light p-3 text-center">
                    Loading...
                </div>
            </div>
            <div class="col-lg-9 col-xl-9" v-if="!dataFetch">
                <div class="d-block mb-3" v-if="list_courses.length > 0">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 id="default">Show 1-{{list_courses.length}} of {{list_courses.length}} Courses</h6>

                                <!--div class="d-none align-items-center justify-content-end w-25">
                                    <label class="mb-0 w-100 text-right mr-2">Sort by</label>
                                    <select class="form-control w-100">
                                        <option>A to Z</option>
                                        <option>Z to A</option>
                                    </select>
                                </div-->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-block">
                    <div v-if="list_courses.length > 0" class="row">
                        <div class="col-md-4 mb-3" v-for="row in list_courses" :key="row.id">
                            <div class="card">
                                <img v-if="!row.cover" src="images/cover_courses.jpg" class="card-img-top" alt="images/cover_courses.jpg" style="background-size: cover !important;">
                                <img v-if="row.cover" :src="row.cover" class="card-img-top" :alt="row.cover" style="background-size: cover !important;">
                                <div class="card-body p-3">
                                    <h5 class="card-title text-capitalize mb-2 font-1rem">{{row.name}}</h5>
                                    <p class="card-text">{{row.program_study}}</p>
                                    <p class="card-text text-muted">{{row.category}}</p>
                                    <p class="card-text text-muted">{{row.program}}</p>
                                    <div class="card-block mt-2">
                                        <div class="d-flex card-text align-items-center">
                                            <div><i class="feather icon-award wd-15"></i></div>
                                            <div class="mt-1 ml-2">{{row.instructure}}</div>
                                        </div>
                                        <div class="d-flex card-text align-items-center">
                                            <div><i class="feather icon-user wd-15"></i></div>
                                            <div class="mt-1 ml-2">{{row.member}} Parisipant(s)</div>
                                        </div>
                                    </div>
                                    <router-link :to="{name: 'learningpath', params: {id: row.id}}" class="btn btn-primary btn-block mt-2">Enter Course</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="list_courses.length == 0" class="rounded bg-light p-3 text-center">
                        Data not found.
                    </div>
                </div>
            </div>
        </div> <!-- row -->
    </div>
    <foot></foot>
</div>
</template>

<script>
export default {
    name: 'Courses',
    metaInfo: {
        title: 'Courses',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: true,
            list_year: [],
            list_program_study: [],
            list_teacher: [],
            list_courses: [],
            teacher_selected: [],
            params: {
                program_study_id: "",
                periode_id: "",
                list_teacher: "",
                limit: 100,
                page: "",
                keywords: ""
            }
        }
    },
    created() {
        this.getYear();
        this.getProgramStudy();
        // this.getTeacher();
        this.getCourses();
    },
    methods: {
        getCourses: function (submit=false) {
            this.dataFetch = true;
            this.list_courses = [];
            let uri = process.env.VUE_APP_APIHOST + 'student/courses/list';

            if (submit) {
                this.params.list_teacher = '';
                if (this.teacher_selected.length > 0)
                    this.params.list_teacher = JSON.stringify(this.teacher_selected);
            }
            this.$http.get(uri, {params: this.params}).then(res => {
                this.dataFetch = false;
                if (res.data.status) {
                    this.list_courses = res.data.data;
                }
            });
        },
        getYear: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/periode';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_year = res.data.data;
                }
            });
        },
        getProgramStudy: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/program-study';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_program_study = res.data.data;
                }
            });
        },
        getTeacher: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/teacher';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_teacher = res.data.data;
                }
            });
        },
    }
}
</script>
