<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="d-block mt-3 mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="params.program_id" v-on:change="list()">
                                        <option value="">See All Program</option>
                                        <option v-for="row in listProgram" :value="row.id" :key="row.id">{{row.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="params.courses_id" v-on:change="list()">
                                        <option value="">See All Courses</option>
                                        <option v-for="row in listCourses" :value="row.id" :key="row.id">{{row.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="params.topic_id" v-on:change="list()">
                                        <option value="">All Topic</option>
                                        <option v-for="row in listTopic" :value="row.id" :key="row.id">{{row.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" placeholder="Enter the keyword then press enter ..." v-model="params.keywords" v-on:keyup.enter="list()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title mb-2">Reporting</h6>
                            <div class="table-responsive" v-if="!dataFetch">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="3%">#</th>
                                            <th width="15%">Identity Number</th>
                                            <th>Name</th>
                                            <th width="15%" class="text-center">Study Group</th>
                                            <!-- Nanti ini generate dari response api sesuai materi di topik -->
                                            <th width="10%" class="text-center">Quiz 1</th>
                                            <th width="10%" class="text-center">Assignment 2</th>
                                            <th width="10%" class="text-center">Quiz 3</th>
                                            <th width="10%" class="text-center">Assignment 4</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataList.length > 0">
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td class="text-center" width="3%">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>1021023123</td>
                                            <td>Jhon Doe</td>
                                            <td class="text-center">X RPL 3</td>
                                            <!-- Nanti ini generate dari response api sesuai materi di topik -->
                                            <td class="text-center">85</td>
                                            <td class="text-center">78</td>
                                            <td class="text-center">90</td>
                                            <td class="text-center">88</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="dataList.length == 0">
                                        <tr>
                                            <td colspan=5>
                                                <div class="d-block p-3 rounded bg-light">
                                                    <div class="text-center">Data not found</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                                <div class="text-center">loading data...</div>
                            </div>
                            <div class="d-block mt-3" v-if="pagination.totalPages > 0">
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages"
                                    :classes="pagination.bootstrapPaginationClasses"
                                    :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import vPagination from 'vue-plain-pagination'
export default {
    name: 'Meeting',
    metaInfo: {
        title: 'Webinar',
        titleTemplate: '%s - Elearning'
    },
    components: {
        vPagination
    },
    data() {
        return {
            dataFetch: true,
            dataList: [],
            listProgram: [],
            listCourses: [],
            listTopic: [],
            params: {
                courses_id: '',
                program_id: '',
                topic_id: '',
                keyword: "",
            },
            pagination: {
                currentPage: 1,
                totalPages: 1,
                limit: 10,
                bootstrapPaginationClasses: {
                    ul: 'pagination justify-content-end',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                paginationAnchorTexts: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            this.dataList = [0,1]
            this.dataFetch = false
            
            // let uri = process.env.VUE_APP_APIHOST + 'teacher/conference/list';

            // this.dataFetch = true;
            // this.$http.get(uri, {
            //     params: {
            //         keywords: this.keywords,
            //         page: this.pagination.currentPage,
            //         limit: this.pagination.limit
            //     }
            // }).then(res => {
            //     this.dataFetch = false;
            //     this.dataList = res.data.data;
            //     this.pagination.totalPages = res.data.total_page;
            // });
        },
    }
}
</script>