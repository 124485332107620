<template>
    <div class="row justify-content-center">
        <div class="col-md-8 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <div class="card-subtitle mt-0">Please complete the form below.</div>
                    <form class="forms-sample" v-on:submit.prevent="onSubmit">
                        <div class="form-group row justify-content-center mt-4">
                            <div class="col-md-3 mb-3 pb-2 text-center">
                                <div>
                                    <img :src="preview_image" class="border-radius-1 w-100 border" />
                                </div>
                                <input type="file" @change="previewFiles" ref="file"
                                    accept="image/png, image/gif, image/jpeg" class="d-none" />
                                <button type="button" class="btn btn-outline-secondary btn-block mt-2"
                                    @click="$refs.file.click()">Change Cover</button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="name">Name of courses</label>
                            <input type="text" class="form-control" id="name" name="name" autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.name.$error }" v-model="form.name" placeholder="Enter name of courses ...">
                            <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                        </div>
                        <div class="form-group">
                            <label for="name">Description of courses</label>
                            <ckeditor :config="editorConfig" v-model="form.description" class="mt-1"></ckeditor>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button type="submit" class="btn btn-primary mr-2 mt-2">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Setting',
    metaInfo: {
        title: 'Setting Class',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            id              : this.params.id,
            form : {
                id          : "",
                code        : "",
                name        : "",
                courses_category_id: "",
                description : ""
            },
            list_course_category:[],
            submitted       : false,
            waiting         : false,
            preview_image: 'https://via.placeholder.com/513x342',
            editorConfig: {
                toolbar: [{
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                }, {
                    name: 'paragraph',
                    items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                }, {
                    name: 'links',
                    items: ['Link', 'Unlink']
                }, {
                    name: 'insert',
                    items: ['Image', 'EmbedSemantic', 'Table']
                }, {
                    name: 'tools',
                    items: ['Maximize']
                }],
                extraPlugins: 'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                imageUploadUrl: process.env.VUE_APP_APIHOST + 'uploader/images',
                uploadUrl: process.env.VUE_APP_APIHOST + 'uploader/files',
            },
            courses     : this.$parent.detail            
        }
    },
    props: ['params', 'fetchDetailClass'],
    created() {
        this.getForm();
    },
    validations: {
        form: {
            name: {
                required
            },
        }
    },
    methods: {
        getForm: function() {
            this.form.id            = this.courses.id;
            this.form.name          = this.courses.name;
            this.form.description   = this.courses.description;

            if(this.courses.cover != '' && this.courses.cover !== null) {
                this.preview_image = this.courses.cover;
            }
        },
        onSubmit: function () {
            this.waiting    = true;
            this.submitted  = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {    
                let formData = new FormData();

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/save-setting', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    location.reload();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });

            }
        },
        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.preview_image = URL.createObjectURL(file);
                this.form.cover = file;
            }
        },
    }
}
</script>
