<template>
<!-- List of courses -->
<div class="row">
    <div class="col-lg-12 col-xl-12">
        <div class="d-block mb-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-block">
                            <h6 id="default">{{detail.name}}</h6>
                            <p class="text-muted tx-14 text-uppercase">{{detail.format}}</p>
                        </div>
                    </div>
                    <div class="d-block border-top mt-2 pt-2">
                        <div class="d-block mb-3 rounded bg-light p-3" v-if="detail.note"><span class="font-weight-bold">Note : </span> {{detail.note}}</div>
                        <p class="text-justify mt-3" v-if="detail.format == 'text'" v-html="detail.description"></p>
                        <iframe :src="detail.attachment" v-if="detail.format == 'file'" width="100%" height="1000"></iframe>
                        <div v-if="detail.format == 'youtube'" class="col-md-12">
                            <vue-plyr>
                                <div class="plyr__video-embed">
                                    <iframe
                                    :src="detail.description"
                                    allowfullscreen
                                    allowtransparency
                                    allow="autoplay"
                                    ></iframe>
                                </div>
                            </vue-plyr>
                        </div>                                    
                        <div v-if="detail.format == 'video'" class="col-md-12">
                            <vue-plyr>
                                <video
                                    controls
                                    crossorigin
                                    playsinline>
                                    <source
                                        size="1080"
                                        :src="detail.attachment"
                                        type="video/mp4"
                                    />
                                    </video>
                            </vue-plyr>
                        </div>
                        <div v-if="detail.format == 'audio'" class="col-md-12">
                            <vue-plyr>
                                <audio controls crossorigin playsinline>
                                    <source
                                        size="1080"
                                        :src="detail.attachment"
                                    />
                                </audio>
                            </vue-plyr>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> <!-- row -->
</template>

<script>
export default {
    name: 'DescriptionAssignment',
    metaInfo: {
        title: 'Detail Assignment',
        titleTemplate: '%s - Elearning'
    },
    props: ['params'],
    components: {},
    data() {
        return {
            dataFetch: true,
            courses_id: this.params.courses_id,
            courses_lessons_id: this.params.id,
            detail: {}
        }
    },
    created() {
        this.fetchDetail();
    },
    methods: {
        fetchDetail: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/detail';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_id: this.courses_id,
                    courses_lessons_id: this.courses_lessons_id
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.detail = getResponse;
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            history.back();
                        }
                    });
                }
            });
        },
    }
}
</script>
