<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <!-- Top Header Dashboard -->
        <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
                <h4 class="mb-3 mb-md-0">List of Schedule</h4>
            </div>
            <div class="d-flex">
                <button class="btn btn-white rounded pb-1 mr-1" type="button" @click="getData"><i class="mdi mdi-reload mr-1"></i>Refresh</button>
            </div>
        </div>

        <!-- Card list annoucement -->
        <div class="row">
            <div class="col-12 col-xl-12">
                <div v-if="dataFetch">
                    <div class="rounded bg-light p-3 mb-3 text-center">
                        Loading...
                    </div>
                </div>
                <div class="d-block mb-3" v-if="!dataFetch">
                    <div class="card">
                        <div class="card-body">
                            <div class="tscroll">
                            <table class="table table-no-bordered w-100">
                                <thead>
                                    <tr>
                                        <th class="text-uppercase"><i class="mdi mdi-av-timer mr-1" style="font-size: 12pt"></i> Time</th>
                                        <th class="text-uppercase" v-for="(day, index) in list.list_day" :key="index">
                                            <div class="d-block mb-1">{{day.day}}</div>
                                            <div class="d-block">{{day.date_long}}</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="list.list.length > 0" v-for="(row, index) in list.list" :key="index">
                                        <td>{{row.time}}</td>
                                        <td v-for="event in row.child" :key="event.id" class="bg-light border-0">
                                            <div v-if="event.length > 0" class="d-flex justify-content-between mb-2 rounded bg-white p-3" v-for="detailEvent in event">
                                                <div class="mr-3">
                                                    <div class="d-block mb-1">
                                                        {{detailEvent.name}}
                                                    </div>
                                                    <div class="d-block text-muted">
                                                        {{detailEvent.lessons_name}}
                                                    </div>
                                                </div>
                                                <div><i class="mdi mdi-dots-vertical"></i></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-else>
                                        <td class="bg-light text-center" colspan="8">Schedule not available at this time.</td>
                                    </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
export default {
    name: 'AllSchedule',
    metaInfo: {
        title: 'All Schedule',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: false,
            list: [],
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            this.list = [];
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/schedule-all';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.list = getResponse;
                }
                this.dataFetch = false
            }).catch(() => {
                this.dataFetch = false
            });
        },
    }
}
</script>
