<template>
<div>
    <loading-page v-if="loading" />
    <div class="horizontal-menu">
        <nav class="navbar bottom-navbar">
            <div class="container">
                <div class="navbar-content">
                    <div class="navbar-brand" v-on:click="setCurrent('dashboard')">
                        <router-link :to="{name: 'home'}">
                            <img class="img-fluid wd-100" src="images/logo.png" />
                        </router-link>
                    </div>
                    <div class="navbar-nav"></div>
                    <router-link v-if="users.key" :to="{name: 'dashboard'}"  class="btn btn-primary btn-pill align-self-center">
                        BACK TO APP
                    </router-link>
                    <router-link v-if="!users.key" :to="{name: 'signin'}"  class="btn btn-primary btn-pill align-self-center">
                        LOGIN
                    </router-link>
                </div>
            </div>
        </nav>
    </div>
    <div class="banner-home">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="h2 text-white font-weight-bold mb-3">Portal LMS <br>Universitas Al-Ghifari</div>
                    <div class="h5 text-white font-weight-300 mb-5" style="line-height: 1.6;">Jl. Cisaranten Kulon No.140, Cisaranten Kulon, Kec. Arcamanik, Kota Bandung, Jawa Barat 40293</div>
                    <router-link v-if="users.key" :to="{name: 'dashboard'}">
                        <button type="button" class="btn btn-primary btn-pill px-4" style="line-height: 2;">BACK TO APPLICATION</button>
                    </router-link>
                    <router-link v-if="!users.key" :to="{name: 'signin'}">
                        <button type="button" class="btn btn-primary btn-pill px-4" style="line-height: 2;">LOGIN TO APPLICATION</button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="page-wrapper">
        <div class="page-content">
            <!-- Card list annoucement -->
            <div class="row mb-3">
                <div class="col-12 col-xl-12">
                    <div class="d-flex mb-3 align-items-center"><i class="mdi mdi-newspaper h4 mr-3"></i>
                        <h5>Announcement</h5>
                    </div>
                    <div v-if="list_announcement.length > 0" class="d-block stretch-card">
                        <div class="row flex-grow">
                            <div class="col-lg-4 col-xl-4 grid-margin stretch-card" v-for="(row, index) in list_announcement" :key="index">
                                <div class="card">
                                    <div class="card-body">
                                        <h6 class="card-title mb-3 text-capitalize">{{row.name}}</h6>
                                        <div class="card-text text-muted text-ellipsis">
                                            {{row.description}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="list_announcement.length < 1">
                        <div class="rounded bg-light p-3 mb-3 text-center">
                            Announcement not available at this time.
                        </div>
                    </div>
                </div>
            </div>
            <!-- List of courses -->
            <div class="d-flex mb-3 align-items-center"><i class="mdi mdi-school h4 mr-3"></i>
                <h5>List of Courses</h5>
            </div>
            <div class="d-block mt-3 mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="params.program_id" v-on:change="list()">
                                        <option value="">See All Program</option>
                                        <option v-for="row in list_program" :value="row.id" :key="row.id">{{row.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="params.periode_id" v-on:change="list()">
                                        <option value="">See All Year</option>
                                        <option v-for="row in list_year" :value="row.id" :key="row.id">{{row.name}} {{row.active == '1' ? '(Active)' : ''}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <select class="form-control" v-model="params.courses_category_id" v-on:change="list()">
                                        <option value="">See All Subject</option>
                                        <option v-for="row in list_course_category" :value="row.id" :key="row.id">{{row.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group mb-0">
                                    <input type="text" class="form-control" placeholder="Enter the keyword then press enter ..." v-model="params.keywords" v-on:keyup.enter="list()" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="d-block stretch-card">
                        <div class="row flex-grow" v-if="list_course.length != 0">
                            <div class="col-md-3 mb-3" v-for="row in list_course" :key="row.id">
                                <div class="card">
                                    <img v-if="!row.cover" src="images/cover_courses.jpg" class="card-img-top" alt="images/cover_courses.jpg" style="background-size: cover !important;">
                                    <img v-if="row.cover" :src="row.cover" class="card-img-top" :alt="row.cover" style="background-size: cover !important;">
                                    <div class="card-body p-3">
                                        <h5 class="card-title text-capitalize mb-2 font-1rem">{{row.name}}</h5>
                                        <p class="card-text">{{row.program_study}}</p>
                                        <p class="card-text text-muted">{{row.category}}</p>
                                        <p class="card-text text-muted">{{row.program}}</p>
                                        <div class="card-block mt-2">
                                            <div class="d-flex card-text align-items-center">
                                                <div><i class="feather icon-award wd-15"></i></div>
                                                <div class="mt-1 ml-2">{{row.instructure}}</div>
                                            </div>
                                            <div class="d-flex card-text align-items-center">
                                                <div><i class="feather icon-user wd-15"></i></div>
                                                <div class="mt-1 ml-2">{{row.member}} Parisipant(s)</div>
                                            </div>
                                        </div>
                                        <router-link :to="{name: 'signin'}" class="btn btn-primary btn-block mt-2">Enter Course</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="rounded bg-light p-3 mb-3 text-center">
                            Courses not available.
                        </div>
                    </div>
                </div>
            </div> <!-- row -->
            <!-- List of teachers -->
            <div class="row">
                <div class="col-12 col-xl-12">
                    <div class="d-flex mb-3 align-items-center"><i class="mdi mdi-account h4 mr-3"></i>
                        <h5>List of Lecturer</h5>
                    </div>
                    <div class="d-block stretch-card">
                        <VueSlickCarousel v-bind="pengaturanSlick" v-if="list_teacher.length">
                            <div class="p-2" v-for="row in list_teacher" :key="row.id">
                                <div class="card" >
                                    <div class="d-flex p-3 mt-3 justify-content-center">
                                        <div :style="{'background-image': 'url( ' + (row.photo ? row.photo : 'images/default_user.png') + ')', 'width': '100px', 'height': '100px', 'background-size': 'cover'}" class="rounded-circle" alt="user"></div>
                                    </div>
                                    <div class="card-body p-3 text-center">
                                        <h5 class="card-title text-capitalize mb-2 font-1rem">{{row.fullname}}</h5>
                                        <p class="card-text">{{row.program_study}}</p>
                                        <p class="card-text text-muted mt-3 text-justify"></p>
                                    </div>
                                </div>
                            </div>
                        </VueSlickCarousel>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import '../../public/css/slick-theme.css'

export default {
    name: 'Dashboard',
    metaInfo: {
        title: 'Dashboard',
        titleTemplate: '%s - Elearning'
    },
    components: { VueSlickCarousel },
    data() {
        return {
            dataFetch: true,
            fetchDashboard: true,
            fetchStatistic: true,
            list_teacher: [],
            list_program: [],
            list_year: [],
            list_course_category: [],
            list_course: [],
            list_announcement: [],
            users: {},
            params: {
                courses_category_id: '',
                program_id: '',
                program_study_id: '',
                periode_id: '',
                status: '',
                keywords: '',
                limit: 4
            },
            loading: true,
            pengaturanSlick : {
                "dots": false,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 4,
                "slidesToScroll": 4,
                "touchThreshold": 5,
                "autoplay": true,
                "autoplaySpeed": 2000,
            }
        }
    },
    created() {
        this.fetchLocalData();
        this.listProgram();
        this.listYear();
        this.listCourseCategory();
        this.listTeacher();
        this.listAnnouncement();
        this.list();
    },
    methods: {
        listTeacher: function () {
            let uri = process.env.VUE_APP_APIHOST + 'landing/list/teacher';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.list_teacher = getResponse;
                }
            });
        },
        listProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'landing/list/program';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_program = res.data.data;
                }
            });
        },
        listYear: function () {
            let uri = process.env.VUE_APP_APIHOST + 'landing/list/periode';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_year = res.data.data;
                }
            });
        },
        listCourseCategory: function () {
            let uri = process.env.VUE_APP_APIHOST + 'landing/list/courses-category';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_course_category = res.data.data;
                }
            });
        },
        listAnnouncement: function () {
            let uri = process.env.VUE_APP_APIHOST + 'landing/list/announcement';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_announcement = res.data.data;
                }
            });
        },
        list: function () {
            this.list_course = [];
            let uri = process.env.VUE_APP_APIHOST + 'landing/list/courses';

            this.$http.get(uri, {
                params: this.params
            }).then(res => {
                if (res.data.status) {
                    this.list_course = res.data.data;
                }
            });
        },
        fetchLocalData: function() {
            this.users.key = localStorage.getItem('auth_token_lms');
            this.users.name = localStorage.getItem('name');
            this.users.role = localStorage.getItem('role');
        }
    },
    mounted () {
        setTimeout(() => {
            this.loading = false
        }, 900);

    }
}
</script>