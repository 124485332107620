<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div v-if="dataFetch" class="col-lg-12 col-xl-12">
                    <p>Please wait...</p>
                </div>
                <div v-if="!dataFetch" class="col-lg-12 col-xl-12">
                    <div v-if="!detail.data_presence.id" class="alert alert-warning">
                        <p><i class="mdi mdi-information mr-2"></i> You haven't made an presence.</p>
                    </div>

                    <div v-if="detail.data_presence.id">
                        <div class="alert" :class="detail.data_presence.presence == '1' ? 'alert-success':'alert-danger'">            
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <p v-if="detail.data_presence.presence == '1'"><i class="feather icon-check"></i> You've been presence in this lesson.</p>
                                    <p v-if="detail.data_presence.presence == '0'"><i class="feather icon-x"></i> You are not present in this lesson.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="alert alert-info mb-3 rounded" v-if="detail.note">
                        <span class="font-weight-bold">Note : </span> {{detail.note}}
                    </div>

                    <div class="d-block mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-block">
                                        <p class="text-muted tx-15">Title</p>
                                        <h6 id="default">{{detail.name}}</h6>
                                    </div>
                                    <div class="d-block">
                                        <p class="text-muted tx-15">Schedule</p>
                                        <h6 id="default" v-if="detail.can_expired">{{detail.text_start_date}} s.d {{detail.text_end_date}}</h6>
                                        <h6 id="default" v-if="!detail.can_expired">-</h6>
                                    </div>
                                    <div class="d-block">
                                        <p class="text-muted tx-15">Duration</p>
                                        <h6 id="default">{{detail.duration}} Minutes</h6>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end w-25">
                                        <button v-if="detail.show_value == 0 && detail.quiz.status != 'finished'" class="btn btn-primary mr-2" type="button" v-on:click="goToQuiz">Start Quiz</button>
                                        <router-link class="btn btn-light" :to="{name: 'learningpath', params: {id: courses_id}}">Back</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="detail.show_value == 1">
                        <div v-if="detail.quiz.status == 'not_yet'" class="row mb-3">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <i class="mdi mdi-information mr-2 text-muted tx-20"></i>
                                                <div class="tx-15 text-muted">
                                                    
                                                    You haven't done this quiz yet
                                                </div>
                                            </div>
                                            <div>
                                                <button class="btn btn-primary" type="button" v-on:click="goToQuiz">Start Quiz</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="detail.quiz.status == 'finished'" class="row mb-3">
                            <div class="col-md-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <div class="tx-15 text-muted">Wrong</div>
                                        <div class="tx-50 text-dark font-weight-bold">{{detail.quiz.wrong}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <div class="tx-15 text-muted">Correct</div>
                                        <div class="tx-50 text-dark font-weight-bold">{{detail.quiz.correct}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <div class="tx-15 text-muted">Waiting</div>
                                        <div class="tx-50 text-dark font-weight-bold">{{detail.quiz.waiting}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="card">
                                    <div class="card-body text-center">
                                        <div class="tx-15 text-muted">Score</div>
                                        <div class="tx-50 text-dark font-weight-bold">{{detail.quiz.total_scored}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="detail.show_value == 0 && detail.quiz.status == 'finished'">
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="tx-15 text-muted">                                                    
                                                    You've done this quiz, but the quiz results can't be seen.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>

                    <div class="row" v-if="detail.description">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div v-html="detail.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    export default {
        name: 'QuizOverview',
        metaInfo: {
            title: 'Quiz Overview',
            titleTemplate: '%s - Elearning'
        },
        components: {},
        data() {
            return {
                courses_id          : this.$route.params.courses_id,
                courses_lessons_id  : this.$route.params.id,
                dataFetch           : true,
                detail              : []
            }
        },
        created() {
            this.getOverview();
        },
        methods: {
            getOverview: function () {
                let uri = process.env.VUE_APP_APIHOST + 'student/courses-lessons/quiz/overview';

                this.dataFetch = true;
                this.$http.get(uri, {
                    params: {
                        courses_id          : this.courses_id,
                        courses_lessons_id  : this.courses_lessons_id
                    }
                }).then(res => {
                    if (res.data.status) {
                        let getResponse = res.data.data;
                        this.detail     = getResponse;
                        if(this.detail.quiz.status == 'ongoing') {
                            this.goToQuiz();
                        } else {
                           this.dataFetch  = false;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                history.back();
                            }
                        });
                    }
                });
            },
            goToQuiz: function() {
                this.$router.push({
                    name: 'quiz_start', 
                    params: { courses_id: this.courses_id, id: this.courses_lessons_id }
                });
            }
        }
    }
</script>