<template>
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-block">
                    <div class="form-group d-flex row">
                        <div class="col">
                            <input type="text" class="form-control" v-model="keywords" placeholder="Search member here ..." v-on:keyup.enter="getData()" />
                        </div>
                        <div class="col-md-auto">
                            <button class="btn btn-outline-secondary" @click="getData()"><i class="feather icon-refresh-ccw mr-2"></i>Reload</button>
                        </div>
                    </div>

                    <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                        <div class="text-center">loading data...</div>
                    </div>

                    <div class="table-responsive mt-3" v-if="!dataFetch">                    
                        <table v-if="list_member.length > 0" class="table">
                            <thead>
                                <tr>
                                    <th width="3%">#</th>
                                    <th>Student</th>
                                    <th>Status</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in list_member" :key="row.id">
                                    <td>
                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                    </td>
                                    <td>
                                        {{row.identity_number}}<br/>
                                        {{row.name}}
                                    </td>
                                    <td v-if="row.id">
                                        <label class="text-success" v-if="row.has_finish"><i class="feather icon-check-circle mr-2"></i>Complete</label>
                                        <label class="text-warning" v-if="row.has_start && !row.has_finish"><i class="feather icon-check-circle mr-2"></i>On Progress</label>
                                    </td>
                                    <td v-if="row.id">{{row.text_start}}</td>
                                    <td v-if="row.id">{{row.text_end}}</td>
                                    <td v-if="row.id" class="text-center">
                                        <span v-if="!row.manual_scored">
                                            -
                                        </span>
                                        <span class="badge bg-info text-white" v-if="row.manual_scored">
                                            score manually: {{row.total_scored}}
                                        </span>
                                    </td>
                                    <td colspan="3" v-if="!row.id">
                                        <div class="bg-light rounded p-3 text-center text-muted">Not working yet.</div>
                                    </td>
                                    <td v-if="!row.id">
                                        <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target="#set-manual-score" @click="setScoreManual(row)">Set Score Manual</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="(!dataFetch && emptyState)" class="text-center">
                        <img src="images/empty-box.svg" class="mx-auto d-block width mb-2" style="width:300px">
                        <p style="font-size:18px">Data not found</p>
                    </div>

                    <div class="d-block mt-3" v-if="!dataFetch & list_member.length > 0">
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="set-manual-score" tabindex="-1" role="dialog" aria-labelledby="set-manual-score" aria-hidden="true">
        <div class="modal-dialog modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Set Manual Score - {{detail.name}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="text-muted">Score</div>
                            <div class="input-group col-xs-12 mt-2">
                                <input type="number" :max="100" :min="0" class="form-control file-upload-info" v-model="detail.scored" placeholder="Insert score here..." v-on:keyup="setScored">
                                <span class="input-group-append">
                                    <button class="file-upload-browse btn btn-primary" type="button" @click="postScoreManual(detail.users_id, detail.scored)">Save Score</button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <button type="button" id="btnCloseModalManual" class="btn btn-light" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vPagination from 'vue-plain-pagination'

export default {
    name: 'QuizStatus',
    metaInfo: {
        title: 'Quiz Status',
        titleTemplate: '%s - Elearning'
    },
    components: {
        vPagination,
    },
    data() {
        return {
            emptyState  : false,
            dataFetch: true,
            list_member: [],
            keywords: '',
            pagination: {
                currentPage : 1,
                totalPages  : 1,
                limit       : 50,
                bootstrapPaginationClasses: {
                    ul: 'pagination justify-content-end',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                paginationAnchorTexts: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            },
            detail: {
                scored : 0
            }
        }
    },
    props: ['params', 'fetchDetailClass'],
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/result';
            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_id          : this.params.courses_id,
                    courses_lessons_id  : this.params.id,
                    with_answer         : '0',
                    page                : this.pagination.currentPage,
                    limit               : this.pagination.limit,
                    keywords            : this.keywords
                }
            }).then(res => {
                this.dataFetch      = false;
                this.emptyState     = false;

                this.list_member = [];
                if (res.data.status) {
                    this.list_member            = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                }

                if(this.list_member.length < 1) {
                    this.emptyState = true;
                }
            });
        },
        setScoreManual: function (data) {
            this.detail = data;
        },
        setScored: function() {
            if(this.detail.scored > 100) {
                this.detail.scored = 100;
            }

            if(this.detail.scored < 0) {
                this.detail.scored = 0;
            }
        },
        postScoreManual: function (users_id, scored) {
            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/save-scored-manual', {
                users_id            : users_id,
                courses_lessons_id  : this.params.id,
                scored              : scored
            })
            .then(response => {
                if (response.data.status) {
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            document.getElementById("btnCloseModalManual").click();                                
                            this.getData();
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                }
            })
        }

    }
}
</script>
