<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="row justify-content-center">
                <div class="col-md-8 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="card-title mb-0">Form Program</div>
                                <router-link :to="{name: 'program'}" class="btn btn-light">Back</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-subtitle mt-0">Please complete the form below.</div>
                            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                                <div class="form-group">
                                    <label for="code">Code</label>
                                    <input type="text" class="form-control" id="code" name="code" autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.code.$error }" v-model="form.code" placeholder="Enter code ...">
                                    <div v-if="submitted && !$v.form.code.required" class="invalid-feedback">Code is required!</div>
                                </div>
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" id="name" name="name" :class="{ 'is-invalid': submitted && $v.form.name.$error }" v-model="form.name" placeholder="Enter name ...">
                                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save</button>
                                    <router-link :to="{name: 'program'}" class="btn btn-light mt-2">Back</router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'ProgramForm',
        metaInfo: {
            title: 'Form Program',
            titleTemplate: '%s - Unfari E-learning'
        },
        components : {
        },
        created() {
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                id              : this.$route.params.id,
                form : {
                    id          : "",
                    code        : "",
                    name        : "",
                },
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                code: {
                    required
                },
                name: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    for (var index in this.form) {
                        formData.append(index, this.form[index]);
                    }

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/program/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'program' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Update";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/program/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.form       = getResponse;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'program' });
                                }
                            });
                        }
                    });
                }
            },
        }
    }
</script>