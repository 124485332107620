<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div v-if="dataFetch" class="page-content">
            <p>Please wait...</p>
        </div>
        <div v-if="!dataFetch" class="page-content">
            <!-- List of courses -->
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="d-block">
                        <div class="card rounded">
							<div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <h6 class="card-title mb-0">Courses Information</h6>
                                    </div>
                                    <div>
                                        <!-- <button v-if="data_members.length > 0" class="btn btn-success" type="button" v-on:click="exportExcel">Export Excel</button> -->
                                        <button v-if="data_members.length > 0" class="btn btn-danger ml-2" type="button" v-on:click="exportPDF" :disabled="isFetchPDF">{{isFetchPDF ? "Loading...":"Download PDF"}}</button>
                                        <button class="btn btn-light ml-2" v-on:click="goBack()">Back</button>  
                                    </div>
                                </div>
                                
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Courses Title:</label>
									<p class="text-muted">{{detail.name}}</p>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Moderator:</label>
									<p class="text-muted">{{detail.instructure}}</p>
								</div>

								<div class="mt-3" v-if="detail.teamteaching">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Co Moderator:</label>
									<p class="text-muted">
                                        <ul>
                                            <li v-for="(item, index) in detail.team_list">{{item}}</li>
                                        </ul>
                                    </p>
								</div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Total Members:</label>
									<p class="text-muted"><i class="feather icon-users"></i> {{detail.total_members}}</p>
								</div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Total Lessons:</label>
									<p class="text-muted"><i class="feather icon-book"></i> {{data_lessons.length}}</p>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Detail Lessons (Meeting):</label>
									<p class="text-muted">
                                        <ol class="mt-2">
                                            <li v-for="(item, index) in data_lessons" :key="index">{{item.sections_name+' - '+item.lessons_name }}</li>
                                        </ol>
                                    </p>
								</div>
							</div>

                            <div class="card-body" v-if="data_members.length > 0">
                                <div class="table-responsive table-bordered">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center" style="width:3%" rowspan="2">No</th>
                                                <th rowspan="2">Student Name</th>
                                                <th  class="text-center" v-if="data_lessons.length > 0" :colspan="data_lessons.length">Meeting to
                                                </th>
                                            </tr>
                                            <tr>
                                                <th class="text-center" v-for="(item, index) in data_lessons.length" :key="index">{{ ++index }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, index) in data_members" :key="index">
                                                <td style="width:3%" class="text-center">
                                                    {{++index}}
                                                </td>   
                                                <td style="width:50%">
                                                    {{ row.identity_number }}<br/>{{row.name}}
                                                </td>
                                                <td class="text-center" v-for="(rowDetail, indexDetail) in row.data_presence" :key="indexDetail">
                                                    <span v-if="rowDetail == '1'" style="color: green;"><b>&#10003;</b></span>
                                                    <span v-if="rowDetail == 'X'" style="color: red;"><b>X</b></span>
                                                    <span v-if="rowDetail == '0'" style="color: red;"><b>X</b></span>
                                                    <span v-if="rowDetail == '-'" style="color: black;"><b>-</b></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p class="mt-3">Keterangan:</p>
                                <ul>
                                    <li>Hadir (<span style="color: green;"><b>&#10003;</b></span>)</li>
                                    <li>Tidak Hadir (<span style="color: red;"><b>X</b></span>)</li>
                                    <li>Belum Hadir (<span style="color: black;"><b>-</b></span>)</li>
                                </ul>
                            </div>

                            <div class="card-body" v-if="data_members.length < 1">
                                <div class="rounded bg-light p-3 text-center">
                                    Members not found
                                </div>
                            </div>
						</div>                        
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ViewPresenceLecturer',
        metaInfo: {
            title: 'View Presence Lecturer',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination,
        },
        data() {
            return {
                isFetchPDF  : false,
                dataFetch   : true,
                courses_id  : this.$route.params.courses_id,
                users_id    : this.$route.params.users_id,
                detail      : {},
                data_members : [],
                data_lessons : [],
                keywords    : '',
                pagination  : {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 30,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getDataByID(this.courses_id, this.users_id);
        },
        methods: {
            getDataByID: function(courses_id, users_id) {
                let uri     = process.env.VUE_APP_APIHOST+'student/courses/presence-student/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : courses_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;
                        let getResponse     = res.data.data;
                        this.detail         = getResponse.courses;
                        this.data_members   = getResponse.data_members;
                        this.data_lessons   = getResponse.data_lessons;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                history.back();
                            }
                        });
                    }
                });
            },
            exportExcel: function() {
                let uri = process.env.VUE_APP_APIHOST+'teacher/export/teacher-presence?courses_id='+this.courses_id+'&users_id='+this.detail.users_id;
                window.open(uri);
            },
            exportPDF() {
                this.isFetchPDF = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'student/export/download-pdf-student-presence', {
                    params : {
                        courses_id : this.courses_id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchPDF = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "File gagal didownload",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Absensi Perkuliahan Mahasiswa Kelas '+this.detail.name}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            goBack: function() {
                history.back();                
            }
        }
    }
</script>