<template>
    <div class="card">
        <div class="card-body">
            <div class="card-subtitle mt-0">Please complete the form below.</div>
            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="program_id">Program</label>
                        <select class="form-control" id="program_id" name="program_id" v-model="form.program_id" :class="{ 'is-invalid': submitted && $v.form.program_id.$error }">
                            <option value="">Choose Program</option>
                            <option v-for="item in list_program" :key="item.id" :value="item.id">{{ item.name }}</option>
                        </select>
                        <div v-if="submitted && !$v.form.program_id.required" class="invalid-feedback">Program is
                            required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="program_study_id">Study Program</label>
                        <select class="form-control" id="program_study_id" name="program_study_id"
                            v-model="form.program_study_id" :class="{ 'is-invalid': submitted && $v.form.program_study_id.$error }" v-on:change="getStudyGroup">
                            <option value="">Choose Study Program</option>
                            <option v-for="item in list_program_study" :key="item.id" :value="item.id">{{ item.name }}
                            </option>
                        </select>
                        <div v-if="submitted && !$v.form.program_study_id.required" class="invalid-feedback">Study
                            Program is required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="education_level_id">Level of Education</label>
                        <select class="form-control" id="education_level_id" name="education_level_id"
                            v-model="form.education_level_id" :class="{ 'is-invalid': submitted && $v.form.education_level_id.$error }">
                            <option value="">Choose Level of Education</option>
                            <option v-for="item in list_education" :key="item.id" :value="item.id">{{ item.name }}
                            </option>
                        </select>
                        <div v-if="submitted && !$v.form.education_level_id.required" class="invalid-feedback">Level of
                            Education is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="curriculum_id">Curiculum</label>
                        <select class="form-control" id="curriculum_id" name="curriculum_id"
                            v-model="form.curriculum_id" :class="{ 'is-invalid': submitted && $v.form.curriculum_id.$error }">
                            <option value="">Choose Curriculum</option>
                            <option v-for="item in list_curriculum" :key="item.id" :value="item.id">{{ item.name }}
                            </option>
                        </select>
                        <div v-if="submitted && !$v.form.curriculum_id.required" class="invalid-feedback">Curriculum is
                            required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="study_group_id">Study Group</label>
                        <select class="form-control" id="study_group_id" name="study_group_id"
                            v-model="form.study_group_id" :class="{ 'is-invalid': submitted && $v.form.study_group_id.$error }">
                            <option value="">Choose Study Group</option>
                            <option v-for="item in list_study_group" :key="item.id" :value="item.id">{{ item.name }}
                            </option>
                        </select>
                        <div v-if="submitted && !$v.form.study_group_id.required" class="invalid-feedback">Study Group
                            is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="status_users_id">Status</label>
                        <select class="form-control" id="status_users_id" name="status_users_id"
                            v-model="form.status_users_id" :class="{ 'is-invalid': submitted && $v.form.status_users_id.$error }">
                            <option value="">Choose Status</option>
                            <option v-for="item in list_status" :key="item.id" :value="item.id">{{ item.name }}</option>
                        </select>
                        <div v-if="submitted && !$v.form.status_users_id.required" class="invalid-feedback">Status is
                            required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="year_entry">Entry Year</label>
                        <select class="form-control" id="year_entry" name="year_entry" v-model="form.year_entry">
                            <option value="">Choose Entry Year</option>
                            <option v-for="item in 25" :key="item" :value="1999+item">{{ (1999 + item) }}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label for="date_entry">Entry Date</label>
                        <div class="d-block">
                            <date-picker v-model="form.date_entry" class="w-100" valueType="format"></date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="type">Status Transfer College</label>
                        <select class="form-control" id="type" name="type" v-model="form.type">
                            <option value="new">New</option>
                            <option value="transfer">Transfer</option>
                        </select>
                        <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Type is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="study_limit">Study Limit</label>
                        <input type="text" class="form-control" id="study_limit" name="study_limit"
                            :class="{ 'is-invalid': submitted && $v.form.study_limit.$error }"
                            v-model="form.study_limit" placeholder="Enter Study of Limit ...">
                        <div v-if="submitted && !$v.form.study_limit.required" class="invalid-feedback">Study Limit is
                            required!</div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save & Next</button>
                    <router-link :to="{ name: 'student' }" class="btn btn-light mt-2">Back</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Academic',
    metaInfo: {
        title: 'Form Student',
        titleTemplate: '%s - Unfari E-learning'
    },
    props: {
        form: Object
    },
    created() {
        this.getCurriculum();
        this.getStatusUser();
        this.getStudyProgram();
        this.getProgram();
        this.getEducation();
    },
    data() {
        return {
            list_program: [],
            list_program_study: [],
            list_education: [],
            list_curriculum: [],
            list_study_group: [],
            list_status: [],
            submitted: false,
            waiting: false,
        }
    },
    validations: {
        form: {
            program_id: {
                required
            },
            program_study_id: {
                required
            },
            education_level_id: {
                required
            },
            curriculum_id: {
                required
            },
            study_group_id: {
                required
            },
            status_users_id: {
                required
            },
            type: {
                required
            },
            study_limit: {
                required
            },
        }
    },
    methods: {
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the forms',
                });

                this.waiting = false;
                return;
            } else {
                this.$emit("listenerChild", this.form);
            }
        },
        getCurriculum: function () {
            let uri = process.env.VUE_APP_APIHOST + 'admin/curriculum/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_curriculum = res.data.data;
                }
            });
        },
        getStudyProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'admin/program-study/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_program_study = res.data.data;
                    this.getStudyGroup();
                }
            });
        },
        getProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'admin/program/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_program = res.data.data;
                }
            });
        },
        getEducation: function () {
            let uri = process.env.VUE_APP_APIHOST + 'admin/education/list';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_education = res.data.data;
                }
            });
        },
        getStatusUser: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/status_users';

            this.$http.get(uri, {
            }).then(res => {
                if (res.data.status) {
                    this.list_status = res.data.data;
                }
            });
        },
        getStudyGroup: function () {
            if(this.form.program_study_id) {
                let uri = process.env.VUE_APP_APIHOST + 'admin/study-group/list';

                this.$http.get(uri, {
                    params : {
                        program_study_id: this.form.program_study_id
                    }
                }).then(res => {
                    if (res.data.status) {
                        this.list_study_group = res.data.data;
                    }
                });
            }
        },
    }
}
</script>