<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <!-- List of courses -->
        <div class="row justify-content-center">
            <div class="col-md-12 col-lg-8">
                <div class="d-block mb-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="d-block">
                                    <h6 id="default">{{form.id ? 'Edit Lesson':'Create New Lesson'}}</h6>
                                </div>
                                <div class="d-flex align-items-center justify-content-end w-25">
                                    <router-link class="btn btn-light" :to="{name: 'courses_section', params: {id: form.courses_id}}">Back</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-block">
                    <div class="card">
                        <div class="card-body">
                            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                                <div class="form-group">
                                    <label for="title">Lesson Title</label>
                                    <input type="text" class="form-control" id="title" autocomplete="off" v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$error }" placeholder="Enter lesson title ...">
                                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Lesson title is required!</div>
                                </div>
                                <div class="form-group row" style="margin-top: -20px;">
                                    <div class="col-md-6 mt-3">
                                        <label for="exampleInputEmail1">Lesson type</label>
                                        <select v-model="form.type" :class="{ 'is-invalid': submitted && $v.form.type.$error }" v-on:change="changeFormat">
                                            <option value="">Choose Lesson Type</option>
                                            <option value="theory">Theory</option>
                                            <option value="assignment">Assignment</option>
                                            <option value="quiz">Quiz</option>
                                            <option value="fgd">FGD</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.type.required" class="invalid-feedback">Lesson type is required!</div>
                                    </div>

                                    <div class="col-md-6 mt-3" v-if="form.type == 'quiz'">
                                        <label for="exampleInputEmail1">Show Scored</label>
                                        <select v-model="form.show_value">
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                    </div>
                                    <div class="col-md-6 mt-3" v-if="form.type != 'quiz' && form.type != 'fgd'">
                                        <label for="exampleInputEmail1">Format Lesson Description</label>
                                        <select v-model="form.format" :class="{ 'is-invalid': submitted && $v.form.format.$error }">
                                            <option value="">Choose Descriptipn</option>
                                            <option value="text">Text</option>
                                            <option value="youtube">Youtube</option>
                                            <option value="video">Video</option>
                                            <option value="audio">Audio</option>
                                            <option value="file">File (PDF, DOC, PPT)</option>
                                        </select>
                                        <div v-if="submitted && !$v.form.format.required" class="invalid-feedback">Format lesson is required!</div>
                                    </div>
                                </div>
                                
                                <div class="mt-2 p-3 rounded bg-light" v-if="form.type == 'fgd'">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1">Get URL From</label>
                                        <select v-model="form.webinar.type">
                                            <option value="master">Webinar</option>
                                            <option value="create">Create New</option>
                                        </select>
                                    </div>
                                    <div class="form-group" v-if="form.type == 'fgd' && form.webinar.type == 'master'">
                                        <label>Choose Webinar</label>
                                        <multiselect v-model="form.select_webinar" :options="dataConferences" placeholder="Choose Webinar" :show-labels="false" label="name" track-by="name">
                                            <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                        </multiselect>
                                    </div>
                                    <div class="mt-2 mb-3" v-if="form.type == 'fgd' && form.webinar.type == 'create'">
                                        <div class="form-group mb-1">
                                            <label>URL Webinar</label>
                                            <input type="text" class="form-control" placeholder="Input URL Webinar here ..." v-model="form.webinar.url" />
                                        </div>
                                        <div class="form-group row">
                                            <div class="col-md-6">
                                                <label for="date">Date</label>
                                                <div class="d-block w-100">
                                                    <date-picker v-model="form.webinar.date" class="w-100" valueType="format" placeholder="yyyy-mm-dd">
                                                    </date-picker>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <label for="date">Time</label>
                                                <div class="d-block w-100">
                                                    <date-picker v-model="form.webinar.time" :showSecond="false" class="w-100" type="time" format="HH:mm" placeholder="HH:mm"
                                                        valueType="format"></date-picker>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mt-2" v-if="form.type == 'quiz'">
                                    <label>Duration</label>
                                    <input type="number" class="form-control" placeholder="Input duration here ..." v-model="form.duration" />
                                </div>
                                <div class="form-group mt-2" v-if="['video', 'audio', 'file'].includes(form.format)">
                                    <label>Attachment</label>
                                    <input type="file" class="form-control" placeholder="Choose File" @change="previewFiles" />
                                    <a :href="form.attachment" class="d-block bg-light rounded text-dark mt-3 p-3" v-if="form.attachment && form.id" target="_blank"><i class="feather icon-eye mr-3"></i>See Prevous Attachment</a>
                                </div>
                                <div class="form-group mt-2" v-if="form.type == 'quiz' || form.format == 'text' && form.type != 'fgd'">
                                    <label>Description</label>
                                    <ckeditor v-model="form.description" :config="editorConfig" class="mt-3"></ckeditor>
                                </div>
                                <div class="form-group mt-2" v-if="form.format == 'youtube'">
                                    <label>URL youtube</label>
                                    <input type="text" class="form-control" v-model="form.description" placeholder="Input url youtube here ..." />
                                </div>
                                <div class="form-group mt-2">
                                    <label>Note</label>
                                    <input type="text" class="form-control" v-model="form.note" placeholder="Input note here ..." />
                                </div>
                                <div class="form-group mt-2 d-none">
                                    <label>Reference</label>
                                    <input type="text" class="form-control" v-model="form.preference" placeholder="Input preference here ..." />
                                </div>
                                <div class="form-group mt-2">
                                    <label>Date Expired</label>
                                    <div class="d-block">
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="optionsRadios5" id="optionsRadios6" value="0" v-model="form.can_expired">
                                                Not Expired
                                                <i class="input-frame"></i></label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <label class="form-check-label">
                                                <input type="radio" class="form-check-input" name="optionsRadios5" id="optionsRadios6" value="1" v-model="form.can_expired">
                                                Expired
                                                <i class="input-frame"></i></label>
                                        </div>
                                        <div class="d-block rounded bg-light p-3" v-if="form.can_expired == '1'">
                                            <label>Start Date</label>
                                            <div class="form-group row pb-0 pt-0">
                                                <div class="col-md-6">
                                                    <div class="d-block w-100">
                                                        <date-picker v-model="form.start_date" class="w-100" valueType="format"></date-picker>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="d-block w-100">
                                                        <date-picker v-model="form.start_time" :showSecond="false" class="w-100" type="time" format="HH:mm" placeholder="HH:mm"
                                                            valueType="format"></date-picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="d-block rounded bg-light p-3" v-if="form.can_expired == '1'" style="margin-top:-10px">
                                            <label>End Date</label>
                                            <div class="form-group row pb-0 pt-0">
                                                <div class="col-md-6">
                                                    <div class="d-block w-100">
                                                        <date-picker v-model="form.end_date" class="w-100" valueType="format"></date-picker>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="d-block w-100">
                                                        <date-picker v-model="form.end_time" :showSecond="false" class="w-100" type="time" format="HH:mm" placeholder="HH:mm"
                                                            valueType="format"></date-picker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Status</label>
                                    <select class="form-control col-md-3" v-model="form.status" :class="{ 'is-invalid': submitted && $v.form.status.$error }">
                                        <option value="draft">Unpublish</option>
                                        <option value="publish">Publish</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.status.required" class="invalid-feedback">Status is required!</div>
                                </div>
                                <button type="submit" class="btn btn-primary mr-2 mt-2">Submit</button>
                                <router-link class="btn btn-light mt-2" :to="{name: 'courses_section', params: {id: form.courses_id}}">Cancel</router-link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- row -->
    </div>
    <foot></foot>
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'FormLesson',
    metaInfo: {
        title: 'Form Lessons',
        titleTemplate: '%s - Elearning'
    },
    data() {
        return {
            dataFetch: true,
            dataSections: [],
            form: {
                id: (this.$route.params.courses_lessons_id ? this.$route.params.courses_lessons_id : ''),
                courses_id: this.$route.params.courses_id,
                courses_sections_id: this.$route.params.courses_sections_id,
                name: "",
                type: "",
                format: "",
                description: "",
                note: "",
                can_expired: "0",
                start_date: "",
                start_time : "",
                end_date: "",
                end_time: "",
                duration: "30",
                status: "draft",
                webinar: {
                    id: '',
                    type: 'master',
                    url: '',
                    date: '',
                    time: ''
                },
                attachment: '',
                preference: '',
                show_value  : 1,
                select_webinar: []
            },
            dataConferences: [],
            editorConfig: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ]
            },
            submitted: false,
            waiting: false
        }
    },
    created() {
        this.getDataSections();
        this.getDataByID(this.form.id, this.form.courses_id);
        this.getDataConferences();
    },
    methods: {
        getDataSections: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-sections/detail';
            this.$http.get(uri, {
                params : {
                    id : this.form.courses_sections_id
                }
            }).then(res => {
                this.dataSections = res.data.data;

                if(this.dataSections.default_study_time) {
                    if(this.form.id == '') {
                        this.form.can_expired = 1;                        
                        this.form.start_date = this.dataSections.start_date;
                        this.form.start_time = this.dataSections.start_time;
                        this.form.end_date   = this.dataSections.start_date;
                        this.form.end_time   = this.dataSections.end_time;
                    }
                }
            });
        },
        getDataConferences: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/conference/list-all';
            this.$http.get(uri).then(res => {
                this.dataConferences = res.data.data;
            });
        },
        getDataByID: function (id, courses_id) {
            if (typeof (id) !== 'undefined' && id !== null) {
                this.label = "Update";

                if(id != '') {
                let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/detail';

                this.dataFetch = true;
                this.$http.get(uri, {
                    params: {
                        courses_id: courses_id,
                        courses_lessons_id: id
                    }
                }).then(res => {
                    if (res.data.status) {
                        let getResponse = res.data.data;
                        this.form = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'courses_section',
                                    params: {
                                        id: this.form.courses_id
                                    }
                                });
                            }
                        });
                    }
                });
                }
            }
        },
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();
                for (var index in this.form) {
                    if (index == 'webinar') {
                        if(this.form.select_webinar.id) {
                            this.form.webinar.id = this.form.select_webinar.id;
                            this.form.select_webinar = JSON.stringify(this.form.select_webinar);
                        }
                        
                        let webinar = {};

                        webinar.id =  this.form.webinar.id;
                        webinar.type =  this.form.webinar.type;
                        webinar.date =  "";
                        if(this.form.webinar.date) {
                            webinar.date =  this.form.webinar.date;
                        }

                        webinar.time =  "";
                        if(this.form.webinar.time) {
                            webinar.time =  this.form.webinar.time;
                        }

                        webinar.url =  "";
                        if(this.form.webinar.url) {
                            webinar.url =  this.form.webinar.url;
                        }
                        

                        this.form.webinar = webinar;
                        formData.append(index, JSON.stringify(this.form[index]));
                    }
                    else {
                        formData.append(index, this.form[index]);
                    }
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'courses_section',
                                        params: {
                                            id: this.form.courses_id
                                        }
                                    });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.form.attachment = file;
            }
        },
        changeFormat: function() {
            if(this.form.type == 'quiz' || this.form.type == 'fgd') {
                this.form.format = 'text';
            }
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            type: {
                required
            },
            format: {
                required
            },
            can_expired: {
                required
            },
            status: {
                required
            },
        }
    },
}
</script>
