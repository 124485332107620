import Vue from 'vue'
import VueRouter from 'vue-router'
import Axios from 'axios'
// import VueBodyClass from 'vue-body-class';

import Signin from '../views/authentication/Signin.vue'
import Signout from '../views/authentication/Signout.vue'
import NavBarMenu from '@/components/NavBarMenu.vue';
import SideBarMenu from '@/components/SideBarMenu.vue';

import HomePage from '../views/HomePage.vue';

// Pages for student
import Dashboard from '../views/student/Dashboard.vue'
import Courses from '../views/student/courses/Courses.vue'
import CoursesDetail from '../views/student/courses/CoursesDetail.vue'
import ViewMaterial from '../views/student/courses/ViewMaterial.vue'
import Assignment from '../views/student/courses/Assignment.vue'
import QuizOverview from '../views/student/courses/QuizOverview.vue'
import Quiz from '../views/student/courses/Quiz.vue'
import Schedule from '../views/student/calendar/Schedule.vue'
import Reports from '../views/student/reports/Reports.vue'
import PresenceReportStudent from '../views/student/reports/StudentPresence.vue'
import Faq from '../views/student/Faq.vue'
import WebinarMain from '../views/student/webinar/Main.vue'
import WebinarDetail from '../views/student/webinar/Detail.vue'
import AllScheduleStudent from '../views/student/schedule/View.vue'

// Pages for administrator
import DashboardAdmin from '../views/administrator/Dashboard.vue'
// Page Master Data
import YearPeriod from '../views/administrator/year/Main.vue'
import YearPeriodForm from '../views/administrator/year/Form.vue'
import Program from '../views/administrator/program/Main.vue'
import ProgramForm from '../views/administrator/program/Form.vue'
import StudyProgram from '../views/administrator/study_program/Main.vue'
import StudyProgramForm from '../views/administrator/study_program/Form.vue'
import Education from '../views/administrator/education/Main.vue'
import EducationForm from '../views/administrator/education/Form.vue'
import Curriculum from '../views/administrator/curriculum/Main.vue'
import CurriculumForm from '../views/administrator/curriculum/Form.vue'
import Announcement from '../views/administrator/announcement/Main.vue'
import AnnouncementForm from '../views/administrator/announcement/Form.vue'
import PresenceTeacher from '../views/administrator/presence/Main.vue'
import ViewPresenceTeacher from '../views/administrator/presence/Detail.vue'
import LearningRecap from '../views/administrator/recap/Learning.vue'
import LearningRecapDetail from '../views/administrator/recap/LearningDetail.vue'
// End Page Master Data

// Page Manage Account
import StudyGroup from '../views/administrator/study_group/Main.vue'
import StudyGroupForm from '../views/administrator/study_group/Form.vue'
import Student from '../views/administrator/student/Main.vue'
import StudentForm from '../views/administrator/student/Form.vue'
import StudentImport from '../views/administrator/student/Import.vue'
import Teacher from '../views/administrator/teacher/Main.vue'
import TeacherForm from '../views/administrator/teacher/Form.vue'
import Prodi from '../views/administrator/prodi/Main.vue'
import ProdiForm from '../views/administrator/prodi/Form.vue'

// End Page Manage Account

// Page Manage Courses
import CoursesCategory from '../views/administrator/courses_category/Main.vue'
import CoursesCategoryForm from '../views/administrator/courses_category/Form.vue'
import ManageCourses from '../views/administrator/courses/Main.vue'
import ManageCoursesForm from '../views/administrator/courses/Form.vue'
import ManageCoursesMembers from '../views/administrator/courses/Members.vue'
// End Page Manage Account

// End Pages for administrator

// pages for teacher
import DashboardTeacher from '../views/teacher/Dashboard.vue'

// page all schedule
import AllScheduleTeacher from '../views/teacher/schedule/View.vue'

// Page Courses
import CoursesTeacher from '../views/teacher/courses/Courses.vue'
import CoursesDetailTeacher from '../views/teacher/courses/CoursesDetail.vue'
import ViewMaterialTeacher from '../views/teacher/courses/ViewMaterial.vue'
import ViewPresenseLessons from '../views/teacher/courses/ViewPresence.vue'
import FormLesson from '../views/teacher/courses/FormLesson.vue'
import ManageAssignment from '../views/teacher/courses/ManageAssignment.vue'
import CoursesManageQuiz from '../views/teacher/courses/ManageQuiz.vue'
// import ViewQuestion from '../views/teacher/courses/ViewQuestion.vue'

import ReportTeacher from '../views/teacher/report/Report.vue'
import LearningRecapTeacher from '../views/teacher/report/LearningRecap.vue'
import ViewReportingPresenceTeacher from '../views/teacher/report/Presence.vue'
import ViewReportingPresenceStudent from '../views/student/reports/Presence.vue'
import ViewReportingRecapScoreStudent from '../views/teacher/report/RecapScore.vue'

// End Page Courses

// Page Webinarz
import Meeting from '../views/teacher/webinar/Main.vue'
import MeetingForm from '../views/teacher/webinar/Form.vue'
// End Page Webinar

// Page My Profile 
import MyProfile from '../views/profile/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
    meta: {
      transition  : 'fade'
    }
  },
  {
    path: '/signout',
    name: 'signout',
    component: Signout,
    meta: {
      transition  : 'fade',
      roles:['administrator', 'student', 'teacher']
    }
  },
  {
    path: '/my/profile',
    name: 'my_profile',
    component: MyProfile,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator','teacher']
    }
  },
  {
    path: '/student/profile',
    name: 'my_profile_student',
    component: MyProfile,
    meta: {
      transition : 'fade',
      layout : NavBarMenu,
      class_parent: 'main-wrapper',
      roles:['student']
    }
  },
  // START ROUTES FOR STUDENT
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      transition : 'fade',
      layout : NavBarMenu,
      roles:['administrator', 'student', 'teacher','prodi']
    }
  },
  {
    path: '/all-schedule-student',
    name: 'all_schedule_student',
    component: AllScheduleStudent,
    meta: {
      transition : 'fade',
      layout : NavBarMenu,
      class_parent: 'main-wrapper',
      roles:['student']
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomePage,
    meta: {
      transition : 'fade',
      roles:['administrator', 'student', 'teacher','prodi']
    }
  },
  {
    path: '/courses',
    name: 'courses',
    component: Courses,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles : ['student']
    }
  },
  {
    path: '/learningpath/:id',
    name: 'learningpath',
    component: CoursesDetail,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/material/:courses_id/:id',
    name: 'view_material',
    component: ViewMaterial,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/assignment/:courses_id/:id',
    name: 'assignment',
    component: Assignment,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/quiz/overview/:courses_id/:id',
    name: 'quiz_overview',
    component: QuizOverview,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/quiz/start/:courses_id/:id',
    name: 'quiz_start',
    component: Quiz,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: Schedule,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/webinar',
    name: 'webinar',
    component: WebinarMain,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/webinar/detail/:id',
    name: 'webinar_detail',
    component: WebinarDetail,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/presence-report',
    name: 'presence_report',
    component: PresenceReportStudent,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      transition  : 'fade',
      layout : NavBarMenu,
      roles: ['student']
    }
  },
  // END ROUTES FOR STUDENT
  // START ROUTES FOR ADMINISTRATOR
  {
    path: '/dashboard',
    name: 'home_admin',
    component: DashboardAdmin,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator','prodi']
    }
  },
  {
    path: '/year',
    name: 'year_period',
    component: YearPeriod,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/year/add',
    name: 'year_period_add',
    component: YearPeriodForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/year/edit/:id',
    name: 'year_period_edit',
    component: YearPeriodForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/program',
    name: 'program',
    component: Program,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/program/add',
    name: 'program_add',
    component: ProgramForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/program/edit/:id',
    name: 'program_edit',
    component: ProgramForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/study_program',
    name: 'study_program',
    component: StudyProgram,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/study_program/add',
    name: 'study_program_add',
    component: StudyProgramForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/study_program/edit/:id',
    name: 'study_program_edit',
    component: StudyProgramForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/presence_teacher',
    name: 'presence_teacher',
    component: PresenceTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/presence_teacher/view/:courses_id/:users_id',
    name: 'view_presence_teacher',
    component: ViewPresenceTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/learning_recap',
    name: 'learning_recap',
    component: LearningRecap,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/learning_recap/detail/:periode_id/:users_id',
    name: 'learning_recap_detail',
    component: LearningRecapDetail,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/student',
    name: 'student',
    component: Student,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/student/add',
    name: 'student_add',
    component: StudentForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/student/edit/:id',
    name: 'student_edit',
    component: StudentForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/student/import',
    name: 'student_import',
    component: StudentImport,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/teacher',
    name: 'teacher',
    component: Teacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/teacher/add',
    name: 'teacher_add',
    component: TeacherForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/teacher/edit/:id',
    name: 'teacher_edit',
    component: TeacherForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/prodi',
    name: 'prodi',
    component: Prodi,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/prodi/add',
    name: 'prodi_add',
    component: ProdiForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/prodi/edit/:id',
    name: 'prodi_edit',
    component: ProdiForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/education',
    name: 'education_level',
    component: Education,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/education/add',
    name: 'education_level_add',
    component: EducationForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/education/edit/:id',
    name: 'education_level_edit',
    component: EducationForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/curriculum',
    name: 'curriculum',
    component: Curriculum,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/curriculum/add',
    name: 'curriculum_add',
    component: CurriculumForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/curriculum/edit/:id',
    name: 'curriculum_edit',
    component: CurriculumForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/courses_category',
    name: 'courses_category',
    component: CoursesCategory,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/courses_category/add',
    name: 'courses_category_add',
    component: CoursesCategoryForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/courses_category/edit/:id',
    name: 'courses_category_edit',
    component: CoursesCategoryForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/study_group',
    name: 'study_group',
    component: StudyGroup,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/study_group/add',
    name: 'study_group_add',
    component: StudyGroupForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/study_group/edit/:id',
    name: 'study_group_edit',
    component: StudyGroupForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/manage_course',
    name: 'manage_course',
    component: ManageCourses,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/manage_course/add',
    name: 'manage_course_add',
    component: ManageCoursesForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/manage_course/edit/:id',
    name: 'manage_course_edit',
    component: ManageCoursesForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/manage_course/members/:id',
    name: 'manage_course_members',
    component: ManageCoursesMembers,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/announcement',
    name: 'announcement',
    component: Announcement,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/announcement/add',
    name: 'announcement_add',
    component: AnnouncementForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  {
    path: '/announcement/edit/:id',
    name: 'announcement_edit',
    component: AnnouncementForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator']
    }
  },
  // END ROUTES FOR ADMINISTRATOR
  // START ROUTES FOR TEACHER
  {
    path: '/dashboard',
    name: 'home_teacher',
    component: DashboardTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/all-schedule-teacher',
    name: 'all_schedule_teacher',
    component: AllScheduleTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/manage',
    name: 'courses_teacher',
    component: CoursesTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/manage/sections/:id',
    name: 'courses_section',
    component: CoursesDetailTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/manage/quiz/:courses_id/:id',
    name: 'courses_manage_quiz',
    component: CoursesManageQuiz,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/material/view/:courses_id/:id',
    name: 'view_material_teacher',
    component: ViewMaterialTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/presence/view/:courses_id/:id',
    name: 'view_presence_lessons',
    component: ViewPresenseLessons,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/lessons/create/:courses_id/:courses_sections_id',
    name: 'form_lesson_create',
    component: FormLesson,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/lessons/edit/:courses_id/:courses_sections_id/:courses_lessons_id',
    name: 'form_lesson_edit',
    component: FormLesson,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/courses/assignment/manage/:courses_id/:id',
    name: 'courses_assignment_manage',
    component: ManageAssignment,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/meeting',
    name: 'meeting',
    component: Meeting,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/meeting/add',
    name: 'meeting_add',
    component: MeetingForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/meeting/edit/:id',
    name: 'meeting_edit',
    component: MeetingForm,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/reporting',
    name: 'reporting',
    component: ReportTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/learning_recap_teacher',
    name: 'learning_recap_teacher',
    component: LearningRecapTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/reporting_presence_teacher/view/:courses_id',
    name: 'view_reporting_presence_teacher',
    component: ViewReportingPresenceTeacher,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['teacher']
    }
  },
  {
    path: '/reporting_presence_student/view/:courses_id',
    name: 'view_reporting_presence_student',
    component: ViewReportingPresenceStudent,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator','teacher','student']
    }
  },
  {
    path: '/reporting_recap_score_student/view/:courses_id',
    name: 'view_reporting_recap_score_student',
    component: ViewReportingRecapScoreStudent,
    meta: {
      transition : 'fade',
      layout : SideBarMenu,
      class_parent: 'main-wrapper',
      roles:['administrator','teacher','student']
    }
  },
  // END ROUTES FOR TEACHER
]

// const vueBodyClass = new VueBodyClass(routes);

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages   = ['signin'];
  const authRequired  = !publicPages.includes(to.name);
  const token         = localStorage.getItem('auth_token_lms');


  if (authRequired && token) {
        Axios.get(process.env.VUE_APP_APIHOST + 'user/info', {
            headers: {
                'Authorization': token
            }
        }).then(response => {
            if(response.data.logged_in) {
                let roles = response.data.role;
                let name = response.data.name;
                localStorage.setItem('role', roles);
                localStorage.setItem('name', name);
                
                if(to.meta.roles.includes(roles)) {
                  if (to.name == 'dashboard' && roles == 'administrator' || to.name == 'home' && roles == 'administrator' || to.name == 'dashboard' && roles == 'prodi' || to.name == 'home' && roles == 'prodi') {
                    next({name: 'home_admin'})
                  } else if (to.name == 'dashboard' && roles == 'teacher' || to.name == 'home' && roles == 'teacher') {
                    next({name: 'home_teacher'})
                  } else {
                    next();
                  }
                } else {
                    alert("Akses tidak diperbolehkan");
                    if (roles == 'administrator' || roles == 'prodi')
                      next({name: 'home_admin'})
                    else
                      next({ name: 'dashboard' });
                }
            } else {
                alert("Sesi login telah habis !");
                localStorage.removeItem('id');
                localStorage.removeItem('auth_token_lms');
                next({ name: 'signin' });
            }
        }).catch(function (error) {
            console.debug(error);
        });
    // } else if (authRequired && !token) {
    //   next();
    } else {
        if(to.name == 'signin') {
            if(token) {
                next({ name: 'dashboard' });
            } else {
                next();
            }
        } else {
          next();
        }
    }
//   vueBodyClass.guard(to, next);
});


export default router
