<template>
<div class="page-wrapper">
    <div class="page-content">
        <div class="d-block mb-3">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center">
                        <select class="form-control col-md-3" v-model="params.periode_id" @change="fetchCourses()">
                            <option value="">Select Year Period</option>
                            <option v-for="row in periode" :key="row.id" :value="row.id">{{row.name}} {{row.active == '1' ? '(Active)' : ''}}</option>
                        </select>
                        <select class="form-control mx-2 col-md-4" v-model="params.courses_id" @change="fetchData()">
                            <option value="">Select courses</option>
                            <option v-for="row in courses" :key="row.id" :value="row.id">{{row.name}}</option>
                        </select>
                        <input type="text" class="form-control w-100" placeholder="Enter the keyword then press enter ..." v-model="params.keywords" v-on:keyup.enter="fetchData()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!dataFetch" >
            <div class="col-lg-12 col-xl-12 mb-3 grid-margin grid-margin-xl-0">
                <div class="card my-3" v-if="data.length > 0" v-for="row in data" :key="row.id">
                    <div class="card-body">
                        <div class="d-block">
                            <div class="d-flex align-items-center border-bottom pb-3">
                                <div class="d-flex justify-content-center align-items-center rounded-circle bg-secondary wd-40 ht-40 text-white mr-3"><i class="my-0 mdi mdi-cards tx-20"></i></div>
                                <div class="d-block">
                                        <div class="mb-0 ml-2 tx-17 font-weight-bold">{{row.name}}</div>
                                        <div class="mb-0 ml-2 tx-15 text-muted" v-if="row.list_lessons.length > 0">{{row.list_lessons.length}} Material(s)</div>
                                        <div class="mb-0 ml-2 tx-15 text-muted" v-if="row.list_lessons.length == 0">No material yet</div>
                                    </div>
                            </div>
                            <div class="d-block my-4 pl-5 w-100" v-for="(lesson, index) in row.list_lessons" :key="lesson.id">
                                <div class="d-flex justify-content-between">
                                    <h6 class="text-body mb-2 tx-17">{{index + 1}}. {{lesson.name}}</h6>
                                    <div class="d-flex mt-0">
                                        <label class="badge badge-primary mr-2 tx-12">{{lesson.type}}</label>
                                        <label class="badge badge-success tx-12">{{lesson.format}}</label>
                                    </div>
                                </div>
                                <div class="d-flex rounded bg-light p-2 mt-2 justify-content-between align-items-center">
                                    <div class="tx-14 text-dark font-weight-bold">Score</div>
                                    <div class="tx-20 font-weight-bold">{{lesson.scored}}</div>
                                </div>
                                <div class="d-none rounded bg-light p-2 mt-2 justify-content-between align-items-center">
                                    <div class="tx-14 text-dark font-weight-bold">Score Remedial</div>
                                    <div class="tx-20 font-weight-bold">80.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="data.length == 0">
                    <div class="d-flex align-items-center justify-content-center rounded bg-light p-3 text-muted" v-if="params.courses_id && params.periode_id">Score not available</div>
                    <div class="d-flex align-items-center justify-content-center rounded bg-light p-3 text-muted" v-if="!params.courses_id || !params.periode_id">Please choose year period and courses</div>
                </div>
            </div>
        </div>
        <div v-if="dataFetch" class="d-flex align-items-center justify-content-center rounded bg-light p-3 text-muted">
            Loading...
        </div>

    </div>
    <foot></foot>
</div>
</template>

<script>
export default {
    name: 'Report',
    metaInfo: {
        title: 'Report',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: false,
            params: {
                periode_id: '',
                courses_id: '',
                keywords: ''
            },
            periode: [],
            courses: [],
            data: []
        }
    },
    created() {
        this.fetchPeriode();
    },
    methods: {
        fetchData: function () {
            this.data = []
            this.dataFetch = true;
            if (this.params.courses_id  && this.params.periode_id) {
                let uri = process.env.VUE_APP_APIHOST + 'student/courses-lessons/report';

                this.$http.get(uri, {
                    params: this.params
                }).then(res => {
                    if (res.data.status) {
                        this.data = res.data.data;
                    }

                    this.dataFetch = false;                    
                });
            }
        },
        fetchPeriode: function () {
            this.periode = [];
            let uri = process.env.VUE_APP_APIHOST + 'general/list/periode';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.periode = res.data.data;
                    
                    if(this.periode) {
                        this.params.periode_id = this.periode[0].id;
                        this.fetchCourses();
                    }
                }
            });
        },
        fetchCourses: function () {
            this.courses = [];
            if(this.params.periode_id) {
                let uri = process.env.VUE_APP_APIHOST + 'student/courses/list';

                this.$http.get(uri, {
                    params: {
                        periode_id : this.params.periode_id
                    }
                }).then(res => {
                    if (res.data.status) {
                        this.courses = res.data.data;
                    }
                });
            }
        },
    }
}
</script>
