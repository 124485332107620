<template>
    <div class="card">
        <div class="card-body">
            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                <div class="form-group">
                    <label for="password">Old Password</label>
                    <input type="password" class="form-control" id="password" name="password" autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.old_password.$error }" v-model="form.old_password" placeholder="Enter new password ...">
                    <div v-if="submitted && !$v.form.old_password.required" class="invalid-feedback">Old Password is required!</div>
                </div>
                <div class="form-group">
                    <label for="password">New Password</label>
                    <input type="password" class="form-control" id="password" name="password" autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.new_password.$error }" v-model="form.new_password" placeholder="Enter new password ...">
                    <div v-if="submitted && !$v.form.new_password.required" class="invalid-feedback">Password is required!</div>
                    <div class="alert alert-info mt-2"><i class="mdi mdi-information mr-2"></i>Use a minimum of 8 or more characters with a combination of letters (A-Z) and numbers (0-9)</div>
                </div>
                <div class="form-group">
                    <label for="retype_new_password">Confirmation Password</label>
                    <input type="password" class="form-control" id="retype_new_password" name="retype_new_password" :class="{ 'is-invalid': submitted && $v.form.retype_new_password.$error }" v-model="form.retype_new_password" placeholder="Confirm new password ...">
                    <div v-if="submitted && !$v.form.retype_new_password.required" class="invalid-feedback">Confirm Password is required!</div>
                    <div class="alert alert-info mt-2"><i class="mdi mdi-information mr-2"></i>Use a minimum of 8 or more characters with a combination of letters (A-Z) and numbers (0-9)</div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save New Password</button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Password',
        metaInfo: {
            title: 'Change Password',
            titleTemplate: '%s - Unfari E-learning'
        },
        props: {
            form: Object
        },
        components : {
        },
        created() {
        },
        data() {
            return {
                submitted           : false,
                waiting             : false,
            }
        },
        validations: {
            form: {
                old_password: {
                    required
                },
                new_password: {
                    required
                },
                retype_new_password: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else if (this.form.new_password != this.form.retype_new_password) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Password not match!',
                    });

                    this.waiting = false;
                    return;
                } else {
                    let formData = new FormData();
                    formData.append('old_password', this.form.old_password);
                    formData.append('new_password', this.form.new_password);
                    formData.append('retype_new_password', this.form.retype_new_password);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/profile/change_password', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        location.reload();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waiting = false;
                            return;
                        });
                }
            },
        }
    }
</script>