<template>
    <div class="text-center">
        <img src="../../public/images/empty-box.svg" class="mx-auto d-block width">
        <h4>Data not found</h4>
    </div>
</template>
<script>
export default {
    name : 'HandlingNull'
}
</script>
<style scoped>
    .width{
        width: 30%;
    }
</style>