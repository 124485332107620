<template>
<div class="row">
    <div class="col-md-8 col-sm-12 mt-3">
        <form class="forms-sample" v-on:submit.prevent="saveQuestion" ref="formQuestion">
            <div class="card ">
                <div class="card-header">
                    <p class="font-weight-bold">Add New Questions</p>
                </div>
                <div class="card-body pt-0">
                    <div class="form-group row">
                        <div class="col-md-6 my-1">
                            <label for="exampleInputEmail1">Point <span class="text-danger">(*)</span></label>
                            <input type="number" class="form-control" id="exampleInputEmail1" v-model="form_question.point" placeholder="Enter point..." :class="{ 'is-invalid': submitted && $v.form_question.point.$error }">
                            <div v-if="submitted && !$v.form_question.point.required" class="invalid-feedback">Point is required</div>
                        </div>
                        <div class="col-md-6 my-1">
                            <label for="exampleInputEmail1">Quiz type <span class="text-danger">(*)</span></label>
                            <select v-model="form_question.type" :class="{ 'is-invalid': submitted && $v.form_question.type.$error }">
                                <option value="">Choose Quiz Type</option>
                                <option value="multiple_choice">Multiple Choice</option>
                                <option value="match">Match</option>
                                <option value="essay">Esaay</option>
                            </select>
                            <div v-if="submitted && !$v.form_question.type.required" class="invalid-feedback">Quiz type is required</div>
                        </div>
                    </div>
                    <div class="form-group mt-1 bg-light p-3 border-radius-1">
                        <div class="d-flex justify-content-between">
                            <div for="exampleInputEmail1">Question</div>
                            <a href="javascript:void(0)" v-if="!data.show_quest" v-on:click="showContent('quest')" class="text-dark">Show <i class="feather icon-chevron-down"></i></a>
                            <a href="javascript:void(0)" v-if="data.show_quest" v-on:click="hideContent('quest')" class="text-dark">Hide <i class="feather icon-chevron-up"></i></a>
                        </div>
                        <ckeditor v-model="form_question.questions" :config="editorConfigQuestion" v-show="data.show_quest" class="mt-3"></ckeditor>
                    </div>
                    <!-- BLOCK IF TYPE IF MULTIPLE CHOICE -->
                    <div class="row" v-if="form_question.type == 'multiple_choice'">
                        <div class="col-md-12">
                            <label for="exampleInputEmail1">Fill options below.</label>
                            <div class="form-group row">
                                <div class="col-md-12 my-1">
                                    <div class="d-block bg-light p-3 border-radius-1">
                                        <div class="d-flex justify-content-between">
                                            <div class="font-weight-bold">Option A</div>
                                            <a href="javascript:void(0)" v-if="!data.show_option_1" v-on:click="showContent('option1')" class="text-dark">Show <i class="feather icon-chevron-down"></i></a>
                                            <a href="javascript:void(0)" v-if="data.show_option_1" v-on:click="hideContent('option1')" class="text-dark">Hide <i class="feather icon-chevron-up"></i></a>
                                        </div>
                                        <ckeditor :config="editorConfigOptionOne" v-show="data.show_option_1" v-model="form_question.options[0]" class="mt-3"></ckeditor>
                                    </div>
                                </div>
                                <div class="col-md-12 my-1">
                                    <div class="d-block bg-light p-3 border-radius-1">
                                        <div class="d-flex justify-content-between">
                                            <div class="font-weight-bold">Option B</div>
                                            <a href="javascript:void(0)" v-if="!data.show_option_2" v-on:click="showContent('option2')" class="text-dark">Show <i class="feather icon-chevron-down"></i></a>
                                            <a href="javascript:void(0)" v-if="data.show_option_2" v-on:click="hideContent('option2')" class="text-dark">Hide <i class="feather icon-chevron-up"></i></a>
                                        </div>
                                        <ckeditor :config="editorConfigOptionTwo" v-show="data.show_option_2" v-model="form_question.options[1]" class="mt-3"></ckeditor>
                                    </div>
                                </div>
                                <div class="col-md-12 my-1">
                                    <div class="d-block bg-light p-3 border-radius-1">
                                        <div class="d-flex justify-content-between">
                                            <div class="font-weight-bold">Option C</div>
                                            <a href="javascript:void(0)" v-if="!data.show_option_3" v-on:click="showContent('option3')" class="text-dark">Show <i class="feather icon-chevron-down"></i></a>
                                            <a href="javascript:void(0)" v-if="data.show_option_3" v-on:click="hideContent('option3')" class="text-dark">Hide <i class="feather icon-chevron-up"></i></a>
                                        </div>
                                        <ckeditor :config="editorConfigOptionThree" v-show="data.show_option_3" v-model="form_question.options[2]" class="mt-3"></ckeditor>
                                    </div>
                                </div>
                                <div class="col-md-12 my-1">
                                    <div class="d-block bg-light p-3 border-radius-1">
                                        <div class="d-flex justify-content-between">
                                            <div class="font-weight-bold">Option D</div>
                                            <a href="javascript:void(0)" v-if="!data.show_option_4" v-on:click="showContent('option4')" class="text-dark">Show <i class="feather icon-chevron-down"></i></a>
                                            <a href="javascript:void(0)" v-if="data.show_option_4" v-on:click="hideContent('option4')" class="text-dark">Hide <i class="feather icon-chevron-up"></i></a>
                                        </div>
                                        <ckeditor :config="editorConfigOptionFour" v-show="data.show_option_4" v-model="form_question.options[3]" class="mt-3"></ckeditor>
                                    </div>
                                </div>
                                <div class="col-md-12 my-1">
                                    <div class="d-block bg-light p-3 border-radius-1">
                                        <div class="d-flex justify-content-between">
                                            <div class="font-weight-bold">Option E</div>
                                            <a href="javascript:void(0)" v-if="!data.show_option_5" v-on:click="showContent('option5')" class="text-dark">Show <i class="feather icon-chevron-down"></i></a>
                                            <a href="javascript:void(0)" v-if="data.show_option_5" v-on:click="hideContent('option5')" class="text-dark">Hide <i class="feather icon-chevron-up"></i></a>
                                        </div>
                                        <ckeditor :config="editorConfigOptionFive" v-show="data.show_option_5" v-model="form_question.options[4]" class="mt-3"></ckeditor>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-2">
                                <label for="exampleFormControlSelect1">Correct Answer</label>
                                <select class="form-control" id="exampleFormControlSelect1" v-model="form_question.answer">
                                    <option value="">Choose Answer</option>
                                    <option value="a">A</option>
                                    <option value="b">B</option>
                                    <option value="c">C</option>
                                    <option value="d">D</option>
                                    <option value="e">E</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- BLOCK IF TYPE IS MATCH -->
                    <div class="form-group mt-1 bg-light p-3 border-radius-1" v-if="form_question.type == 'match'">
                        <div class="d-flex justify-content-between">
                            <div for="exampleInputEmail1">Correct Answer</div>
                            <a href="javascript:void(0)" v-if="!data.show_match" v-on:click="showContent('match')" class="text-dark">Show <i class="feather icon-chevron-down"></i></a>
                            <a href="javascript:void(0)" v-if="data.show_match" v-on:click="hideContent('match')" class="text-dark">Hide <i class="feather icon-chevron-up"></i></a>
                        </div>
                        <input type="text" v-model="form_question.answer" class="form-control mt-3"/>
                    </div>

                    <div class="form-group">
                        <label for="exampleInputEmail1">Discussion</label>
                        <ckeditor :config="editorConfigDiscussion" v-model="form_question.discussion"></ckeditor>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button type="submit" class="btn btn-primary"><i class="link-icon feather icon-save"></i> Save</button>
                        <button type="button" class="btn btn-danger ml-2" v-if="form_question.id" @click="deleteQuestion(form_question.id)"><i class="link-icon feather icon-trash"></i> Delete</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-sm-12 col-md-4 mt-3">
        <div class="card">
            <div class="card-header">
                <p class="font-weight-bold">Information</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 text-muted">Quiz Name</div>
                    <div class="col-md-12 tx-17 mt-1">{{detail.name}}</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12 text-muted">Duration</div>
                    <div class="col-md-12 tx-17 mt-1" v-if="detail.duration">{{detail.duration}} Minutes</div>
                    <div class="col-md-12 text-muted mt-1" v-if="!detail.duration">The duration has not been set</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12 text-muted">Expired at</div>
                    <div class="col-md-12 tx-17 mt-1" v-if="detail.can_expired">{{detail.end_date}}</div>
                    <div class="col-md-12 text-muted mt-1" v-if="!detail.can_expired">No expiration</div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12 text-muted">Description</div>
                    <div class="col-md-12 tx-17 mt-1" v-html="detail.description"></div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-12 text-muted">Status</div>
                    <div class="col-md-12 mt-1">
                        <div class="badge badge-success" v-if="detail.status == 'publish'">Publish</div>
                        <div class="badge badge-secondary" v-if="detail.status == 'draft'">Draft</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-header">
                <p class="font-weight-bold">Question number</p>
            </div>
            <div class="card-body">
                <div class="d-block">
                    <div class="button-quiz-group" v-if="data.list_question.length > 0">
                        <button v-for="(row, index) in data.list_question" :key="index" @click="detailQuest(row)" type="button" class="button-quiz" :class="{'primary': row.id == form_question.id && form_question.id != ''}">{{index + 1}}</button>
                    </div>
                    <div class="d-block rounded bg-light p-3 text-center" v-if="data.list_question.length == 0">
                        No questions at this time.
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="mt-2"></div>
                <button type="button" class="btn btn-primary btn-block" @click="resetFormQuest()"><i class="link-icon feather icon-plus"></i> Add Questions</button>
                <div class="btn-group btn-block">
                    <button type="button" class="btn btn-light btn-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="link-icon feather icon-list"></i> Other</button>
                    <div class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 35px, 0px);">
                        <a class="dropdown-item py-2" href="template/format-import-quiz.xlsx"><i class="link-icon feather icon-download"></i> Download Template Import Questions</a>
                        <button class="dropdown-item py-2" @click="openFile()"><i class="link-icon feather icon-upload"></i> Import Data Questions</button>
                        <input type="file" class="d-none" @change="importQuestion()" ref="file">
                        <div class="dropdown-divider"></div>
                        <a href="javascript:void(0)" @click="resetQuestion()" class="dropdown-item"><i class="link-icon feather icon-trash"></i> Reset All Questions </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'ManageQuestion',
    metaInfo: {
        title: 'Questions',
        titleTemplate: '%s - Elearning'
    },
    components : {},
    props: ['params', 'detail'],
    validations: {
        form_question: {
            point: {
                required
            },
            type: {
                required
            }
        }
    },
    data() {
        return {
            dataFetch: true,
            submitted: true,
            data: {
                courses_id: this.params.courses_id,
                courses_lessons_id: this.params.id,
                show_quest: true,
                show_option_1: true,
                show_option_2: true,
                show_option_3: true,
                show_option_4: true,
                show_option_5: true,
                show_match: true,
                list_question: [],
            },
            form_question: {
                id: '',
                courses_lessons_id: this.params.id,
                type: '',
                point: '1',
                questions: '',
                options: [],
                answer: '',
                discussion: '',
            },
            editorConfigQuestion: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ]
            },
            editorConfigOptionOne: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ],
            },
            editorConfigOptionTwo: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ],
            },
            editorConfigOptionThree: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ],
            },
            editorConfigOptionFour: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ],
            },
            editorConfigOptionFive: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ],
            },
            editorConfigAnswer: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ],
            },
            editorConfigDiscussion: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ],
            },
        }
    },
    created() {
        this.fetchQuestion(this.data.courses_lessons_id);
    },
    methods: {
        showContent: function (type) {
            if (type == 'quest') {
                this.data.show_quest = true;
            } else if (type == 'option1') {
                this.data.show_option_1 = true;
            } else if (type == 'option2') {
                this.data.show_option_2 = true;
            } else if (type == 'option3') {
                this.data.show_option_3 = true;
            } else if (type == 'option4') {
                this.data.show_option_4 = true;
            } else if (type == 'option5') {
                this.data.show_option_5 = true;
            } else if (type == 'match') {
                this.data.show_match = true;
            }
        },
        hideContent: function (type) {
            if (type == 'quest') {
                this.data.show_quest = false;
            } else if (type == 'option1') {
                this.data.show_option_1 = false;
            } else if (type == 'option2') {
                this.data.show_option_2 = false;
            } else if (type == 'option3') {
                this.data.show_option_3 = false;
            } else if (type == 'option4') {
                this.data.show_option_4 = false;
            } else if (type == 'option5') {
                this.data.show_option_5 = false;
            } else if (type == 'match') {
                this.data.show_match = false;
            }
        },
        fetchQuestion: function (id) {
            this.data.list_question = [];
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/list-questions';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_lessons_id: id
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.data.list_question = getResponse;
                }
            });
        },
        resetFormQuest: function () {
            this.form_question = {};
        },
        detailQuest: function (data) {
            this.form_question = data;
        },
        resetQuestion: function () {
            this.$swal({
                icon: 'info',
                title: 'Reset Question',
                text: 'Are you sure you want to reset question?',
                showCancelButton: true,
                confirmButtonText: 'Reset',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/reset-questions', {
                            courses_id: this.params.courses_id,
                            courses_lessons_id: this.params.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.resetFormQuest();
                                        this.fetchQuestion(this.data.courses_lessons_id);
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteQuestion: function (id) {
            this.$swal({
                icon: 'info',
                title: 'Delete Data',
                text: 'Are you sure you want to delete question?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/delete-questions', {
                            id: id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.resetFormQuest();
                                        this.fetchQuestion(this.data.courses_lessons_id);
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        saveQuestion: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                for (var index in this.form_question) {
                    if (index == 'options') {
                        this.form_question[index] = JSON.stringify(this.form_question[index]);
                    }
                    formData.append(index, this.form_question[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/save-questions', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            });
                            this.resetFormQuest();
                            this.fetchQuestion(this.data.courses_lessons_id);
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        importQuestion: function() {
            this.loader()
            let formData = new FormData();
            formData.append('courses_id', this.params.courses_id);
            formData.append('courses_lessons_id', this.params.id);
            formData.append('file', this.$refs.file.files[0]);

            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/import-questions', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                })
                .then(response => {
                    this.loader(false)
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        });
                        this.resetFormQuest();
                        this.fetchQuestion(this.data.courses_lessons_id);
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.loader(false)
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
        },
        openFile: function() {
            this.$refs.file.click();
        },
        loader: function(show=true) {
            if (show) {
                this.$swal({
                    html:'<img src="images/loader.svg" />',
                    showConfirmButton: false,
                    background: 'transparent'
                })
            } else {
                this.$swal.close()
            }
        }
    }
}
</script>
