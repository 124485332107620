<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <!-- List of courses -->
        <div class="row">
            <div class="col-lg-12 col-xl-12">
                <div class="d-block mb-3">
                    <div class="card">
                        <div class="card-body pb-1">
                            <div class="d-flex align-items-center border-bottom pb-2">
                                <router-link class="btn btn-light" :to="{name: 'courses_teacher'}">Back</router-link>
                                <div class="d-block ml-3">
                                    <h5 class="font-weight-bold text-capitalize">{{detail.name}}</h5>
                                    <p class="text-muted">{{detail.category}}</p>
                                </div>
                            </div>
                            <ul class="nav mt-2">
                                <li class="nav-item">
                                    <a class="nav-link pl-0 tx-16" :class="currentTabComponent == 'sections' ? 'active' : 'text-dark'" href="javascript:void(0)" @click="changeTab('sections')">Section <span class="badge" :class="currentTabComponent == 'sections' ? 'badge-primary' : 'badge-light'" v-if="detail.total_sections > 0">{{detail.total_sections}}</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link tx-16" :class="currentTabComponent == 'member' ? 'active' : 'text-dark'" href="javascript:void(0)" @click="changeTab('member')">Member <span class="badge" :class="currentTabComponent == 'member' ? 'badge-primary' : 'badge-light'" v-if="detail.total_members > 0">{{detail.total_members}}</span></a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link tx-16" :class="currentTabComponent == 'setting' ? 'active' : 'text-dark'" href="javascript:void(0)" @click="changeTab('setting')">Setting</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <keep-alive>
                    <component :params="params" :fetchDetailClass="fetchDetailClass" :is="currentTabComponent"></component>
                </keep-alive>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
// import tabs components
import sections from '@/components/tabs/teacher/manage_section/Sections.vue';
import member from '@/components/tabs/teacher/manage_section/Member.vue';
import setting from '@/components/tabs/teacher/manage_section/Setting.vue';

export default {
    name: 'CoursesDetail',
    metaInfo: {
        title: 'Learning Path',
        titleTemplate: '%s - Elearning'
    },
    components: {
        sections,
        member,
        setting
    },
    data() {
        return {
            currentTabComponent: 'sections',
            dataFetch: true,
            params: this.$route.params,
            detail: {}
        }
    },
    created() {
        this.fetchDetailClass();
    },
    methods: {
        fetchDetailClass: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/detail?id=' + this.params.id;

            this.$http.get(uri).then(res => {
                this.detail = {};
                if (res.data.status) {
                    this.detail = res.data.data;
                }
            });
        },
        changeTab: function (tabActive) {
            this.currentTabComponent = tabActive;
        },
    }
}
</script>
