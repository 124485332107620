<template>
    <div class="page-wrapper full-page" style="background-image: url(/images/background_login.jpg); background-size: cover">
        <div class="page-content d-flex align-items-center justify-content-center">
            <div class="row mx-0 auth-page">
                <div class="col-md-12 col-sm-8 mx-auto">
                    <div class="card">
                        <div class="row">
                            <div class="col-md-6 pr-md-0">
                                <div class="auth-left-wrapper">
                                </div>
                            </div>
                            <div class="col-md-6 pl-md-0">
                                <div class="auth-form-wrapper px-4 py-3">
                                    <a href="#" class="noble-ui-logo d-block mb-4"><img class="img-fluid wd-200" src="images/logo.png" /></a>
                                    <h5 class="text-muted font-weight-normal mb-4">Welcome back! Log in to your account.</h5>
                                    <form class="forms-sample" v-on:submit.prevent="onSubmit">
                                        <div class="form-group">
                                            <label for="username">Username</label>
                                            <input type="text" class="form-control form-control-lg" :class="{ 'is-invalid': submitted && $v.username.$error }" v-model="username" id="username" placeholder="Enter username">
                                            <div v-if="submitted && !$v.username.required" class="invalid-feedback">Username is required!</div>
                                        </div>
                                        <div class="form-group">
                                            <label for="password">Password</label>
                                            <input type="password" class="form-control form-control-lg" :class="{ 'is-invalid': submitted && $v.password.$error }" v-model="password" id="password" autocomplete="current-password" placeholder="Enter password">
                                            <div v-if="submitted && !$v.password.required" class="invalid-feedback">Password is required!</div>
                                        </div>
                                        <div class="mt-3 mb-3">
                                            <button type="submit" class="btn btn-primary btn-block rounded mr-2 mb-2 mb-md-0 text-white p-3 tx-16">LOGIN</button>
                                            <router-link :to="{name: 'home'}" class="btn btn-link btn-block rounded mr-2 mb-2 mb-md-0 font-weight-bold p-3 tx-16">BACK TO LANDING PAGE</router-link>
                                        </div>
                                        <div class="d-block mt-3 mb-0 border-top pt-3">
                                            <div class="tx-16 text-dark font-weight-bold">Universitas Al-Ghifari</div>
                                            <div class="tx-14 text-muted">Jl. Cisaranten Kulon No.140, Cisaranten Kulon, Kec. Arcamanik, Kota Bandung, Jawa Barat 40293</div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Signin',
        metaInfo: {
            title: 'Signin',
            titleTemplate: '%s - Elearning'
        },
        data() {
            return {
                username: "",
                password: "",
                submitted: false,
                waiting: false,
            }
        },
        validations: {
            username: {
                required
            },
            password: {
                required
            }
        },
        created() {
        },
        methods: {
            onSubmit: function () {
                this.waiting = true;
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'auth/signin', {
                            username: this.username,
                            password: this.password
                        })
                        .then(response => {
                            this.waiting = false;

                            if (response.data.status) {
                                const token = response.data.user.key;
                                const role = response.data.user.role;
                                localStorage.setItem('auth_token_lms', token);
                                location.reload();
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            },
        }
    }
</script>