<template>
<div>
    <div class="d-block">
        <div class="d-block my-2">
            <button type="button" class="btn btn-primary btn-block p-3" data-toggle="modal" data-target="#section-modal" @click="createSection"><i class="feather icon-plus mr-1"></i> Create New Section</button>
        </div>
        <div class="row">
            <div class="col-md-12" v-if="list_material.length > 0">
                <div id="accordion" class="accordion" role="tablist" v-for="section in list_material" :key="section.courses_sections_id">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne">
                            <div class="row">
                                <a class="collapsed pr-0 col" data-toggle="collapse" :href="('#material-' + section.courses_sections_id)" aria-expanded="false">
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex justify-content-center align-items-center rounded-circle bg-secondary wd-40 ht-40 text-white mr-2">
                                            <i class="my-0 mdi mdi-cards tx-20"></i>
                                        </div>
                                        <div class="d-block">
                                            <div class="mb-0 ml-2 tx-17 font-weight-bold">{{section.sections_name}}</div>
                                            <div class="mb-0 ml-2 tx-15 text-muted" v-if="section.list_lessons.length > 0">{{section.list_lessons.length}} Material(s)</div>
                                            <div class="mb-0 ml-2 tx-15 text-muted" v-if="section.list_lessons.length == 0">No material yet</div>
                                        </div>
                                    </div>
                                </a>
                                <div class="btn-group col-md-auto" role="group" aria-label="action">
                                    <button type="button" class="btn btn-light btn-icon" data-toggle="modal" data-target="#section-modal" @click="editSection(section)"><i class="feather icon-edit"></i></button>
                                    <button type="button" class="btn btn-light btn-icon" @click="deleteSection(section.courses_sections_id)"><i class="feather icon-trash"></i></button>
                                </div>
                            </div>
                        </div>
                        <div :id="('material-' + section.courses_sections_id)" class="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                            <div class="card-body" v-if="section.list_lessons.length > 0">
                                <div v-for="(row, index) in section.list_lessons" :key="row.id" :class="{'mt-4': index > 0}" class="d-block w-100 mb-3">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-flex align-items-center">
                                            <div class="mr-2">
                                                <div class="d-flex justify-content-center align-items-center rounded-circle bg-primary wd-40 ht-40 text-white">
                                                    <i class="my-0 mdi mdi-file tx-20" v-if="row.type == 'theory'"></i>
                                                    <i class="my-0 mdi mdi-clipboard-text tx-20" v-if="row.type == 'assignment'"></i>
                                                    <i class="my-0 mdi mdi-server tx-20" v-if="row.type == 'quiz'"></i>
                                                    <i class="my-0 mdi mdi-link tx-20" v-if="row.type == 'fgd'"></i>
                                                </div>
                                            </div>
                                            <div class="mb-0 ml-2 text-capitalize tx-16 text-dark">
                                                {{row.name}}<br/>
                                                <label class="badge badge-primary mr-3 tx-12 my-0 text-uppercase">{{row.type}}</label>
                                                <label class="badge badge-light mr-3 tx-12 my-0 text-uppercase" v-if="row.type != 'quiz'">{{row.format}}</label>
                                                <label class="badge badge-success badge-pill tx-12 my-0" v-if="row.status == 'publish'">Publish</label>
                                                <label class="badge badge-secondary badge-pill tx-12 px-2 my-0" v-if="row.status == 'draft'">Draft</label>
                                                <p v-if="row.can_expired">
                                                    <label class="badge badge-warning mb-0">Start : {{row.text_start_date}}</label>
                                                    <label class="badge badge-warning">End : {{row.text_end_date}}</label>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex mt-0 align-items-center">
                                            <div class="ml-3 my-0" role="group">
                                                <button id="btnGroupDrop1" type="button" class="btn btn-light btn-icon" data-toggle="dropdown" aria-expanded="false">
                                                    <i class="feather icon-chevron-down mr-0"></i>
                                                </button>
                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="btnGroupDrop1">
                                                    <router-link v-if="row.type == 'assignment'" class="dropdown-item dropdown-item-action" :to="{name: 'courses_assignment_manage', params: {courses_id: params.id, id: row.id}}"><button class="btn btn-primary btn-block">Manage Assignment</button></router-link>
                                                    <router-link v-if="row.type == 'quiz'" :to="{name : 'courses_manage_quiz', params: {courses_id: params.id, id: row.id}}" class="dropdown-item dropdown-item-action"><button class="btn btn-primary btn-block" type="button">Manage Quiz</button></router-link>
                                                    <div class="dropdown-divider" v-if="row.type == 'quiz' || row.type == 'assignment'"></div>
                                                    <!-- <a class="dropdown-item" href="#" v-if="row.type == 'quiz'"><i class="feather icon-eye mr-1" style="font-size: 16px"></i> View Question</a> -->
                                                    <router-link v-if="row.type != 'quiz'" :to="{name: 'view_material_teacher', params: {courses_id: params.id, id: row.id}}"><a class="dropdown-item" href="#"><i class="feather icon-eye mr-1" style="font-size: 16px"></i> View</a></router-link>
                                                    <router-link class="dropdown-item" :to="{name: 'form_lesson_edit', params: {courses_id: params.id, courses_sections_id: section.courses_sections_id, courses_lessons_id: row.id}}"><i class="feather icon-edit mr-1" style="font-size: 16px"></i> Edit</router-link>
                                                    <a class="dropdown-item" href="javascript:void(0)" @click="deleteLesson(row.id)"><i class="feather icon-trash mr-1" style="font-size: 16px"></i> Delete</a>
                                                    <router-link :to="{name: 'view_presence_lessons', params: {courses_id: params.id, id: row.id}}"><a class="dropdown-item" href="#"><i class="feather icon-user-check mr-1" style="font-size: 16px"></i> Check Presence</a></router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <router-link class="btn btn-outline-primary btn-block p-3 mt-2" :to="{name: 'form_lesson_create', params: {courses_id: params.id, courses_sections_id: section.courses_sections_id}}"><i class="feather icon-plus mr-1"></i> Create New Lesson</router-link>
                            </div>
                            <div class="card-body" v-if="section.list_lessons.length == 0">
                                <div class="d-flex align-items-center justify-content-center rounded bg-light p-3 mb-3 text-muted">Material has not been created on this topic</div>
                                <router-link class="btn btn-outline-primary btn-block p-3 mt-2" :to="{name: 'form_lesson_create', params: {courses_id: params.id, courses_sections_id: section.courses_sections_id}}"><i class="feather icon-plus mr-1"></i> Create New Lesson</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-3" v-if="list_material.length == 0">
                <div class="d-flex align-items-center justify-content-center rounded bg-light ht-100 text-muted">Oops. learning is not yet available, please create a learning topic</div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="section-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Create New Section</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="d-block">
                        <div class="form-group">
                            <input type="text" class="form-control w-100" v-model="form.name" placeholder="Enter title section" v-on:keyup.enter="saveSection()" />
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <button type="button" class="btn btn-white-naked" data-dismiss="modal" ref="closeModal">Close</button>
                        <button type="button" class="btn btn-primary" @click="saveSection()" data-dismiss="modal">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'ManageSection',
    metaInfo: {
        title: 'Manage Section',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: true,
            list_material: [],
            form: {
                id: '',
                courses_id: this.params.id,
                name: '',
                status: 'publish'
            }
        }
    },
    props: {
        params: Object
    },
    created() {
        this.list();
    },
    methods: {
        list: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-sections/list?courses_id=' + this.params.id;

            this.$http.get(uri).then(res => {
                // this.detail = {};
                this.list_material = [];
                if (res.data.status) {
                    // this.detail = res.data.data.courses;
                    this.list_material = res.data.data.sections;
                }
            });
        },
        saveSection() {
            let formData = new FormData();
            for (var index in this.form) {
                formData.append(index, this.form[index]);
            }
            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-sections/save', formData)
                .then(response => {
                    if (response.data.status) {
                        this.$refs.closeModal.click();
                        this.form.name = '';
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.list();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                    this.waiting = false;
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
        },
        createSection: function() {
            this.form.id    = "";
            this.form.name  = "";
        },
        editSection: function (data) {
            this.form.id = data.courses_sections_id;
            this.form.name = data.sections_name;
        },
        deleteSection: function (id) {
            this.$swal({
                icon: 'info',
                title: 'Delete Data',
                text: 'Are you sure you want to delete this data?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-sections/delete', {
                            id: id,
                            courses_id: this.params.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.list();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
        deleteLesson: function (id) {
            this.$swal({
                icon: 'info',
                title: 'Delete Data',
                text: 'Are you sure you want to delete this data?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/delete', {
                            id: id,
                            courses_id: this.params.id
                        })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.list();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
    }
}
</script>
