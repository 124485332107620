<template>
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-flex align-items-center justify-content-center rounded bg-light mb-4 p-3">
                    <div class="d-block text-center">
                        <img class="w-25 mb-3 mt-2" src="images/comming_soon.png">
                        <h4 class="mb-2">Coming Soon</h4>
                        <p class="tx-17 text-muted">Our website is under construction. Please patiently wait for this feature</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'QuizAnalysis',
    metaInfo: {
        title: 'Quiz Analysis',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: true,
            list_member: [],
            keywords: '',
            pagination: {
                page: '',
                limit: 10
            }
        }
    },
    props: ['params', 'fetchDetailClass'],
    created() {
        // this.list();
    },
    methods: {
        list: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/result';

            this.$http.get(uri, {
                params: {
                    courses_id: this.params.courses_id,
                    courses_lessons_id: this.params.id,
                    with_answer: '0',
                    limit: this.pagination.limit,
                    page: this.pagination.page,
                    keywords: this.keywords
                }
            }).then(res => {
                this.list_member = [];
                if (res.data.status) {
                    this.list_member = res.data.data;
                }
            });
        },
    }
}
</script>
