<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div>
                <h5 class="mb-3 mb-2">Learning Recap</h5>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-0">
                                <i class="mdi mdi-clipboard-search-outline"></i>
                                Search Filters</h4>
                            <p class="card-title-desc mb-0">Please use the filters below to find a learning recap.</p>
                            <div class="row">
                                <div class="col-md-12 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <label for="exampleFormControlSelect1">Choose Year Period</label>
                                        <select class="form-control" v-model="params.periode" v-on:change="searchData">
                                            <option value="">Choose Year Period</option>
                                            <option v-for="row in list_year" :value="row" :key="row.id">{{row.name}} {{row.active == '1' ? '(Active)' : ''}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="params.periode.id">
                        <div class="row mt-3" v-if="dataFetch">
                            <div class="col-md-12">
                                <div class="d-block p-3 rounded bg-light">
                                    <div class="text-center">loading data...</div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3" v-if="!dataFetch">
                            <div class="col-md-12 grid-margin stretch-card" v-if="detail.periode_name">
                                <div class="card rounded">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <h6 class="card-title mb-0">Recap Information</h6>
                                            </div>
                                            <div>
                                                <button :disabled="fetchDownload" type="button" class="btn btn-success" v-on:click="downloadExcel()">
                                                    {{fetchDownload ? 'Downloading....':'Download Excel'}}
                                                </button>
                                            </div>
                                        </div>

                                        <div class="mt-3">
                                            <label class="tx-11 fw-bolder mb-0 text-uppercase">Year Period:</label>
                                            <p class="text-muted">{{detail.periode_name}}</p>
                                        </div>
                                        <div class="mt-3">
                                            <label class="tx-11 fw-bolder mb-0 text-uppercase">Lecturer Name:</label>
                                            <p class="text-muted">{{detail.fullname}} - {{detail.identity_number }}</p>
                                        </div>
                                        <div class="mt-3">
                                            <label class="tx-11 fw-bolder mb-0 text-uppercase">Total Courses:</label>
                                            <p class="text-muted"><i class="feather icon-book"></i> {{detail.list_courses.length}}</p>
                                        </div>
                                    </div>

                                    <div class="card-body">
                                        <div class="table-responsive table-bordered">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" width="3%" rowspan="2">#</th>
                                                        <th rowspan="2">Courses Name</th>
                                                        <th colspan="4" class="text-center">Total Lessons</th>
                                                        <th colspan="4" class="text-center">Lessons has been implemented</th>
                                                        <th class="text-center" colspan="2">Percentage</th>
                                                        <th class="text-center" rowspan="2">Action</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-center">Theory</th>
                                                        <th class="text-center">Assignment</th>
                                                        <th class="text-center">Quiz</th>
                                                        <th class="text-center">FGD</th>
                                                        
                                                        <th class="text-center">Theory</th>
                                                        <th class="text-center">Assignment</th>
                                                        <th class="text-center">Quiz</th>
                                                        <th class="text-center">FGD</th>

                                                        <th class="text-center">lecturer attendance</th>
                                                        <th class="text-center">student attendance</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="detail.list_courses.length > 0">
                                                    <tr v-for="(item, index) in detail.list_courses" :key="index">
                                                        <td class="text-center" width="3%">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <div class="text-dark font-weight-bold tx-15">
                                                                    {{item.courses_name}}<br/>
                                                                    <small>Study Program: {{ item.program_study_name }}</small>
                                                                    <br v-if="item.co_moderator"/>
                                                                    <label v-if="item.co_moderator" class="badge badge-primary mr-2 tx-12 my-0 text-uppercase">Co moderator</label><br/>
                                                                    <small><a href="javascript:void(0)" v-on:click="viewPresence(item.statistic.courses_id)">View Detail Presence</a></small>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_theory}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_assignment}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_quiz}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_fgd}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_theory}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_assignment}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_quiz}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_fgd}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.percentage ? item.statistic.percentage+'%' : '-'}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.percentage_student ? item.statistic.percentage_student+'%' : '-'}}
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-primary btn-sm btn-block mt-2" v-on:click="viewPresence(item.statistic.courses_id)">
                                                                View Detail
                                                            </button>
                                                            <button type="button" class="btn btn-primary btn-sm btn-block mt-2" v-on:click="viewPresenceStudent(item.statistic.courses_id)">
                                                                View Presence Student
                                                            </button>
                                                            <button type="button" class="btn btn-primary btn-sm btn-block mt-2" v-on:click="viewRecapScoreStudent(item.statistic.courses_id)">
                                                                View Recap Score Student
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="detail.list_courses.length < 1">
                                                    <tr>
                                                        <td colspan="13">
                                                            <div class="d-block p-3 rounded bg-light">
                                                                <div class="text-center">Data not found</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>                                    
                                        </div>
                                    </div>
                                </div>                                
                            </div>

                            <div class="col-md-12" v-if="!detail.periode_name">
                                <div class="d-block p-5 rounded bg-light">
                                    <div class="text-center">Data Not Found</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3" v-if="!params.periode.id">
                        <div class="col-md-12">
                            <div class="d-block p-5 rounded bg-light">
                                <div class="text-center">Please choose a year period to view data.</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        name: 'LearningRecap',
        metaInfo: {
            title: 'Learning Recap',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination
        },
        data() {
            return {
                fetchDownload: false,
                dataFetch: true,
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                detail          : {},
                list_year       : [],
                params: {
                    keywords    : '',
                    periode     : '',
                    limit       : 20,
                }
            }
        },
        created() {
            this.listYear();
        },
        methods: {
            listYear: function () {
                let uri = process.env.VUE_APP_APIHOST + 'general/list/periode';

                this.$http.get(uri).then(res => {
                    if (res.data.status) {
                        this.list_year = res.data.data;

                        this.list_year.forEach(element => {
                            if(element.active == '1') {
                                this.params.periode = element
                            }
                        });

                        if(this.params.periode.id) {
                            this.searchData();
                        }

                    }
                });
            },
            searchData: function () {
                this.dataFetch      = true;
                this.list_recap    = [];
                let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/learning-recap/detail';

                this.$http.get(uri, {
                    params: {
                        id : this.params.periode.id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;
                        let getResponse     = res.data.data;
                        this.detail         = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                history.back();
                            }
                        });
                    }
                });
            },
            downloadExcel: function() {
                this.fetchDownload = true;
                let formData = new FormData();

                formData.append('periode_id', this.params.periode.id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses/download-learning-recap-detail',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `learning-recap-detail.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.fetchDownload = false;                    
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.fetchDownload = false;                    
                    return;
                });
            },
            viewPresence: function(courses_id="") {
                this.$router.push(
                {
                    name: 'view_reporting_presence_teacher',
                    params : {
                        courses_id : courses_id
                    }
                });
            },
            viewPresenceStudent: function(courses_id="") {
                this.$router.push(
                {
                    name: 'view_reporting_presence_student',
                    params : {
                        courses_id : courses_id
                    }
                });
            },
            viewRecapScoreStudent: function(courses_id="") {
                this.$router.push(
                {
                    name: 'view_reporting_recap_score_student',
                    params : {
                        courses_id : courses_id
                    }
                });
            }
        }
    }
</script>