<template>
<div class="page-wrapper">
    <div class="page-content">
        <!-- Top Header Dashboard -->
        <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
                <h4 class="mb-3 mb-md-0">Welcome to Dashboard</h4>
            </div>
            <div class="d-none align-items-center flex-wrap text-nowrap">
                <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-md-none d-xl-flex" id="dashboardDate">
                    <span class="input-group-addon bg-transparent"><i class="feather icon-calendar text-primary tx-15"></i></span>
                    <input type="text" class="form-control">
                </div>
                <button type="button" class="btn btn-outline-info btn-icon-text mr-2 d-none d-md-block">
                    <i class="btn-icon-prepend feather icon-download tx-15"></i>
                    Import
                </button>
                <button type="button" class="btn btn-outline-primary btn-icon-text mr-2 mb-2 mb-md-0">
                    <i class="btn-icon-prepend feather icon-printer tx-15"></i>
                    Print
                </button>
                <button type="button" class="btn btn-primary btn-icon-text mb-2 mb-md-0">
                    <i class="btn-icon-prepend feather icon-download-cloud tx-15"></i>
                    Download Report
                </button>
            </div>
        </div>

        <!-- Card stastic QUIZ, LESSONS, ASSIGNMENT, EXAM -->
        <div class="row">
            <div class="col-12 col-xl-12 stretch-card">
                <div class="row flex-grow">
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-primary wd-60 ht-60 text-white">
                                        <i class="feather icon-airplay my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h2 class="mt-2 font-weight-bold">{{statistic.total_courses}}</h2>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of courses</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-success wd-60 ht-60 text-white">
                                        <i class="feather icon-file my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h2 class="mt-2 font-weight-bold">{{statistic.total_theory}}</h2>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of materials</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-warning wd-60 ht-60 text-white">
                                        <i class="feather icon-clipboard my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h1 class="mt-2 font-weight-bold">{{statistic.total_assignment}}</h1>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of assignments</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-info wd-60 ht-60 text-white">
                                        <i class="feather icon-server my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h1 class="mt-2 font-weight-bold">{{statistic.total_quiz}}</h1>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of quiz</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Card list annoucement -->
        <div class="row">
            <div class="col-12 col-xl-12">
                <div class="d-flex mb-3 align-items-center justify-content-between">
                    <div class="d-flex flex-shrink-1 align-items-center">
                        <i class="mdi mdi-calendar h4 mr-3"></i>
                        <h5>Today's Class Schedule</h5>
                    </div>
                    <div class="d-flex">
                        <button class="btn btn-white rounded pb-1 mr-1" type="button" @click="getScheduleToday"><i class="mdi mdi-autorenew mr-1"></i>Refresh</button>
                        <router-link class="btn btn-white rounded pb-1" :to="{name: 'all_schedule_student'}"><i class="mdi mdi-calendar-text mr-1"></i>View More</router-link>
                    </div>
                </div>
                <div v-if="fetch_schedule_today">
                    <div class="rounded bg-light p-3 mb-3 text-center">
                        Loading...
                    </div>
                </div>

                <div class="d-block mb-3" v-if="!fetch_schedule_today">
                    <div class="card mb-2 hide-mobile" v-if="schedule_today.length > 0">
                        <div class="card-body">
                            <div class="row align-items-center justify-content-between">
                                <div class="h6 mb-0 col-md-3">Class</div>
                                <div class="h6 mb-0 col-md-2">Teacher</div>
                                <div class="h6 mb-0 col-md-2">Study Program</div>
                                <div class="h6 mb-0 col-md-2">Lessons Name</div>
                                <div class="h6 mb-0 col-md-1">Schedule</div>
                                <div class="h6 mb-0 col-md-2"></div>
                            </div>
                        </div>
                    </div>
                    <div class="card mb-2" v-if="schedule_today.length > 0" v-for="(item, index) in schedule_today" :key="index">
                        <div class="card-body">
                            <div class="row align-items-center justify-content-between hide-mobile">
                                <div class="col-md-3">
                                    <div class="h6">{{item.name}}</div>
                                    <div>{{ item.category }}</div>
                                </div>
                                <div class="col-md-2">{{ item.instructure }}</div>
                                <div class="col-md-2">
                                    <div class="text-muted">{{item.program}}</div>
                                    <div>{{item.program_study}}</div>
                                </div>
                                <div class="col-md-2">{{item.lessons_name}}</div>
                                <div class="col-md-1">{{ item.start_time }} s.d {{ item.end_time }}</div>
                                <div class="col-md-2 text-right">
                                    <button v-if="item.status_lessons == 'lock'" type="button" class="btn btn-light">Locked</button>
                                    <button v-if="item.status_lessons == 'expired'" type="button" class="btn btn-light">Expired</button>
                                    <button v-if="item.status_lessons == 'opened'" type="button" class="btn btn-primary rounded" v-on:click="goToLessons(item.id, item.lessons_id)">Go To Lessons</button>
                                </div>
                            </div>
                            <div class="row align-items-center justify-content-between show-mobile">
                                <div class="col-md-3 mb-2">
                                    <div class="text-muted mb-1">Courses Information</div>
                                    <div class="h6">{{item.name}}</div>
                                    <div>{{ item.category }}</div>
                                </div>
                                <div class="col-md-1">
                                    <div class="text-muted">Program</div>
                                    <div>{{item.program}}</div>
                                </div>
                                <div class="col-md-2">
                                    <div class="text-muted">Program Study</div>
                                    <div>{{item.program_study}}</div>
                                </div>
                                <div class="col-md-2 mb-2">
                                    <div class="text-muted">Teacher</div>
                                    <div>{{item.instructure}}</div>
                                </div>
                                <div class="col-md-2">
                                    <div class="text-muted">Lessons Name</div>
                                    <div>{{item.lessons_name}}</div>
                                </div>
                                <div class="col-md-2 mb-3">
                                    <div class="text-muted">Schedule</div>
                                    <div>{{ item.start_time }} s.d {{ item.end_time }}</div>
                                </div>
                                <div class="col-md-2">
                                    <button v-if="item.status_lessons == 'lock'" type="button" class="btn btn-danger btn-block">Locked</button>
                                    <button v-if="item.status_lessons == 'expired'" type="button" class="btn btn-light btn-block">Expired</button>
                                    <button v-if="item.status_lessons == 'opened'" type="button" class="btn btn-primary rounded btn-block" v-on:click="goToLessons(item.id, item.lessons_id)">Go To Lessons</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="schedule_today.length < 1">
                        <div  class="rounded bg-light p-3 mb-3 text-center">
                            Schedule not available at this time.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-xl-12">
                <div class="d-flex mb-3 align-items-center"><i class="mdi mdi-newspaper h4 mr-3"></i>
                    <h5>Announcement</h5>
                </div>
                <div class="d-block stretch-card" v-if="announcement.length > 0">
                    <div class="row flex-grow">
                        <div class="col-lg-4 col-xl-4 grid-margin stretch-card" v-for="row in announcement" :key="row.id">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title mb-3 text-capitalize">{{row.name}}</h6>
                                    <div class="card-text text-muted text-ellipsis">
                                        {{row.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rounded bg-light p-3 mb-3 text-center" v-if="announcement.length < 1">
                    Announcement not available at this time.
                </div>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
export default {
    name: 'Dashboard',
    metaInfo: {
        title: 'Dashboard',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: true,
            fetchDashboard: true,
            fetchStatistic: true,
            statistic: [],
            announcement: [],
            fetch_schedule_today: true,            
            schedule_today: [],
        }
    },
    created() {
        this.getStatistic();
        this.getAnnouncement();
        this.getScheduleToday();
    },
    methods: {
        getStatistic: function () {
            this.statistic = [];
            let uri = process.env.VUE_APP_APIHOST + 'student/dashboard/statistic';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.statistic = getResponse;
                }
            });
        },
        getAnnouncement: function () {
            this.announcement = [];
            let uri = process.env.VUE_APP_APIHOST + 'student/dashboard/announcement';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    limit: '3'
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.announcement = getResponse;
                }
            });
        },
        getScheduleToday: function () {
            this.schedule_today = [];
            let uri = process.env.VUE_APP_APIHOST + 'student/courses/schedule-today';

            this.fetch_schedule_today = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.schedule_today = getResponse;
                }

                this.fetch_schedule_today = false;
            });
        },
        goToLessons: function(courses_id="", courses_lessons_id="") {
            let uri = process.env.VUE_APP_APIHOST + 'student/courses/presence-today';

            this.$http.post(uri, {
                courses_id  : courses_id,
                courses_lessons_id : courses_lessons_id,
            }).then(res => {
                if(res.data.status) {
                    if(res.data.data == 'quiz') {
                        this.$router.push({
                            name: 'quiz_overview',
                            params : {
                                courses_id : courses_id,
                                id : courses_lessons_id
                            }
                        });
                    }
                    else if(res.data.data == 'assignment') {
                        this.$router.push({
                            name: 'assignment',
                            params : {
                                courses_id : courses_id,
                                id : courses_lessons_id
                            }
                        });
                    }
                    else {
                        this.$router.push({
                            name: 'view_material',
                            params : {
                                courses_id : courses_id,
                                id : courses_lessons_id
                            }
                        });
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: res.data.message,
                    });
                }
            });
        }
    }
}
</script>
