<template>
<div class="page-wrapper">
    <div class="page-content">
        <div v-if="dataFetch" class="row">
            <div class="col-lg-12 col-xl-12">
                <p>Please wait...</p>
            </div>
        </div>
        
        <div v-if="!dataFetch" class="row">
            <div class="col-lg-12 col-xl-12">
                <div class="d-block mb-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <h6 id="default">{{data.courses_name}}</h6>
                                <div class="d-flex align-items-center justify-content-end w-25">
                                    <router-link class="btn btn-light" :to="{name: 'courses'}">Back</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div id="accordion" class="accordion" role="tablist" v-if="data.sections.length > 0">
                            <div class="card" v-for="section in data.sections" :key="section.id">
                                <div class="card-header" role="tab" id="headingOne">
                                    <a class="collapsed pr-0" data-toggle="collapse" :href="('#material-' + section.courses_sections_id)">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <div class="d-flex align-items-center">
                                                <div class="d-flex justify-content-center align-items-center rounded-circle bg-secondary wd-40 ht-40 text-white mr-2">
                                                    <i class="my-0 mdi mdi-cards tx-20"></i>
                                                </div>
                                                <div class="mb-0 ml-2 text-capitalize tx-17 font-weight-bold">{{section.sections_name}}</div>
                                            </div>
                                            <div class="mb-0 text-capitalize tx-17 font-weight-bold">{{section.total_done}}/{{section.total_lessons}}</div>
                                        </div>
                                    </a>
                                </div>
                                <div :id="('material-' + section.courses_sections_id)" class="collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                                    <div class="card-body">
                                        <div class="d-block w-100 mb-3" v-for="(row, index) in section.list_lessons" :key="row.id" :class="{'mt-4': index > 0}">
                                            <a href="javascript:void(0)" @click="detail(row)">
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <div class="d-flex align-items-center">
                                                        <div class="mr-2">
                                                            <div class="d-flex justify-content-center align-items-center rounded-circle bg-primary wd-40 ht-40 text-white">
                                                                <i class="my-0 mdi mdi-file tx-20" v-if="row.type == 'theory'"></i>
                                                                <i class="my-0 mdi mdi-clipboard-text tx-20" v-if="row.type == 'assignment'"></i>
                                                                <i class="my-0 mdi mdi-server tx-20" v-if="row.type == 'quiz'"></i>
                                                                <i class="my-0 mdi mdi-link tx-20" v-if="row.type == 'fgd'"></i>
                                                            </div>
                                                        </div>
                                                        <div class="mb-0 ml-2 text-capitalize tx-16 text-dark">
                                                            {{row.lessons_name}}
                                                            <p v-if="row.can_expired">
                                                                <small class="badge badge-warning">study time on : {{row.text_start_date}} - {{row.text_end_date}}</small>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex mt-0 align-items-center">
                                                        <label class="badge badge-primary mr-3 tx-12 my-0 text-uppercase">{{row.type}}</label>
                                                        <label class="badge badge-light mr-3 tx-12 my-0 text-uppercase" v-if="row.type != 'quiz'">{{row.format}}</label>
                                                        <i class="my-0 mdi mdi-chevron-right tx-20 ml-3 text-dark"></i>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
export default {
    name: 'CoursesDetail',
    metaInfo: {
        title: 'Learning Path',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: true,
            courses_id: this.$route.params.id,
            data: {
                sections: []
            },
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        fetchData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'student/courses/overview';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_id: this.courses_id
                }
            }).then(res => {
                if (res.data.status) {
                    this.dataFetch  = false;
                    this.data       = res.data.data;
                }
            });
        },
        detail: function (data) {
            if (data.type == 'theory' || data.type == 'fgd') {
                if(data.status == 'closed') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Information!',
                        text: "Lessons cannot be accessed now",
                    });
                }

                if(data.status == 'expired') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Information!',
                        text: "Lessons has expired",
                    });
                }

                if(data.status == 'open') {
                    this.$router.push({
                        name: 'view_material',
                        params: {
                            courses_id: this.courses_id,
                            id: data.lessons_id
                        }
                    });
                }
            } else if (data.type == 'assignment') {
                this.$router.push({
                    name: 'assignment',
                    params: {
                        courses_id: this.courses_id,
                        id: data.lessons_id
                    }
                });
            } else if (data.type == 'quiz') {
                this.$router.push({
                    name: 'quiz_overview',
                    params: {
                        courses_id: this.courses_id,
                        id: data.lessons_id
                    }
                });
            }
        }
    }
}
</script>
