<template>
<div>
    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <input type="text" class="form-control" placeholder="Search data by keyword here ..."  v-model="keywords" v-on:keyup.enter="getData"/>
                        </div>
                        <div class="col-md-2 d-none">
                            <select>
                                <option class="form-control" value="">All</option>
                                <option class="form-control" value="">Rated</option>
                                <option class="form-control" value="">Unrated</option>
                                <option class="form-control" value="">Not Finish Yet</option>
                            </select>
                        </div>
                    </div>
                    <hr/>
                    <div v-if="dataFetch">
                        <p>Loading...</p>
                    </div>
                    <div v-if="!dataFetch">
                        <div v-if="dataList.length < 1" class="rounded bg-light p-3 text-center text-muted mt-3">Data not available.</div>
                        <div v-if="dataList.length > 0" class="table-responsive mt-2">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Student</th>
                                        <th>Upload Date</th>
                                        <th>Score</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row, index) in dataList" :key="row.id">
                                        <td>
                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                        </td>
                                        <td>
                                            {{row.identity_number}}<br/>
                                            {{row.name}}
                                        </td>
                                        <td width="10%" v-if="row.uploaded">{{row.text_uploaded}}</td>
                                        <td v-if="row.uploaded">
                                            <input style="width:100px"
                                                type="number" class="form-control"
                                                :disabled="!scored_enabled[index]"
                                                v-model="table_scored[index] = row.scored" v-on:keyup="setScoredTable(index)" />
                                        </td>

                                        <td width="20%" v-if="row.uploaded">
                                            <button type="button" class="btn btn-primary" @click="editScore(index)"><span v-if="!scored_enabled[index]">Set Score</span><span v-if="scored_enabled[index]">Save Score</span></button>
                                            <button type="button" class="btn btn-primary ml-2" data-toggle="modal" data-target="#result-modal" @click="openDetail(row)">See Assignment</button>
                                        </td>

                                        <td colspan="2" v-if="!row.uploaded">
                                            <div class="bg-light rounded p-3 text-center text-muted">Not working yet.</div>
                                        </td>

                                        <td colspan="2" v-if="!row.uploaded">
                                            <button type="button" class="btn btn-primary btn-block" data-toggle="modal" data-target="#set-manual-score" @click="setScoreManual(row)">Set Score Manual</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <nav v-if="dataList.length > 0" aria-label="navigation" class="mt-3">
                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="result-modal" tabindex="-1" role="dialog" aria-labelledby="result-modal" aria-hidden="true">
            <div class="modal-dialog modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Result Assignment - {{detail.name}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-md-4">
                                <div class="text-muted">Score</div>
                                <div class="input-group col-xs-12 mt-2">
                                    <input type="number" :max="100" :min="0" class="form-control file-upload-info" v-model="detail.scored" placeholder="Insert score here..." v-on:keyup="setScored">
                                    <span class="input-group-append">
                                        <button class="file-upload-browse btn btn-primary" type="button" @click="postScore(detail.id, detail.scored)">Save Score</button>
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-4 pl-2">
                                <div class="text-muted">Upload Date</div>
                                <div class="text-dark tx-17">{{detail.text_uploaded}}</div>
                                <a href="javascript:void(0)" class="text-danger" v-on:click="resetAssignmentUsers(detail.id, detail.users_id)"><u>Reset Assignment Result</u></a>
                            </div>
                        </div>
                        <div v-if="!detail.manual_scored">
                            <div class="d-block mt-4">
                                <div class="text-muted">Description Assignment Student</div>
                                <div class="rounded bg-light p-3 text-center text-muted mt-3" v-if="!detail.description">No description from student.</div>
                                <div class="rounded bg-light p-3 mt-3" v-if="detail.description">{{detail.description}}</div>
                            </div>
                            
                            <div class="d-block mt-4" v-if="!detail.attachment">
                                <div class="text-muted">Attachment Assignment Student</div>
                                <div class="rounded bg-light p-3 text-center text-muted mt-3">No Attachment from student.</div>
                            </div>

                            <div class="d-block mt-4" v-if="detail.attachment">
                                <div class="text-muted">Attachment Assignment Student</div>
                                <div class="rounded bg-light p-3 text-center text-muted mt-3">
                                    <a :href="detail.attachment" target="blank">View Attachment</a>
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.manual_scored">
                            <div class="d-block mt-4">
                                <div class="text-muted">Information</div>
                                    <div class="rounded bg-light p-3 text-center text-muted mt-3">Student scores are input manually</div>
                                </div>
                        </div>
                        <div class="d-flex justify-content-end mt-3">
                            <button type="button" id="btnCloseModal" class="btn btn-light" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="set-manual-score" tabindex="-1" role="dialog" aria-labelledby="set-manual-score" aria-hidden="true">
            <div class="modal-dialog modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Set Manual Score - {{detail.name}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <div class="text-muted">Score</div>
                                <div class="input-group col-xs-12 mt-2">
                                    <input type="number" :max="100" :min="0" class="form-control file-upload-info" v-model="detail.scored" placeholder="Insert score here..." v-on:keyup="setScored">
                                    <span class="input-group-append">
                                        <button class="file-upload-browse btn btn-primary" type="button" @click="postScoreManual(detail.users_id, detail.scored)">Save Score</button>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mt-3">
                            <button type="button" id="btnCloseModalManual" class="btn btn-light" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vPagination from 'vue-plain-pagination'

export default {
    name: "ResultAssignment",
    metaInfo: {
        title: 'Assignment',
        titleTemplate: '%s - Elearning'
    },
    components: {
        vPagination
    },
    props: ['params'],
    data() {
        return {
            emptyState  : false,
            dataFetch   : false,
            dataList    : [],
            keywords    : "",
            pagination: {
                currentPage : 1,
                totalPages  : 0,
                limit       : 50,
                bootstrapPaginationClasses: {
                    ul: 'pagination justify-content-end',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                paginationAnchorTexts: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            },
            table_scored:[],
            scored_enabled: [],
            list: [],
            detail: {
                scored : 0
            },
            detail_lesson: {}
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function() {
            let uri         = process.env.VUE_APP_APIHOST+'teacher/courses-lessons/assignment/list';

            this.dataFetch  = true;                
            this.$http.get(uri,{
                params : {
                    courses_id          : this.params.courses_id,
                    courses_lessons_id  : this.params.id,
                    keywords : this.keywords,
                    page     : this.pagination.currentPage,
                    limit    : this.pagination.limit
                }
            }).then(res => {
                this.emptyState             = false;
                this.dataFetch              = false;                
                this.dataList               = res.data.data;
                this.pagination.totalPages  = res.data.total_page;

                if(this.dataList.length < 1) {
                    this.emptyState = true;
                }
            });
        },
        fetchDetailLesson: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/assignment/detail';
            this.$http.get(uri, {
                params: {
                    courses_id: this.params.courses_id,
                    courses_lessons_id: this.params.id,
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.detail_lesson = getResponse;
                }
            });
        },
        editScore: function (index) {
            this.scored_enabled.splice(index, 0)
            this.scored_enabled[index] = !this.scored_enabled[index];

            if (!this.scored_enabled[index]) {
                this.saveScore(index);
            }
        },
        saveScore: function (index) {
            this.dataList[index].scored = this.table_scored[index];
            this.postScore(this.dataList[index].id, this.dataList[index].scored);
        },
        postScore: function (id, scored) {
            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/assignment/save-scored', {
                    id: id,
                    scored: scored
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                document.getElementById("btnCloseModal").click();                                
                                this.getData();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
        },
        postScoreManual: function (users_id, scored) {
            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/assignment/save-scored-manual', {
                    users_id            : users_id,
                    courses_lessons_id  : this.params.id,
                    scored              : scored
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                document.getElementById("btnCloseModalManual").click();                                
                                this.getData();
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
        },
        openDetail: function (data) {
            this.detail = data;
            this.fetchDetailLesson();
        },
        setScoreManual: function (data) {
            this.detail = data;
        },
        setScored: function() {
            if(this.detail.scored > 100) {
                this.detail.scored = 100;
            }

            if(this.detail.scored < 0) {
                this.detail.scored = 0;
            }
        },
        resetAssignmentUsers: function(id, users_id) {
            this.$swal({
                icon: 'info',
                title: 'Reset Data',
                text: 'Are you sure you want to reset this data?',
                showCancelButton: true,
                confirmButtonText: 'Yes, Reset',
                cancelButtonText: 'Cancel',
            }).then((result) => {
            if (result.value) {
                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/assignment/reset', {
                    id: id,
                    users_id: users_id
                })
                .then(response => {
                    if (response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            text: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                document.getElementById("btnCloseModal").click();                                
                                this.getData();
                            }
                        });
                    } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                    }
                })
            }
            });
        },
        setScoredTable: function(index) {
            if(this.table_scored[index] > 100) {
                this.dataList[index].scored = 100;
            }

            if(this.table_scored[index] < 0) {
                this.dataList[index].scored = 0;
            }
        }
    }
}
</script>
