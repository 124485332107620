<template>
    <div class="page-wrapper">
        <div class="page-content">            
            <div class="row">
                <div v-if="dataFetch" class="col-lg-12 col-xl-12">
                    <p>Please wait...</p>
                </div>
                <div v-if="!dataFetch" class="col-lg-12 col-xl-12">
                    <div class="d-block mb-3">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-block">
                                        <p class="text-muted tx-15">Title</p>
                                        <h6 id="default">{{detail.name}}</h6>
                                    </div>
                                    <div class="d-block">
                                        <p class="text-muted tx-15">Schedule</p>
                                        <h6 id="default" v-if="detail.can_expired">{{detail.text_start_date}} s.d {{detail.text_end_date}}</h6>
                                        <h6 id="default" v-if="!detail.can_expired">-</h6>
                                    </div>
                                    <div class="d-block">
                                        <p class="text-muted tx-15">Total Questions</p>
                                        <h6 id="default">{{detail.list_questions.length}}</h6>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-end w-25">
                                        <router-link class="btn btn-light" :to="{name: 'quiz_overview', params : {courses_id : this.courses_id, id : this.courses_lessons_id}}">Back</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 mt-3">
                            <div class="card">
                                <div class="card-header pb-0">
                                    <div class="card-title mt-2 pb-0">question number #{{number_questions}}
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="d-block mb-3 bg-light p-3 rounded-sm" v-html="form.questions">
                                    </div>
                                    <h6 class="mt-4">Fill Answer</h6>
                                    <div v-if="form.type == 'multiple_choice'" class="d-block pt-2">
                                        <div class="form-group">
                                            <div class="d-flex border rounded align-items-center p-2">
                                                <div class="form-check mb-0 w-100">
                                                    <label class="form-check-label align-items-center" style="line-height: 1.7 !important;">
                                                        <input
                                                            type="radio" class="form-check-input"
                                                            name="options_radio" id="options_a" value="a" :checked="current_save_answer[current_questions]  == 'a'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('a')">
                                                    <i class="input-frame"></i>
                                                    <div v-html="form.options_a"></div>
                                                </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="d-flex border rounded align-items-center p-2">
                                                <div class="form-check mb-0 w-100">
                                                    <label class="form-check-label align-items-center" style="line-height: 1.7 !important;">
                                                        <input
                                                            type="radio" class="form-check-input"
                                                            name="options_radio" id="options_b" value="b" :checked="current_save_answer[current_questions]  == 'b'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('b')">
                                                    <i class="input-frame"></i>
                                                    <div v-html="form.options_b"></div>
                                                </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="d-flex border rounded align-items-center p-2">
                                                <div class="form-check mb-0 w-100">
                                                    <label class="form-check-label align-items-center" style="line-height: 1.7 !important;">
                                                        <input
                                                            type="radio" class="form-check-input"
                                                            name="options_radio" id="options_c" value="c" :checked="current_save_answer[current_questions]  == 'c'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('c')">
                                                    <i class="input-frame"></i>
                                                    <div v-html="form.options_c"></div>
                                                </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="d-flex border rounded align-items-center p-2">
                                                <div class="form-check mb-0 w-100">
                                                    <label class="form-check-label align-items-center" style="line-height: 1.7 !important;">
                                                        <input
                                                            type="radio" class="form-check-input"
                                                            name="options_radio" id="options_d" value="d" :checked="current_save_answer[current_questions]  == 'd'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('d')">
                                                    <i class="input-frame"></i>
                                                    <div v-html="form.options_d"></div>
                                                </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="d-flex border rounded align-items-center p-2">
                                                <div class="form-check mb-0 w-100">
                                                    <label class="form-check-label align-items-center" style="line-height: 1.7 !important;">
                                                        <input
                                                            type="radio" class="form-check-input"
                                                            name="options_radio" id="options_e" value="e" :checked="current_save_answer[current_questions]  == 'e'" v-model="current_save_answer[current_questions] " v-on:click="saveAnswer('e')">
                                                    <i class="input-frame"></i>
                                                    <div v-html="form.options_e"></div>
                                                </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-block pt-2" v-if="form.type == 'match' || form.type == 'essay'">
                                        <textarea v-model="form.your_answer" class="form-control" name="tinymce" id="simpleMdeExample" rows="10"></textarea>
                                    </div>
                                    
                                    <div class="d-flex justify-content-end mt-3">
                                        <button type="button" class="btn btn-light" v-on:click="prev(number_questions)">Previous</button>
                                        <button type="button" class="btn btn-primary ml-2" v-on:click="saveData(number_questions)">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-3">
                            <div class="card mb-3">
                                <div class="card-header pb-0">
                                    <div class="card-title mt-2 pb-0">Remaining Time
                                    </div>
                                </div>
                                <div class="card-body">
                                <vue-countdown-timer
                                    @start_callback="startCallBack"
                                    @end_callback="endCallBack"
                                    :start-time="start_time"
                                    :end-time="end_time"
                                    :interval="1000">
                                    <div slot="countdown" slot-scope="scope" class="d-flex justify-content-between bg-light rounded p-3">
                                        <div class="d-block text-center px-3 w-100">
                                            <div class="tx-25 text-dark font-weight-bold">{{scope.props.hours}}</div>
                                            <div class="tx-12 text-muted">Hours</div>
                                        </div>
                                        <div class="d-block border-left border-right text-center px-3 w-100">
                                            <div class="tx-25 text-dark font-weight-bold">{{scope.props.minutes}}</div>
                                            <div class="tx-12 text-muted">Minutes</div>
                                        </div>
                                        <div class="d-block text-center px-3 w-100">
                                            <div class="tx-25 text-dark font-weight-bold">{{scope.props.seconds}}</div>
                                            <div class="tx-12 text-muted">Seconds</div>
                                        </div>
                                    </div>
                                    </vue-countdown-timer>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header pb-0">
                                    <div class="card-title mt-2 pb-0">Question List</div>
                                </div>
                                <div class="card-body">
                                    <div class="button-quiz-group">
                                        <button v-for="(item, index) in detail.list_questions" :key="index" type="button" class="button-quiz"
                                            v-on:click="setQuestions(item.id, item.number)"
                                            :class="current_save_questions[item.id] == true ? 'done': item.status == 'true' ? 'done':item.id == current_questions ? 'active':'' "
                                            >
                                            {{item.number}}
                                        </button>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button v-on:click="finishQuiz" type="button" class="btn btn-outline-primary btn-block">Finish</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    export default {
        name: 'Quiz',
        metaInfo: {
            title: 'Quiz',
            titleTemplate: '%s - Elearning'
        },
        components: {},
        data() {
            return {
                courses_id          : this.$route.params.courses_id,
                courses_lessons_id  : this.$route.params.id,
                dataFetch           : true,
                detail              : [],
                start_time          : '',
                end_time            : '',
                current_questions : '',
                number_questions : 1,
                form : {
                    courses_quiz_answer_id : '',
                    courses_quiz_answer_detail_id : '',
                    questions     : '',
                    type          : '',
                    options_a     : '',
                    options_b     : '',
                    options_c     : '',
                    options_d     : '',
                    options_e     : '',
                    your_answer   : '',
                    courses_lessons_questions_id   : ''
                },
                current_save_questions : [],
                current_save_answer    : [],
                change_answer         : [],
            }
        },
        created() {
            this.startQuiz();
        },
        methods: {
            startCallBack: function () {
                console.log('waktu dimulai');
            },
            endCallBack: function () {
                this.stopQuiz();
            },
            startQuiz: function () {
                let uri = process.env.VUE_APP_APIHOST + 'student/courses-lessons/quiz/start';

                let formData = new FormData();
                formData.append("courses_id", this.courses_id);
                formData.append("courses_lessons_id", this.courses_lessons_id);
                this.dataFetch = true;
                this.$http.post(uri, formData).then(res => {
                    if (res.data.status) {
                        let getResponse = res.data.data;
                        this.detail     = getResponse;
                        this.dataFetch  = false;
                        this.start_time = this.detail.start_quiz;
                        this.end_time   = this.detail.expired_time;
                        this.form.courses_quiz_answer_id = this.detail.courses_quiz_answer_id;
                        this.setQuestions(this.detail.list_questions[0].id, 1);
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'quiz_overview',
                                    params : {
                                        courses_id : this.courses_id,
                                        id : this.courses_lessons_id                                        
                                    }
                                });
                            }
                        });
                    }
                });
            },
            scrollToTop() {
                window.scrollTo({top: 0, behavior: 'smooth'});
            },
            setQuestions: function(id, number) {
                var item                 = this.detail.list_questions.find(item => item.id === id);
                this.current_questions    = id;
                this.number_questions    = number;

                this.form.courses_lessons_questions_id  = item.id;
                this.form.type                          = item.type;
                this.form.questions                     = item.questions;
                this.form.options_a                     = item.options.a;
                this.form.options_b                     = item.options.b;
                this.form.options_c                     = item.options.c;
                this.form.options_d                     = item.options.d;
                this.form.options_e                     = item.options.e;                

                if(this.change_answer[item.id]) {
                    this.change_answer[item.id]  = this.current_save_answer[item.id];
                    this.form.your_answer       = this.current_save_answer[item.id];
                }
                
                else {
                    this.form.your_answer           = item.your_answer;
                }

                if(this.form.your_answer) {
                    this.current_save_questions[item.id]  = true;
                    this.current_save_answer[item.id]     = this.form.your_answer;
                }

                this.form.courses_quiz_answer_detail_id   = item.answer_detail_id;
                this.scrollToTop();
            },
            saveAnswer: function(your_answer="") {
                this.form.your_answer = your_answer;
                this.saveData('',false);
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.detail.list_questions.length;

                if(next_number > total_questions) {
                    alert("You are already at the last question");
                } else {
                    let id                  = this.detail.list_questions[number].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    alert("You are already in the first question");
                } else {
                    let id                  = this.detail.list_questions[next_number-1].id;
                    this.number_questions   = next_number;
                    this.setQuestions(id, next_number);
                }
            },
            saveData: function(number="", next=true) {
                let formData = new FormData();
                formData.append('answer', this.form.your_answer);
                formData.append('courses_quiz_answer_id', this.form.courses_quiz_answer_id);
                formData.append('courses_lessons_questions_id', this.form.courses_lessons_questions_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'student/courses-lessons/quiz/save-answer', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.data.status) {
                        this.current_save_questions[this.form.courses_lessons_questions_id] = true;
                        this.current_save_answer[this.form.courses_lessons_questions_id]    = this.form.your_answer;
                        this.change_answer[this.form.courses_lessons_questions_id]         = true;

                        if(next) {
                            this.next(number);
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            stopQuiz: function() {
                let formData = new FormData();
                formData.append('courses_quiz_answer_id', this.form.courses_quiz_answer_id);
                formData.append('courses_lessons_id', this.courses_lessons_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'student/courses-lessons/quiz/stop', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    if(response.data.status) {
                        this.$swal({
                            icon: 'success',
                            title: 'Success',
                            html: response.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name : 'quiz_overview',
                                    params : {
                                        courses_id : this.courses_id,
                                        id : this.courses_lessons_id                                        
                                    }
                                });
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                    }
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.waiting = false;
                    return;
                });
            },
            finishQuiz: function() {
                this.$swal({
                    icon: 'info',
                    title: 'Finish Quiz ?',
                    html: 'Submitted answers cannot be changed.<br/>Please double-check the saved answers',
                    confirmButtonText: 'Finish',
                    showCancelButton: true,
                    cancelButtonText: 'Check Again',
                }).then((result) => {
                    if (result.value) {
                        this.stopQuiz();
                    }
                });
            }
        }
    }
</script>