<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <h6 class="card-title">Webinar</h6>
                                <router-link :to="{ name: 'meeting_add' }" class="btn btn-primary">Add Data
                                </router-link>
                            </div>
                            <p class="card-description">Manage master data webinar</p>
                            <div class="table-responsive" v-if="!dataFetch">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="3%">#</th>
                                            <th>Name</th>
                                            <th>Date</th>
                                            <th>Invitation Link</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataList.length > 0">
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td class="text-center" width="3%">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>
                                                <img :src="item.cover ? item.cover : 'https://via.placeholder.com/513x342'"
                                                    class="img-circle mr-2">
                                                {{ item.name }}
                                            </td>
                                            <td>{{ item.text_date }}</td>
                                            <td>{{ item.invitation_link }}</td>
                                            <td>
                                                <label class="badge badge-success" v-if="item.status == 'publish'">Publish</label>
                                                <label class="badge badge-secondary" v-if="item.status != 'publish'">Draft</label>
                                            </td>
                                            <td width="10%">
                                                <a :href="item.url" class="btn btn-primary mr-2" target="_blank" v-if="item.url">View URL</a>
                                                <button type="button" class="btn btn-secondary mr-2" @click="seeParticipant(item)">See Participants</button>
                                                <router-link :to="{ name: 'meeting_edit', params: { id: item.id } }"
                                                    class="btn btn-outline-primary">Edit</router-link>
                                                <button class="btn btn-danger ml-2"
                                                    v-on:click="deleteData(item.id)">Delete</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="dataList.length == 0">
                                        <tr>
                                            <td colspan=5>
                                                <div class="d-block p-3 rounded bg-light">
                                                    <div class="text-center">Data not found</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                                <div class="text-center">loading data...</div>
                            </div>
                            <div class="d-block mt-3" v-if="pagination.totalPages > 0">
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages"
                                    :classes="pagination.bootstrapPaginationClasses"
                                    :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="list-member" width="50%" height="50%" >
            <div class="d-flex justify-content-between flex-column h-100 p-4" :style="{'overflow-y': 'auto'}">
                <div class="d-block">
                    <div class="h4 mb-3">
                        List of Member Conference
                    </div>
                    <table class="table" v-if="memberList.length > 0">
                        <tr>
                            <th width="5%">#</th>
                            <th>Identity Number</th>
                            <th>Name</th>
                            <th>Study Group</th>
                        </tr>
                        <tr v-for="(row, key) in memberList">
                            <td>{{key+1}}</td>
                            <td>{{row.identity_number}}</td>
                            <td>{{row.fullname}}</td>
                            <td>{{row.study_group}}</td>
                        </tr>
                    </table>
                </div>
                <div class="d-block p-3 bg-light rounded text-center" v-if="memberList.length == 0">Data is empty</div>
                <div class="d-flex justify-content-end">
                    <div class="btn btn-light" @click="closeModal()">Close</div>
                </div>
            </div>
        </modal>
        <foot></foot>
    </div>
</template>

<script>
import vPagination from 'vue-plain-pagination'
export default {
    name: 'Meeting',
    metaInfo: {
        title: 'Webinar',
        titleTemplate: '%s - Elearning'
    },
    components: {
        vPagination
    },
    data() {
        return {
            dataFetch: true,
            dataList: [],
            memberList: [],
            keyword: "",
            pagination: {
                currentPage: 1,
                totalPages: 1,
                limit: 10,
                bootstrapPaginationClasses: {
                    ul: 'pagination justify-content-end',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                paginationAnchorTexts: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/conference/list';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    keywords: this.keywords,
                    page: this.pagination.currentPage,
                    limit: this.pagination.limit
                }
            }).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
                this.pagination.totalPages = res.data.total_page;
            });
        },
        seeParticipant: function (data) {
            this.memberList = []
            this.$modal.show('list-member')
            let uri = process.env.VUE_APP_APIHOST + 'teacher/conference/list-member';

            this.$http.get(uri, {
                params: {
                    parent_id: data.id,
                }
            }).then(res => {
                this.memberList = res.data.data;
            });
        },
        closeModal: function() {
            this.$modal.hide('list-member')  
        },
        deleteData: function (id) {
            this.$swal({
                icon: 'info',
                title: 'Delete Data',
                text: 'Are you sure you want to delete this data?',
                showCancelButton: true,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/conference/delete', {
                        id: id
                    })
                        .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.getData();
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                        })
                }
            });
        },
    }
}
</script>