<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div v-if="dataFetch" class="page-content">
            <p>Please wait...</p>
        </div>
        <div v-if="!dataFetch" class="page-content">
            <!-- List of courses -->
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="d-block mb-3">
                        <div class="card rounded">
							<div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <h6 class="card-title mb-0">Courses Information</h6>
                                    </div>
                                    <div>
                                        <button v-if="sections.length > 0" class="btn btn-success" type="button" v-on:click="exportExcel">Export Excel</button>
                                        <button v-if="sections.length > 0" class="btn btn-danger ml-2" type="button" v-on:click="exportPDF" :disabled="isFetchBAP">{{isFetchBAP ? "Loading...":"Download BAP"}}</button>
                                        <router-link v-if="$route.query.tab != 'learning_recap'" class="btn btn-light ml-2" :to="{name: 'presence_teacher'}">Back</router-link>
                                        <button v-if="$route.query.tab == 'learning_recap'" class="btn btn-light ml-2" v-on:click="goBack()">Back</button>
                                    </div>
                                </div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Courses Title:</label>
									<p class="text-muted"><b>{{detail.name}}</b></p>
								</div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Program / Study Program :</label>
									<p class="text-muted"><b>{{detail.program}} / {{ detail.program_study }}</b></p>
								</div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Subject:</label>
									<p class="text-muted"><b>{{detail.category}}</b></p>
								</div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Year Period:</label>
									<p class="text-muted"><b>{{detail.periode}}</b></p>
								</div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Moderator:</label>
									<p class="text-muted"><b>{{detail.instructure}}</b></p>
								</div>

								<div class="mt-3" v-if="detail.teamteaching">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Co Moderator:</label>
									<p class="text-muted">
                                        <ul>
                                            <li v-for="(item, index) in detail.team_list">{{item}}</li>
                                        </ul>
                                    </p>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Total Members:</label>
									<p class="text-muted"><b><i class="feather icon-users"></i> {{detail.total_members}}</b></p>
								</div>
							</div>

                            <div class="card-body" v-if="sections.length > 0">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center" width="3%">No</th>
                                                <th>Sections Name</th>
                                                <th>Lessons Name</th>
                                                <th>Start</th>
                                                <th>End</th>
                                                <th>Presence Date</th>
                                                <th>Presence Status</th>
                                                <th>Description</th>
                                                <th>Total Member Presence</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, index) in sections" :key="index">
                                                <td>
                                                    {{++index}}
                                                </td>
                                                <td>{{row.sections_name}}</td>
                                                <td>{{row.lessons_name}}</td>
                                                <td v-if="row.presence_id">{{row.start_date}}</td>
                                                <td v-if="row.presence_id">{{row.end_date}}</td>
                                                <td v-if="row.presence_id">
                                                    {{row.presence_date}}
                                                </td>
                                                <td v-if="row.presence_id" class="text-center">
                                                    <label v-if="row.presence_status" class="badge badge-success"><i class="feather icon-check"></i></label>
                                                    <label v-if="!row.presence_status" class="badge badge-danger"><i class="feather icon-x"></i></label>
                                                </td>
                                                <td v-if="row.presence_id">
                                                    {{row.description}}
                                                </td>
                                                <td v-if="row.presence_id">
                                                    {{ row.total_presence_student }}
                                                </td>
                                                <td v-if="!row.presence_id" colspan="6">
                                                    <div class="bg-light rounded p-3 text-center text-muted">Not Presence</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="card-body" v-if="sections.length < 1">
                                <div class="rounded bg-light p-3 text-center">
                                    Teacher haven't created a lesson for this class, absence not found
                                </div>
                            </div>
						</div>                        
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ViewPresenceLecturer',
        metaInfo: {
            title: 'View Presence Lecturer',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination,
        },
        data() {
            return {
                isFetchBAP  : false,
                dataFetch   : true,
                courses_id  : this.$route.params.courses_id,
                users_id    : this.$route.params.users_id,
                detail      : {},
                sections    : {},
                list_member : [],
                keywords    : '',
                pagination  : {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 30,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getDataByID(this.courses_id, this.users_id);
        },
        methods: {
            getDataByID: function(courses_id, users_id) {
                let uri     = process.env.VUE_APP_APIHOST+'admin/courses/presence-teacher/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : courses_id,
                        users_id : users_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;
                        let getResponse     = res.data.data;
                        this.detail         = getResponse.courses;
                        this.sections       = getResponse.sections;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                history.back();
                            }
                        });
                    }
                });
            },
            exportExcel: function() {
                let uri = process.env.VUE_APP_APIHOST+'admin/export/teacher-presence?courses_id='+this.courses_id+'&users_id='+this.users_id;
                window.open(uri);
            },
            exportPDF() {
                this.isFetchBAP = true;
                this.$http.get(process.env.VUE_APP_APIHOST + 'admin/export/download-pdf-teacher-presence', {
                    params : {
                        courses_id : this.courses_id,
                        users_id : this.users_id,
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    this.isFetchBAP = false;
                    if(response.data.type == 'application/json') {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: "File gagal didownload",
                        });       
                    } else {
                        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${'Berita Acara Perkuliahan '+this.detail.name}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                })
            },
            goBack: function() {
                this.$router.push(
                    {
                        name: 'learning_recap_detail',
                        params : {
                            periode_id : this.$route.query.periode_id,
                            users_id : this.users_id
                        }
                    });
            }
        }
    }
</script>