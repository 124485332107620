<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div v-if="dataFetch" class="page-content">
            <p>Please wait...</p>
        </div>
        <div v-if="!dataFetch" class="page-content">
            <!-- List of courses -->
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="d-block">
                        <div class="card rounded">
							<div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <h6 class="card-title mb-0">Courses Information</h6>
                                    </div>
                                    <div>
                                        <button v-if="sections.data_members.length > 0" :disabled="fetchDownload" type="button" class="btn btn-success" v-on:click="downloadExcel()">
                                            {{fetchDownload ? 'Downloading....':'Download Excel'}}
                                        </button>
                                        <button class="btn btn-light ml-2" v-on:click="goBack()">Back</button>
                                    </div>
                                </div>
                                
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Courses Title:</label>
									<p class="text-muted">{{detail.name}}</p>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Moderator:</label>
									<p class="text-muted">{{detail.instructure}}</p>
								</div>

								<div class="mt-3" v-if="detail.teamteaching">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Co Moderator:</label>
									<p class="text-muted">
                                        <ul>
                                            <li v-for="(item, index) in detail.team_list">{{item}}</li>
                                        </ul>
                                    </p>
								</div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Total Members:</label>
									<p class="text-muted"><i class="feather icon-users"></i> {{detail.total_members}}</p>
								</div>

                                <div class="mt-3">
                                    <label class="tx-11 fw-bolder mb-0 text-uppercase">Total Quiz & Assignments:</label>
                                    <p class="text-muted"><i class="feather icon-book"></i> {{sections.data_lessons.length}}</p>
                                </div>
                                <div class="mt-3">
                                    <label class="tx-11 fw-bolder mb-0 text-uppercase">Detail Quiz & Assignments:</label>
                                    <p class="text-muted">
                                        <ol class="mt-2">
                                            <li v-for="(item, index) in sections.data_lessons" :key="index">
                                                {{item.sections_name+' - '+item.lessons_name }} ({{item.lessons_type}})
                                            </li>
                                        </ol>
                                    </p>
                                </div>
							</div>

                            <div class="card-body" v-if="sections.data_members.length > 0">
                                <div class="table-responsive table-bordered">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center" style="width:3%" rowspan="2">No</th>
                                                <th rowspan="2">Student Name</th>
                                                <th  class="text-center" v-if="sections.data_lessons.length > 0" :colspan="sections.data_lessons.length">Score
                                                </th>
                                            </tr>
                                            <tr>
                                                <th class="text-center" v-for="(item, index) in sections.data_lessons" :key="index">
                                                    {{ ++index }}<br/>
                                                    <small>({{ item.lessons_name }})</small>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(row, index) in sections.data_members" :key="index">
                                                <td class="text-center">
                                                    {{++index}}
                                                </td>   
                                                <td style="width:50%">
                                                    {{ row.identity_number }}<br/>{{row.name}}
                                                </td>
                                                <td style="max-width:5%" class="text-center" v-for="(rowDetail, indexDetail) in row.score" :key="indexDetail">{{ rowDetail }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="card-body" v-if="sections.data_members.length < 1">
                                <div class="rounded bg-light p-3 text-center">
                                    Data not found, Quiz and assignment not created.
                                </div>
                            </div>
						</div>                        
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ViewRecapScoreStudent',
        metaInfo: {
            title: 'View Recap Score Student',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination,
        },
        data() {
            return {
                fetchDownload: false,
                isFetchBAP  : false,
                dataFetch   : true,
                courses_id  : this.$route.params.courses_id,
                users_id    : this.$route.params.users_id,
                detail      : {},
                sections    : {
                    data_members : [],
                    data_lessons : []
                },
                keywords    : '',
                pagination  : {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 30,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getDataByID(this.courses_id, this.users_id);
        },
        methods: {
            getDataByID: function(courses_id, users_id) {
                let uri     = process.env.VUE_APP_APIHOST+'teacher/courses/recap-score-student';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : courses_id,
                        users_id : users_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;
                        let getResponse     = res.data.data;
                        this.detail         = getResponse.courses;
                        this.sections       = getResponse.sections;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                history.back();
                            }
                        });
                    }
                });
            },
            downloadExcel: function() {
                this.fetchDownload = true;
                let formData = new FormData();

                formData.append('courses_id', this.courses_id);
                formData.append('users_id', this.detail.users_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/export/recap-score-student',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `recap-score-student-${this.detail.name}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.fetchDownload = false;                    
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.fetchDownload = false;                    
                    return;
                });
            },
            goBack: function() {
                this.$router.push(
                {
                    name: 'learning_recap_teacher'
                });
            }
        }
    }
</script>