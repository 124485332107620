import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        userdata: {
            id          : '',
            username    : '',
            name        : '',
            role        : '',
            logged_in   : false,
        }
    },
    mutations: {
        set_userdata(state, userdata) {
            state.userdata = userdata;
        }
    },
    actions: {
        loadUserData({
            commit
        }) {
            axios.get(process.env.VUE_APP_APIHOST + 'user/info')
                .then(response => {
                    commit('set_userdata', response.data);
                });
        }
    }
});