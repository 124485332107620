<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <h6 class="card-title">List of Prodi Account</h6>
                                <router-link :to="{name: 'prodi_add'}" class="btn btn-primary">Add Data</router-link>
                            </div>
                            <div class="d-flex">
                                <p class="card-description mr-auto p-2">Manage data prodi account</p>
                                <div class="p-2">
                                    <input type="text" class="form-control w-100" placeholder="search here" v-model="keywords" v-on:keyup.enter="getData()">
                                </div>
                            </div>
                            <div class="table-responsive" v-if="!dataFetch">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center" width="3%">#</th>
                                            <th>Name</th>
                                            <th>NIDN</th>
                                            <th>Gender</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Level of Education</th>
                                            <th>Study Program</th>
                                            <th width="5%"></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="dataList.length > 0">
                                        <tr v-for="(item, index) in dataList" :key="index">
                                            <td class="text-center" width="3%">
                                                {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center">
                                                    <img v-if="!item.photo" src="images/default_user.png" class="rounded-circle wd-35" alt="user">
                                                    <img v-if="item.photo" :src="item.photo" class="rounded-circle wd-35" alt="user">                                                    
                                                    <div class="d-block ml-3">
                                                        <div class="text-muted mb-1">Identity Number {{item.identity_number}}</div>
                                                        <div class="text-dark font-weight-bold tx-15">{{item.fullname}}</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{item.nidn}}</td>
                                            <td>{{item.gender}}</td>
                                            <td>{{item.email}}</td>
                                            <td>{{item.phone}}</td>
                                            <td>{{item.education_level}}</td>
                                            <td>{{item.program_study}}</td>
                                            <td width="5%">
                                                <router-link :to="{name: 'prodi_edit', params: {id:item.id}}" class="btn btn-outline-primary">Edit</router-link>
                                                <button class="btn btn-danger ml-2" v-on:click="deleteData(item.id, item.users_id)">Delete</button>
                                                <button class="btn btn-outline-dark ml-2" v-on:click="payload.id = item.id" data-toggle="modal" data-target="#ubahPasswordModal">Change Password</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="dataList.length == 0">
                                        <tr>
                                            <td colspan=9>
                                                <div class="d-block p-3 rounded bg-light">
                                                    <div class="text-center">Data not found</div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                                <div class="text-center">loading data...</div>
                            </div>
                            <div class="d-block mt-3" v-if="dataList.length > 0">
                                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="ubahPasswordModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Change Password</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="">New Password</label>
                            <input type="password" class="form-control" v-model="payload.new_password">
                        </div>
                        <div class="form-group">
                            <label for="">New Password Confirmation</label>
                            <input type="password" class="form-control" v-model="payload.retype_new_password">
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Batal</button>
                        <button type="button" class="btn btn-primary" v-on:click="fungsiGantiPassword()">Kirim</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        name: 'Prodi',
        metaInfo: {
            title: 'Prodi',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination
        },
        data() {
            return {
                dataFetch: true,
                dataList: [],
                keywords: "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    },
                },
                payload : {}
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/account/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit,
                        role     : 'administrator'
                    }
                }).then(res => {
                    this.dataFetch              = false;
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                });
            },
            deleteData: function (id, userId) {
                this.$swal({
                    icon: 'info',
                    title: 'Delete Data',
                    text: 'Are you sure you want to delete this data?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/account/delete', {
                        id: id,
                        users_id: userId
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            fungsiGantiPassword : async function () {
                let uri         = process.env.VUE_APP_APIHOST+'admin/account/change_password';
                this.$http.post(uri,this.payload).then((resp) => {
                    const response = resp.data
                    if(response.status){
                        this.$swal({
                            icon: 'success',
                            title: 'Berasil!',
                            text: response.message,
                        });

                        $('#ubahPasswordModal').modal('hide');
                    }else{
                        this.$swal({
                            icon: 'error',
                            title: 'Ups!',
                            text: response.message,
                        });
                    }
                })
            }
        }
    }
</script>