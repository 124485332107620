<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div>
                <h5 class="mb-3 mb-2">PRESENCE LECTURER</h5>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-0">
                                <i class="mdi mdi-clipboard-search-outline"></i>
                                Search Filters</h4>
                            <p class="card-title-desc mb-0">Please use the filter below to search lecturer present data, please select a lecturer below </p>
                            <div class="row">
                                <div class="col-md-12 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <label for="exampleFormControlSelect1">Choose Lecturer</label>
                                        <multiselect v-model="params.users" :options="list_teacher" placeholder="Choose teacher" :show-labels="false" label="fullname" track-by="fullname"  @input="searchData">
                                            <template slot="option" slot-scope="props"><strong>{{ props.option.fullname }}</strong></template>
                                        </multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-2" v-if="params.users">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <select class="form-control" v-model="params.program_id" v-on:change="searchData">
                                            <option value="">See All Program</option>
                                            <option v-for="row in list_program" :value="row.id" :key="row.id">{{row.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <select class="form-control" v-model="params.periode_id" v-on:change="searchData">
                                            <option value="">See All Year</option>
                                            <option v-for="row in list_year" :value="row.id" :key="row.id">{{row.name}} {{row.active == '1' ? '(Active)' : ''}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <select class="form-control" v-model="params.courses_category_id" v-on:change="searchData">
                                            <option value="">See All Subject</option>
                                            <option v-for="row in list_course_category" :value="row.id" :key="row.id">{{row.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group mb-0">
                                        <input type="text" class="form-control" placeholder="Enter the keyword then press enter ..." v-model="params.keywords" v-on:keyup.enter="searchData" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="params.users">
                        <div class="row mt-3" v-if="dataFetch">
                            <div class="col-md-12">
                                <div class="d-block p-3 rounded bg-light">
                                    <div class="text-center">loading data...</div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3" v-if="!dataFetch">
                            <div class="col-md-12" v-if="list_course.length > 0">
                                <div class="d-block">
                                    <div class="row">
                                        <div class="col-md-3 mb-3" v-for="row in list_course" :key="row.id">
                                            <div class="card">
                                                <img v-if="!row.cover" src="images/cover_courses.jpg" class="card-img-top" alt="images/cover_courses.jpg" style="background-size: cover !important;">
                                                <img v-if="row.cover" :src="row.cover" class="card-img-top" :alt="row.cover" style="background-size: cover !important;">
                                                <div class="card-body p-3">
                                                    <h5 class="card-title text-capitalize mb-2 font-1rem">{{row.name}}</h5>
                                                    <p class="card-text">{{row.program_study}}</p>
                                                    <p class="card-text text-muted">{{row.category}}</p>
                                                    <p class="card-text text-muted">{{row.program}}</p>
                                                    <div class="card-block mt-2">
                                                        <div class="d-flex card-text align-items-center">
                                                            <div><i class="feather icon-award wd-15"></i></div>
                                                            <div class="mt-1 ml-2">{{row.instructure}}</div>
                                                        </div>
                                                        <div class="d-flex card-text align-items-center">
                                                            <div><i class="feather icon-user wd-15"></i></div>
                                                            <div class="mt-1 ml-2">{{row.member}} Parisipant(s)</div>
                                                        </div>
                                                    </div>
                                                    <button type="button" class="btn btn-primary btn-block mt-2" v-on:click="viewPresence(row.id)">
                                                        View Presence
                                                    </button>
                                                    <div class="alert alert-info mt-2 text-center mb-0" v-if="row.co_moderator">
                                                        Registered as co moderator
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="list_course.length < 1">
                                <div class="d-block p-3 rounded bg-light">
                                    <div class="text-center">Data not found</div>
                                </div>                            
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        name: 'PresenceLecturer',
        metaInfo: {
            title: 'Presence Lecturer',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination
        },
        data() {
            return {
                dataFetch: true,
                dataList: [],
                keywords: "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                list_teacher    : [],
                list_course     : [],
                list_program    : [],
                list_year       : [],
                list_course_category    : [],
                list_program_study      : [],
                params: {
                    courses_category_id : '',
                    program_id          : '',
                    program_study_id    : '',
                    periode_id          : '',
                    status              : '',
                    keywords            : '',
                    limit               : 6,
                    users            : ""
                }
            }
        },
        created() {
            this.listTeacher();
            this.listProgram();
            this.listYear();
            this.listCourseCategory();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/program/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.dataFetch              = false;
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                });
            },
            listTeacher: function () {
                let uri = process.env.VUE_APP_APIHOST + 'general/list/teacher';

                this.$http.get(uri, {}).then(res => {
                    if (res.data.status) {
                        this.list_teacher = res.data.data;
                    }
                });
            },
            listProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/program';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_program = res.data.data;
                }
            });
            },
            listYear: function () {
                let uri = process.env.VUE_APP_APIHOST + 'general/list/periode';

                this.$http.get(uri).then(res => {
                    if (res.data.status) {
                        this.list_year = res.data.data;
                    }
                });
            },
            listCourseCategory: function () {
                let uri = process.env.VUE_APP_APIHOST + 'general/list/courses-category';

                this.$http.get(uri).then(res => {
                    if (res.data.status) {
                        this.list_course_category = res.data.data;
                    }
                });
            },
            searchData: function () {
                this.dataFetch      = true;
                this.list_course    = [];
                let uri = process.env.VUE_APP_APIHOST + 'admin/courses/presence-teacher';

                this.$http.get(uri, {
                    params: this.params
                }).then(res => {
                    this.dataFetch = false;
                    if (res.data.status) {
                        this.list_course = res.data.data;
                    }
                });
            },
            viewPresence: function(courses_id="") {
                this.$router.push(
                    {
                        name: 'view_presence_teacher',
                        params : {
                            courses_id : courses_id,
                            users_id : this.params.users.users_id
                        }
                    });
            }
        }
    }
</script>