<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div>
                <h5 class="mb-3 mb-2">Learning Recap</h5>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title mb-0">
                                <i class="mdi mdi-clipboard-search-outline"></i>
                                Search Filters</h4>
                            <p class="card-title-desc mb-0">Please use the filters below to find a learning recap.</p>
                            <div class="row">
                                <div class="col-md-12 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <label for="exampleFormControlSelect1">Choose Year Period</label>
                                        <select class="form-control" v-model="params.periode" v-on:change="searchData">
                                            <option value="">Choose Year Period</option>
                                            <option v-for="row in list_year" :value="row" :key="row.id">{{row.name}} {{row.active == '1' ? '(Active)' : ''}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <input type="text" :disabled="!params.periode.id" class="form-control" placeholder="search by lecturer's name" v-model="params.keywords" v-on:keyup.enter="getData()"/>
                                    </div>
                                </div>
                                <div v-if="(params.periode.id && !dataFetch)" class="col-md-12 mt-3 mb-0">
                                    <div class="form-group mb-0">
                                        <button :disabled="fetchDownload" type="button" class="btn btn-success" v-on:click="downloadExcel()">
                                            {{fetchDownload ? 'Downloading....':'Download Excel'}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="params.periode.id">
                        <div class="row mt-3" v-if="dataFetch">
                            <div class="col-md-12">
                                <div class="d-block p-3 rounded bg-light">
                                    <div class="text-center">loading data...</div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-3" v-if="!dataFetch">
                            <div class="col-md-12 grid-margin stretch-card" v-if="list_recap.length > 0">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <h6 class="card-title">Periode Recap: {{params.periode.name}}</h6>
                                        </div>
                                        <div class="table-responsive table-bordered" v-if="!dataFetch">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th class="text-center" width="3%" rowspan="2">#</th>
                                                        <th rowspan="2">Lecture Name</th>
                                                        <th class="text-center">Total</th>
                                                        <th colspan="4" class="text-center">Total Lessons</th>
                                                        <th colspan="4" class="text-center">Lessons has been implemented</th>
                                                        <th class="text-center" colspan="2">Percentage</th>
                                                    </tr>
                                                    <tr>
                                                        <th class="text-center">Courses</th>

                                                        <th class="text-center">Theory</th>
                                                        <th class="text-center">Assignment</th>
                                                        <th class="text-center">Quiz</th>
                                                        <th class="text-center">FGD</th>
                                                        
                                                        <th class="text-center">Theory</th>
                                                        <th class="text-center">Assignment</th>
                                                        <th class="text-center">Quiz</th>
                                                        <th class="text-center">FGD</th>

                                                        <th class="text-center">lecturer attendance</th>
                                                        <th class="text-center">student attendance</th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="list_recap.length > 0">
                                                    <tr v-for="(item, index) in list_recap" :key="index">
                                                        <td class="text-center" width="3%">
                                                            {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                        </td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <div class="text-dark font-weight-bold tx-15">
                                                                    {{ item.identity_number }}<br/>
                                                                    {{item.fullname}}<br/>
                                                                    <a href="javascript:void(0)" v-on:click="viewDetail(item.id)">(View Detail)</a>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_courses}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_theory}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_assignment}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_quiz}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.total_fgd}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_theory}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_assignment}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_quiz}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.presence_fgd}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.percentage ? item.statistic.percentage+'%' : '-'}}
                                                        </td>
                                                        <td class="text-center">
                                                            {{item.statistic.percentage_student ? item.statistic.percentage_student+'%' : '-'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                                <tbody v-if="list_recap.length < 1">
                                                    <tr>
                                                        <td colspan="13">
                                                            <div class="d-block p-3 rounded bg-light">
                                                                <div class="text-center">Data not found</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                                            <div class="text-center">loading data...</div>
                                        </div>
                                        <div class="d-block mt-3" v-if="list_recap.length > 0">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="searchData()"></v-pagination>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12" v-if="list_recap.length < 1">
                                <div class="d-block p-3 rounded bg-light">
                                    <div class="text-center">Data not found</div>
                                </div>                            
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3" v-if="!params.periode.id">
                        <div class="col-md-12">
                            <div class="d-block p-5 rounded bg-light">
                                <div class="text-center">Please choose a year period to view data.</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        name: 'LearningRecap',
        metaInfo: {
            title: 'Learning Recap',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination
        },
        data() {
            return {
                fetchDownload: false,
                dataFetch: true,
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                list_recap      : [],
                list_year       : [],
                params: {
                    keywords    : '',
                    periode     : '',
                    limit       : 20,
                }
            }
        },
        created() {
            this.listYear();
        },
        methods: {
            listYear: function () {
                let uri = process.env.VUE_APP_APIHOST + 'general/list/periode';

                this.$http.get(uri).then(res => {
                    if (res.data.status) {
                        this.list_year = res.data.data;
                    }
                });
            },
            searchData: function () {
                this.dataFetch      = true;
                this.list_recap    = [];
                let uri = process.env.VUE_APP_APIHOST + 'admin/courses/learning-recap';

                this.$http.get(uri, {
                    params: {
                        periode_id  : this.params.periode.id,
                        keywords    : this.params.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.dataFetch = false;
                    if (res.data.status) {
                        this.list_recap = res.data.data;
                        this.pagination.totalPages  = res.data.total_page;                        
                    }
                });
            },
            getData: function () {
                this.dataFetch      = true;
                this.list_recap    = [];
                let uri = process.env.VUE_APP_APIHOST + 'admin/courses/learning-recap';

                this.$http.get(uri, {
                    params: {
                        periode_id  : this.params.periode.id,
                        keywords    : this.params.keywords
                    }
                }).then(res => {
                    this.dataFetch = false;
                    if (res.data.status) {
                        this.list_recap = res.data.data;
                        this.pagination.totalPages  = res.data.total_page;                        
                    }
                });
            },
            downloadExcel: function() {
                this.fetchDownload = true;
                let formData = new FormData();

                formData.append('periode_id', this.params.periode.id);
                formData.append('keywords', this.params.keywords);

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/courses/download-learning-recap',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `learning-recap.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.fetchDownload = false;                    
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.fetchDownload = false;                    
                    return;
                });
            },
            viewDetail: function(users_id="") {
                this.$router.push({
                    name: 'learning_recap_detail',
                    params : {
                        periode_id : this.params.periode.id,
                        users_id : users_id
                    }
                });
            }
        }
    }
</script>