<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="row">
                <div class="col-xl-7">
                    <div class="card border">
                        <div class="card-body">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h6 class="card-title">Import Data Student</h6>
                                <div class="page-title-right">
                                    <router-link :to="{name: 'student'}" class="btn btn-outline-primary">Back</router-link>
                                </div>
                            </div>

                            <form v-on:submit.prevent="onSubmit">
                                <div class="row mt-3  ">
                                    <div class="form-group col-md-12">
                                        <input type="file" class="form-control" name="file" id="file" @change="previewFiles" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                        <small><i>Max upload : 5 MB</i></small>
                                    </div>
                                </div>
                                <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                                <i class="fa fa-upload"></i> {{ waiting ? 'Loading ...' : 'Upload' }}</button>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-xl-5">
                    <div class="card border">
                        <div class="card-body">
                            <div class="page-title-box d-sm-flex align-items-center justify-content-between mb-0">
                                <h6 class="card-title">Import terms and conditions</h6>
                            </div>
                            <ol>
                                <li>Make sure your internet connection is secure.</li>
                                <li>Use the import format provided by the system.</li>
                                <li>If you don't have an import format <br/>Please click the link below</li>
                                <li><a href="template/student-import-format-new-2023.xlsx" target="blank"><b>Download Format</b></a></li>
                                <li>Make sure the format you import is correct.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>    
    export default {
        name: 'StudentImport',
        metaInfo: {
            title: 'Import Data Student',
            titleTemplate: '%s - Elearning'
        },
        data() {
            return {
                label           : 'Tambah',
                form : {
                    file : ""
                },
                submitted       : false,
                waiting         : false,
                dataFetch       : true,
            }
        },
        methods: {
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_image = URL.createObjectURL(file);
                    this.form.file = file;
                }
            },
            onSubmit: function () {
                if(this.form.file == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap masukan file import",
                    });
                } else {
                    this.waiting    = true;
                    this.submitted  = true;

                    let formData = new FormData();

                    formData.append('file', this.form.file);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/account/student_import', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({name : 'student'});
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            }
        }
    }
</script>