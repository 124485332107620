<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <!-- List of courses -->

        <div v-if="detail.disabled_edit" class="alert alert-warning" role="alert">
            Quiz is running on students...
        </div>

        <div v-if="!detail.data_presence.id" class="alert alert-warning mt-2">
            <p><i class="mdi mdi-information mr-2"></i> You haven't made an presence.</p>
        </div>

        <div v-if="detail.data_presence.id" class="alert alert-success mt-2">            
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <p><i class="feather icon-check"></i> You've been presence in this lesson.</p>
                </div>
                <div>
                    <router-link class="btn btn-light ml-2" :to="{name: 'view_presence_lessons', params: {courses_id: detail.courses_id, id:detail.id}}">Check Presence</router-link>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-xl-12">
                <div class="d-block mb-3">
                    <div class="card">
                        <div class="card-body pb-1">
                            <div class="d-flex align-items-center border-bottom pb-2">
                                <router-link class="btn btn-light" :to="{name: 'courses_section', params: {id: params.courses_id}}">Back
                                </router-link>
                                <div class="d-block ml-3">
                                    <h5 class="font-weight-bold">{{detail.name}}</h5>
                                    <p class="text-muted">{{detail.courses_name}}</p>
                                </div>
                            </div>
                            <ul class="nav mt-2">
                                <li class="nav-item">
                                    <a class="nav-link pl-0 tx-16" :class="currentTabComponent == 'ManageQuestion' ? 'active' : 'text-dark'" href="javascript:void(0)" @click="changeTab('ManageQuestion')">Questions</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link tx-16" :class="currentTabComponent == 'QuizStatus' ? 'active' : 'text-dark'" href="javascript:void(0)" @click="changeTab('QuizStatus')">Quiz Status</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link tx-16" :class="currentTabComponent == 'QuizResult' ? 'active' : 'text-dark'" href="javascript:void(0)" @click="changeTab('QuizResult')">Quiz Result</a>
                                </li>
                                <li class="nav-item d-none">
                                    <a class="nav-link tx-16" :class="currentTabComponent == 'QuizAnalysis' ? 'active' : 'text-dark'" href="javascript:void(0)" @click="changeTab('QuizAnalysis')">Quiz Analysis</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <keep-alive> -->
        <component :params="params" :detail="detail" :is="currentTabComponent"></component>
        <!-- </keep-alive> -->
    </div>
</div>
</template>

<script>
// import tabs components
import ManageQuestion from '@/components/tabs/teacher/quiz/ManageQuestion.vue';
import QuizStatus from '@/components/tabs/teacher/quiz/QuizStatus.vue';
import QuizResult from '@/components/tabs/teacher/quiz/QuizResult.vue';
import QuizAnalysis from '@/components/tabs/teacher/quiz/QuizAnalysis.vue';

export default {
    name: 'ManageQuiz',
    metaInfo: {
        title: 'Manage Quiz',
        titleTemplate: '%s - Elearning'
    },
    components: {
        ManageQuestion,
        QuizStatus,
        QuizResult,
        QuizAnalysis,
    },
    data() {
        return {
            currentTabComponent: 'ManageQuestion',
            params: this.$route.params,
            detail: {}
        }
    },
    created() {
        this.fetchDataDetail(this.$route.params.courses_id, this.$route.params.id);
    },
    methods: {
        fetchDataDetail: function (courses_id, id) {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/detail';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_id: courses_id,
                    courses_lessons_id: id
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.detail = getResponse;
                }
            });
        },
        changeTab: function (tabActive) {
            this.currentTabComponent = tabActive;
        },
    },

}
</script>
