<template>
    <div class="card">
        <div class="card-body">
            <div class="card-subtitle mt-0">Please complete the form below.</div>
            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="program_study_id">Study Program</label>
                        <select class="form-control" id="program_study_id" name="program_study_id" v-model="form.program_study_id" :class="{ 'is-invalid': submitted && $v.form.program_study_id.$error }">
                            <option value="">Choose Study Program</option>
                            <option v-for="item in list_program_study" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.program_study_id.required" class="invalid-feedback">Study Program is required!</div>
                    </div>
                    <div class="col-md-6">
                        <label for="education_level_id">Level of Education</label>
                        <select class="form-control" id="education_level_id" name="education_level_id" v-model="form.education_level_id" :class="{ 'is-invalid': submitted && $v.form.education_level_id.$error }">
                            <option value="">Choose Level of Education</option>
                            <option v-for="item in list_education" :key="item.id" :value="item.id">{{item.name}}</option>
                        </select>
                        <div v-if="submitted && !$v.form.education_level_id.required" class="invalid-feedback">Level of Education is required!</div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="status_teacher">Status Teacher</label>
                        <select class="form-control" id="status_teacher" name="status_teacher" v-model="form.status_teacher" :class="{ 'is-invalid': submitted && $v.form.status_teacher.$error }">
                            <option value="permanent">Permanent</option>
                            <option value="non-permanent">Non Permanent</option>
                        </select>
                        <div v-if="submitted && !$v.form.status_teacher.required" class="invalid-feedback">Status Teacher is required!</div>
                    </div>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save & Next</button>
                    <router-link :to="{name: 'teacher'}" class="btn btn-light mt-2">Back</router-link>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import moment from 'moment';
    
    export default {
        name: 'Academic',
        metaInfo: {
            title: 'Form Student',
            titleTemplate: '%s - Unfari E-learning'
        },
        props: {
            form: Object
        },
        components : {
            // Datepicker,
        },
        created() {
            this.getStudyProgram();
            this.getEducation();
        },
        data() {
            return {
                list_program_study  : [],
                list_education      : [],
                submitted           : false,
                waiting             : false,
            }
        },
        validations: {
            form: {
                education_level_id: {
                    required
                },
                program_study_id: {
                    required
                },
                status_teacher: {
                    required
                },
            }
        },
        methods: {
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the forms',
                    });

                    this.waiting = false;
                    return;
                } else {
                    this.form.date_entry = this.convertDate(this.form.date_entry);
                    this.$emit("listenerChild", this.form);
                }
            },
            getStudyProgram: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/program-study/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_program_study = res.data.data;
                    }
                });
            },
            getEducation: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/education/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_education = res.data.data;
                    }
                });
            },
        }
    }
</script>