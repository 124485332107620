<template>
	<nav class="sidebar">
      <div class="sidebar-header">
        <a href="javascript:void(0)" class="sidebar-brand">
          <img class="img-fluid wd-100" src="images/logo.png" />
        </a>
        <div class="sidebar-toggler" id="sidebar-toggler" @click="toggleSidebar()">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="sidebar-body" style="overflow-y:auto;">
        <ul class="nav" v-if="(users.role == 'administrator' || users.role == 'prodi')">
          <li class="nav-item nav-category">Main</li>
          <li class="nav-item" :class="activeClass == 'home_admin' ? 'active' : ''" v-on:click="setCurrent('home_admin')">
            <router-link :to="{name: 'home_admin'}" class="nav-link">
              <i class="link-icon feather icon-grid"></i>
              <span class="link-title">Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item nav-category">Master Data</li>
          <li class="nav-item" :class="activeClass == 'year_period' ? 'active' : ''" v-on:click="setCurrent('year_period')">
            <router-link :to="{name: 'year_period'}" class="nav-link">
              <i class="link-icon feather icon-calendar"></i>
              <span class="link-title">Year Period</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'program' ? 'active' : ''" v-on:click="setCurrent('program')">
            <router-link :to="{name: 'program'}" class="nav-link">
              <i class="link-icon feather icon-bookmark"></i>
              <span class="link-title">Program</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'study_program' ? 'active' : ''" v-on:click="setCurrent('study_program')">
            <router-link :to="{name: 'study_program'}" class="nav-link">
              <i class="link-icon feather icon-bookmark"></i>
              <span class="link-title">Study Program</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'education_level' ? 'active' : ''" v-on:click="setCurrent('education_level')">
            <router-link :to="{name: 'education_level'}" class="nav-link">
              <i class="link-icon feather icon-tag"></i>
              <span class="link-title">Education Level</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'curriculum' ? 'active' : ''" v-on:click="setCurrent('curriculum')">
            <router-link :to="{name: 'curriculum'}" class="nav-link">
              <i class="link-icon feather icon-book"></i>
              <span class="link-title">Curriculum</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'announcement' ? 'active' : ''" v-on:click="setCurrent('announcement')">
            <router-link :to="{name: 'announcement'}" class="nav-link">
              <i class="link-icon feather icon-airplay"></i>
              <span class="link-title">Announcement</span>
            </router-link>
          </li>
          <li class="nav-item nav-category">Manage Account</li>
          <li class="nav-item" :class="activeClass == 'study_group' ? 'active' : ''" v-on:click="setCurrent('study_group')">
            <router-link :to="{name: 'study_group'}" class="nav-link">
              <i class="link-icon feather icon-layers"></i>
              <span class="link-title">Study Group</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'student' ? 'active' : ''" v-on:click="setCurrent('student')">
            <router-link :to="{name: 'student'}" class="nav-link">
              <i class="link-icon feather icon-user"></i>
              <span class="link-title">Student</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'teacher' ? 'active' : ''" v-on:click="setCurrent('teacher')">
            <router-link :to="{name: 'teacher'}" class="nav-link">
              <i class="link-icon feather icon-award"></i>
              <span class="link-title">Lecturer</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'prodi' ? 'active' : ''" v-on:click="setCurrent('prodi')">
            <router-link :to="{name: 'prodi'}" class="nav-link">
              <i class="link-icon feather icon-users"></i>
              <span class="link-title">Prodi Account</span>
            </router-link>
          </li>
          <li class="nav-item nav-category">Manage Courses</li>
          <li class="nav-item" :class="activeClass == 'courses_category' ? 'active' : ''" v-on:click="setCurrent('courses_category')">
            <router-link :to="{name: 'courses_category'}" class="nav-link">
              <i class="link-icon feather icon-book-open"></i>
              <span class="link-title">Subject</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'manage_course' ? 'active' : ''" v-on:click="setCurrent('manage_course')">
            <router-link :to="{name: 'manage_course'}" class="nav-link">
              <i class="link-icon feather icon-monitor"></i>
              <span class="link-title">Courses</span>
            </router-link>
          </li>
          <li class="nav-item nav-category">Reporting</li>
          <li class="nav-item" :class="activeClass == 'presence_teacher' ? 'active' : ''" v-on:click="setCurrent('presence_teacher')">
            <router-link :to="{name: 'presence_teacher'}" class="nav-link">
              <i class="link-icon feather icon-user-check"></i>
              <span class="link-title">Presence Lecturer</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'learning_recap' ? 'active' : ''" v-on:click="setCurrent('learning_recap')">
            <router-link :to="{name: 'learning_recap'}" class="nav-link">
              <i class="link-icon feather icon-book"></i>
              <span class="link-title">Learning Recap</span>
            </router-link>
          </li>
        </ul>
        <ul class="nav" v-if="users.role == 'teacher'">
          <li class="nav-item nav-category">Main</li>
          <li class="nav-item" :class="activeClass == 'home_teacher' ? 'active' : ''" v-on:click="setCurrent('home_teacher')">
            <router-link :to="{name: 'home_teacher'}" class="nav-link">
              <i class="link-icon feather icon-grid"></i>
              <span class="link-title">Dashboard</span>
            </router-link>
          </li>
          <li class="nav-item nav-category">Courses</li>
          <li class="nav-item d-none">
            <a class="nav-link" data-toggle="collapse" href="#emails" role="button" aria-expanded="false" aria-controls="emails">
              <i class="link-icon feather icon-calendar"></i>
              <span class="link-title">Year Period</span>
              <i class="link-arrow" data-feather="chevron-down"></i>
            </a>
            <div class="collapse" id="emails">
              <ul class="nav sub-menu">
                <li class="nav-item">
                  <a href="pages/email/inbox.html" class="nav-link">Inbox</a>
                </li>
                <li class="nav-item">
                  <a href="pages/email/read.html" class="nav-link">Read</a>
                </li>
                <li class="nav-item">
                  <a href="pages/email/compose.html" class="nav-link">Compose</a>
                </li>
              </ul>
            </div>
          </li>
          <li class="nav-item" :class="activeClass == 'courses_teacher' ? 'active' : ''" v-on:click="setCurrent('courses_teacher')">
            <router-link :to="{name: 'courses_teacher'}" class="nav-link">
              <i class="link-icon feather icon-airplay"></i>
              <span class="link-title">Courses</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'meeting' ? 'active' : ''" v-on:click="setCurrent('meeting')">
            <router-link :to="{name: 'meeting'}" class="nav-link">
              <i class="link-icon feather icon-video"></i>
              <span class="link-title">Webinar</span>
            </router-link>
          </li>
          <li class="nav-item d-none" :class="activeClass == 'reporting' ? 'active' : ''" v-on:click="setCurrent('reporting')">
            <router-link :to="{name: 'reporting'}" class="nav-link">
              <i class="link-icon feather icon-printer"></i>
              <span class="link-title">Reporting</span>
            </router-link>
          </li>
          <li class="nav-item" :class="activeClass == 'learning_recap_teacher' ? 'active' : ''" v-on:click="setCurrent('learning_recap_teacher')">
            <router-link :to="{name: 'learning_recap_teacher'}" class="nav-link">
              <i class="link-icon feather icon-printer"></i>
              <span class="link-title">Learning Recap</span>
            </router-link>
          </li>
        </ul>
      </div>
    </nav>
</template>


<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Main',
        data() {
            return {
                activeClass     : this.$route.name,
                activeMenu      : "",
                activeSubMenu   : "",
                lastMenu        : "",
                dataMenu        : [],
                users           : [],
                form : {
                    old_password        : '',
                    new_password        : '',
                    retype_new_password : '',
                },
                submitted   : false,
                waiting     : false
            }
        },
        validations: {
            form: {
                old_password: {
                    required
                },
                new_password: {
                    required
                },
                retype_new_password: {
                    required
                },
            }
        },
        created() {
          this.fetchLocalData();
        },
        methods: {
            setCurrent: function(params = "") {
                this.activeClass    = params;
                this.removeSidebar();
            },
            getMenu: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/menu';
                
                this.$http.get(uri).then(response => {
                    this.dataMenu   = response.data.list_menu;
                    this.lastMenu   = response.data.last_menu;

                    let app = document.createElement('script')
                    app.setAttribute('src', 'js/appAdmin.js')                    
                    document.head.appendChild(app)
                });
            },
            fetchLocalData: function() {
                this.users.name = localStorage.getItem('name');
                this.users.role = localStorage.getItem('role');
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/profile/change_password', {
                        old_password        : this.form.old_password,
                        new_password        : this.form.new_password,
                        retype_new_password : this.form.retype_new_password,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.form.old_password        = '';
                                    this.form.new_password        = '';
                                    this.form.retype_new_password = '';
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            this.waiting = false;
                        }
                    })
                }
            },
            toggleSidebar() {
              const el = document.body;
              const sidebar = document.getElementById('sidebar-toggler');
              const className = 'sidebar-folded';

              if (!el.classList.contains(className)) {
                  el.classList.add(className);
                  sidebar.classList.add('active');
              } else {
                  el.classList.remove(className);
                  sidebar.classList.remove('active');
              }
            },
            removeSidebar() {
              const el = document.body;
              const sidebar = document.getElementById('sidebar-toggler');
              const className = 'sidebar-open';

              if (!el.classList.contains(className)) {
                  el.classList.add(className);
                  sidebar.classList.add('active');
              } else {
                  el.classList.remove(className);
                  sidebar.classList.remove('active');
              }
            }
        }
    }
</script>