import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VuePageTransition from 'vue-page-transition'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueDropify from 'vue-dropify'
import CKEditor from 'ckeditor4-vue'
import VueCountdownTimer from 'vuejs-countdown-timer'
import Multiselect from 'vue-multiselect'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import moment from 'moment'
import store from './store'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import VModal from 'vue-js-modal'

// Internal Component
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'

// Loading component
import LoadingPage from '@/components/LoadingPage.vue'

// Data empty component
import HandlingNull from '@/components/HandlingNull.vue'

Vue.config.productionTip = false
Vue.prototype.$http       = axios

// Vue Use
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VuePageTransition)
Vue.use(CKEditor)
Vue.use(VueCountdownTimer)
Vue.use(DatePicker)
Vue.use(VueToast)
Vue.use(VModal, {componentName: 'modal'})
Vue.use(VuePlyr, {
  plyr: {}
})
Vue.component('vue-dropify', VueDropify)
Vue.component('multiselect', Multiselect)
Vue.component('navbar', NavBar)
Vue.component('foot', Footer)
Vue.component('loading-page',LoadingPage)
Vue.component('handling-null',HandlingNull)

Vue.filter('formatDate', function(value, format='MM/DD/YYYY hh:mm') {
  if (value) {
      return moment(String(value)).format(format)
  }
});

// Add Global Auth Token
const token = localStorage.getItem('auth_token_lms')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
