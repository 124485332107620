<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div v-if="dataFetch" class="page-content">
            <p>Please wait...</p>
        </div>
        <div v-if="!dataFetch" class="page-content">
            <!-- List of courses -->
            <div class="row">
                <div class="col-lg-12 col-xl-12">
                    <div class="d-block mb-3">
                        <div class="card rounded">
							<div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <h6 class="card-title mb-0">Lecturer Information</h6>
                                    </div>
                                    <div>
                                        <button :disabled="fetchDownload" type="button" class="btn btn-success" v-on:click="downloadExcel()">
                                            {{fetchDownload ? 'Downloading....':'Download Excel'}}
                                        </button>
                                        <router-link class="btn btn-light ml-2" :to="{name: 'learning_recap'}">Back</router-link>
                                    </div>
                                </div>

								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Year Period:</label>
									<p class="text-muted">{{detail.periode_name}}</p>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Lecturer Name:</label>
									<p class="text-muted">{{detail.fullname}} - {{detail.identity_number }}</p>
								</div>
								<div class="mt-3">
									<label class="tx-11 fw-bolder mb-0 text-uppercase">Total Courses:</label>
									<p class="text-muted"><i class="feather icon-book"></i> {{detail.list_courses.length}}</p>
								</div>
							</div>

                            <div class="card-body">
                                <div class="table-responsive table-bordered">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th class="text-center" width="3%" rowspan="2">#</th>
                                                <th rowspan="2">Courses Name</th>
                                                <th colspan="4" class="text-center">Total Lessons</th>
                                                <th colspan="4" class="text-center">Lessons has been implemented</th>
                                                <th class="text-center" colspan="2">Percentage</th>
                                                <th class="text-center" rowspan="2">Action</th>
                                            </tr>
                                            <tr>
                                                <th class="text-center">Theory</th>
                                                <th class="text-center">Assignment</th>
                                                <th class="text-center">Quiz</th>
                                                <th class="text-center">FGD</th>
                                                
                                                <th class="text-center">Theory</th>
                                                <th class="text-center">Assignment</th>
                                                <th class="text-center">Quiz</th>
                                                <th class="text-center">FGD</th>

                                                <th class="text-center">lecturer attendance</th>
                                                <th class="text-center">student attendance</th>
                                            </tr>
                                        </thead>
                                        <tbody v-if="detail.list_courses.length > 0">
                                            <tr v-for="(item, index) in detail.list_courses" :key="index">
                                                <td class="text-center" width="3%">
                                                    {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="text-dark font-weight-bold tx-15">
                                                            {{item.courses_name}}
                                                            <br v-if="item.co_moderator"/>
                                                            <label v-if="item.co_moderator" class="badge badge-primary mr-2 tx-12 my-0 text-uppercase">Co moderator</label>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.total_theory}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.total_assignment}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.total_quiz}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.total_fgd}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.presence_theory}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.presence_assignment}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.presence_quiz}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.presence_fgd}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.percentage ? item.statistic.percentage+'%' : '-'}}
                                                </td>
                                                <td class="text-center">
                                                    {{item.statistic.percentage_student ? item.statistic.percentage_student+'%' : '-'}}
                                                </td>
                                                <td>
                                                    <button type="button" class="btn btn-primary btn-block mt-2" v-on:click="viewPresence(item.statistic.courses_id)">
                                                        View Presence
                                                    </button>
                                                    <button type="button" class="btn btn-primary btn-sm btn-block mt-2" v-on:click="viewPresenceStudent(item.statistic.courses_id)">
                                                        View Presence Student
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody v-if="detail.list_courses.length < 1">
                                            <tr>
                                                <td colspan="13">
                                                    <div class="d-block p-3 rounded bg-light">
                                                        <div class="text-center">Data not found</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                                    
                                </div>
                            </div>
						</div>                        
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'ViewLearningRecapDetail',
        metaInfo: {
            title: 'View Learning Recap Detail',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination,
        },
        data() {
            return {
                fetchDownload: false,
                dataFetch   : true,
                periode_id  : this.$route.params.periode_id,
                users_id    : this.$route.params.users_id,
                detail      : {},
                sections    : {},
                list_member : [],
                keywords    : '',
                pagination  : {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 30,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getDataByID(this.periode_id, this.users_id);
        },
        methods: {
            getDataByID: function(periode_id, users_id) {
                let uri     = process.env.VUE_APP_APIHOST+'admin/courses/learning-recap/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        id : periode_id,
                        users_id : users_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;
                        let getResponse     = res.data.data;
                        this.detail         = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                history.back();
                            }
                        });
                    }
                });
            },
            downloadExcel: function() {
                this.fetchDownload = true;
                let formData = new FormData();

                formData.append('periode_id', this.periode_id);
                formData.append('users_id', this.users_id);

                this.$http.post(process.env.VUE_APP_APIHOST + 'admin/courses/download-learning-recap-detail',
                    formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    responseType: 'blob'
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `learning-recap-detail.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    this.fetchDownload = false;                    
                })
                .catch(function () {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please check your internet connection.',
                    });
                    this.fetchDownload = false;                    
                    return;
                });
            },
            viewPresence: function(courses_id="") {
                this.$router.push(
                    {
                        name: 'view_presence_teacher',
                        params : {
                            courses_id : courses_id,
                            users_id : this.users_id
                        },
                        query: { tab: 'learning_recap', periode_id:this.periode_id }
                    });
            },
            viewPresenceStudent: function(courses_id="") {
                this.$router.push(
                {
                    name: 'view_reporting_presence_student',
                    params : {
                        courses_id : courses_id
                    }
                });
            }
        }
    }
</script>