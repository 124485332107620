<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <div class="d-block grid-margin">
            <h4 class="mb-3 mb-md-0">List of Courses</h4>
        </div>
        <!-- List of courses -->
        <div class="d-block mt-3 mb-3">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <select class="form-control" v-model="params.program_id" v-on:change="list()">
                                    <option value="">See All Program</option>
                                    <option v-for="row in list_program" :value="row.id" :key="row.id">{{row.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <select class="form-control" v-model="params.periode_id" v-on:change="list()">
                                    <option value="">See All Year</option>
                                    <option v-for="row in list_year" :value="row.id" :key="row.id">{{row.name}} {{row.active == '1' ? '(Active)' : ''}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <select class="form-control" v-model="params.courses_category_id" v-on:change="list()">
                                    <option value="">See All Subject</option>
                                    <option v-for="row in list_course_category" :value="row.id" :key="row.id">{{row.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group mb-0">
                                <input type="text" class="form-control" placeholder="Enter the keyword then press enter ..." v-model="params.keywords" v-on:keyup.enter="list()" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="dataFetch">
            <div class="col-md-12">
                <div class="d-block p-3 rounded bg-light">
                    <div class="text-center">loading data...</div>
                </div>
            </div>
        </div>

        <div class="row" v-if="!dataFetch">
            <div class="col-md-12" v-if="list_course.length > 0">
                <div class="d-block">
                    <div class="row">
                        <div class="col-md-3 mb-3" v-for="row in list_course" :key="row.id">
                            <div class="card">
                                <img v-if="!row.cover" src="images/cover_courses.jpg" class="card-img-top" alt="images/cover_courses.jpg" style="background-size: cover !important;">
                                <img v-if="row.cover" :src="row.cover" class="card-img-top" :alt="row.cover" style="background-size: cover !important;">
                                <div class="card-body p-3">
                                    <h5 class="card-title text-capitalize mb-2 font-1rem">{{row.name}}</h5>
                                    <p class="card-text">{{row.program_study}}</p>
                                    <p class="card-text text-muted">{{row.category}}</p>
                                    <p class="card-text text-muted">{{row.program}}</p>
                                    <div class="card-block mt-2">
                                        <div class="d-flex card-text align-items-center">
                                            <div><i class="feather icon-award wd-15"></i></div>
                                            <div class="mt-1 ml-2">{{row.instructure}}</div>
                                        </div>
                                        <div class="d-flex card-text align-items-center">
                                            <div><i class="feather icon-user wd-15"></i></div>
                                            <div class="mt-1 ml-2">{{row.member}} Parisipant(s)</div>
                                        </div>
                                    </div>
                                    <router-link :to="{name: 'courses_section', params: {id: row.id}}" class="btn btn-primary btn-block mt-2">Enter Course</router-link>
                                    <div class="alert alert-info mt-2 text-center mb-0" v-if="row.co_moderator">
                                        you are registered as co moderator
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12" v-if="list_course.length < 1">
                <div class="card">
                    <div class="d-block p-4 rounded">
                        <div class="text-center">
                            <img src="images/empty-box.svg" class="mx-auto d-block width mb-2" style="width:300px">
                            <p style="font-size:18px">Data not found</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
export default {
    name: 'Courses',
    metaInfo: {
        title: 'Courses',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: true,
            list_course: [],
            list_program: [],
            list_year: [],
            list_course_category: [],
            list_program_study: [],
            params: {
                courses_category_id: '',
                program_id: '',
                program_study_id: '',
                periode_id: '',
                status: '',
                keywords: '',
                limit: 100
            }
        }
    },
    created() {
        this.listProgram();
        this.listYear();
        this.listCourseCategory();
        this.list();
    },
    methods: {
        listProgram: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/program';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_program = res.data.data;
                }
            });
        },
        listYear: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/periode';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_year = res.data.data;

                    this.list_year.forEach(element => {
                        if(element.active == '1') {
                            this.params.periode_id = element.id
                        }
                    });

                    if(this.params.periode_id) {
                        this.list();
                    }
                }
            });
        },
        listCourseCategory: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/courses-category';

            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    this.list_course_category = res.data.data;
                }
            });
        },
        list: function () {
            this.dataFetch      = true;
            this.list_course    = [];
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses/list';

            this.$http.get(uri, {
                params: this.params
            }).then(res => {
                this.dataFetch = false;
                if (res.data.status) {
                    this.list_course = res.data.data;
                }
            });
        },
    }
}
</script>
