<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="row">
                <div class="col-md-4 d-md-block mb-3">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title mb-4">List of Webinar ({{dataList.length}})</h6>
                            <div class="d-block bg-light p-3 rounded my-2 cursor-pointer"
                                v-for="row in dataList" :key="row.id" @click="fetchDetailEvent(row.id)">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="d-block">
                                        <div class="tx-15 text-dark font-weight-bold">{{ row.name }}</div>
                                        <div class="tx-14 text-muted"><i
                                                class="feather icon-calendar mr-2"></i>{{ row.text_date }}</div>
                                    </div>
                                    <i class="feather icon-chevron-right text-secondary tx-20"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="card">
                        <div class="card-body">
                            <FullCalendar :options="calendarOptions"></FullCalendar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal name="detail-event" height="60%">
            <div class="d-block p-4 h-100" :style="{ 'overflow-y': 'auto' }">
                <img :src="detailEvent.cover" class="border-radius-1 w-100 border" v-if="detailEvent.cover">
                <img src="images/cover_courses.jpg" class="border-radius-1 w-100 border" v-if="!detailEvent.cover">
                <div class="h4 mt-3">{{ detailEvent.name }}</div>
                <div class="text-muted mt-1"><i class="feather icon-calendar mr-2"></i>{{ detailEvent.text_date }}</div>
                <div class="border-bottom my-4"></div>
                <div class="d-block text-muted" v-html="detailEvent.description"></div>
                <div class="my-3">
                    <a :href="detailEvent.url" class="btn btn-primary btn-block p-3 tx-16" target="_blank">Open Link</a>
                </div>
            </div>
        </modal>
        <foot></foot>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
export default {
    name: 'Schedule',
    metaInfo: {
        title: 'Schedule',
        titleTemplate: '%s - Elearning'
    },
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data() {
        return {
            dataFetch: true,
            dataList: [],
            detailEvent: {},
            calendarOptions: {
                plugins: [dayGridPlugin, interactionPlugin],
                initialView: 'dayGridMonth',
                eventClick: this.eventDetail,
                events: []
            },
            pagination: {
                currentPage: 1,
                totalPages: 1,
                limit: 10,
            },
        }
    },
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'student/conference/list';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    keywords: this.keywords,
                    page: this.pagination.currentPage,
                    limit: this.pagination.limit
                }
            }).then(res => {
                this.dataFetch = false;
                this.dataList = res.data.data;
                this.pagination.totalPages = res.data.total_page;

                this.calendarOptions.events = [];
                this.dataList.forEach(element => {
                    this.calendarOptions.events.push({ id: element.id, title: element.name, date: element.date })
                });
            });
        },
        eventDetail: function (arg) {
            this.fetchDetailEvent(arg.event.id)
        },
        fetchDetailEvent: function (id) {
            this.$modal.show('detail-event')

            let uri = process.env.VUE_APP_APIHOST + 'student/conference/detail';
            this.$http.get(uri, {
                params: {
                    id: id,
                }
            }).then(res => {
                this.detailEvent = res.data.data;
            });
        },
        randomColor: function () {
            var letters = '0123456789ABCDEF';
            var color = '#';
            for (var i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }
    }
}
</script>