<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="row justify-content-center">
                <div class="col-md-8 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="card-title mb-0">Form Webinar</div>
                                <router-link :to="{ name: 'meeting' }" class="btn btn-light">Back</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-subtitle mt-0">Please complete the form below.</div>
                            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                                <div class="form-group row justify-content-center mt-4">
                                    <div class="col-md-3 mb-3 pb-2 text-center">
                                        <div>
                                            <img :src="preview_image" class="border-radius-1 w-100 border" />
                                        </div>
                                        <input type="file" @change="previewFiles" ref="file"
                                            accept="image/png, image/gif, image/jpeg" class="d-none" />
                                        <button type="button" class="btn btn-outline-secondary btn-block mt-2"
                                            @click="$refs.file.click()">Change Cover</button>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" id="name" name="name"
                                        :class="{ 'is-invalid': submitted && $v.form.name.$error }" v-model="form.name"
                                        placeholder="Enter name ...">
                                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is
                                        required!</div>
                                </div>
                                <div class="form-group">
                                    <label for="url">URL</label>
                                    <input type="text" class="form-control" id="url" name="url" autocomplete="off"
                                        :class="{ 'is-invalid': submitted && $v.form.url.$error }" v-model="form.url"
                                        placeholder="Enter url (sample : https://meet.google.com/xxx-xxx-xxx)...">
                                    <div v-if="submitted && !$v.form.url.required" class="invalid-feedback">URL is
                                        required!</div>
                                </div>
                                <div class="form-group row mb-3">
                                    <div class="col-md-6">
                                        <label for="date">Date</label>
                                        <div class="d-block w-100">
                                            <date-picker v-model="form.date" class="w-100" valueType="format" placeholder="yyyy-mm-dd">
                                            </date-picker>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="date">Time</label>
                                        <div class="d-block w-100">
                                            <date-picker v-model="form.time" :showSecond="false" class="w-100" type="time" format="HH:mm" placeholder="HH:mm"
                                                valueType="format"></date-picker>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="url">Description</label>
                                    <ckeditor v-model="form.description" :config="editorConfig" class="mt-3"></ckeditor>
                                </div>
                                <div class="form-group row">
                                    <div class="col-md-6">
                                        <label for="study_group_id">Study Group</label>
                                        <multiselect v-model="form.study_group_id" :multiple="true" :options="list_study_group" placeholder="Choose study group" :show-labels="false" label="name" track-by="name">
                                            <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                        </multiselect>
                                        <div v-if="submitted && !$v.form.study_group_id.required"
                                            class="invalid-feedback">Study Group
                                            is required!</div>
                                    </div>
                                    <div class="col-md-6">
                                        <label for="status">Status</label>
                                        <select class="form-control" id="status" name="status" v-model="form.status">
                                            <option value="draft">Draft</option>
                                            <option value="publish">Publish</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save</button>
                                    <router-link :to="{ name: 'meeting' }" class="btn btn-light mt-2">Back</router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>
<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'MeetingForm',
    metaInfo: {
        title: 'Form Webinar',
        titleTemplate: '%s - Unfari E-learning'
    },
    created() {
        this.getStudyGroup();
        this.getDataByID(this.$route.params.id);
    },
    data() {
        return {
            id: this.$route.params.id,
            form: {
                status: "draft"
            },
            list_study_group: [],
            preview_image: 'https://via.placeholder.com/513x342',
            editorConfig: {
                toolbar: [
                    ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                ]
            },
            submitted: false,
            waiting: false
        }
    },
    validations: {
        form: {
            name: {
                required
            },
            url: {
                required
            },
            date: {
                required
            },
            time: {
                required
            },
            study_group_id: {
                required
            },
        }
    },
    methods: {
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                let formData = new FormData();

                // get list study group id
                var listStudyGroupId = [];
                for (var keyStudyGroup in this.form.study_group_id) {
                    listStudyGroupId.push(this.form.study_group_id[keyStudyGroup].id)
                }
                this.form.study_group_id = JSON.stringify(listStudyGroupId);

                for (var index in this.form) {
                    formData.append(index, this.form[index]);
                }

                this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/conference/save', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'meeting' });
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

            }
        },
        getDataByID: function (id) {
            if (typeof (id) !== 'undefined' && id !== null) {
                this.label = "Update";

                let uri = process.env.VUE_APP_APIHOST + 'teacher/conference/detail';

                this.dataFetch = true;
                this.$http.get(uri, {
                    params: {
                        id: id
                    }
                }).then(res => {
                    if (res.data.status) {
                        let getResponse = res.data.data;
                        this.form = getResponse;

                        if(this.form.cover != '' && this.form.cover !== null) {
                            this.preview_image = this.form.cover;
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({ name: 'meeting' });
                            }
                        });
                    }
                });
            }
        },
        previewFiles(e) {
            const file = e.target.files[0];
            var fsize = ((file.size / 1024) / 1024).toFixed(4); // MB

            if (fsize >= 5) {
                alert("Uploaded files cannot be more than 5 MB");
            } else {
                this.preview_image = URL.createObjectURL(file);
                this.form.cover = file;
            }
        },
        getStudyGroup: function () {
            let uri = process.env.VUE_APP_APIHOST + 'general/list/study-group';

            this.$http.get(uri, {}).then(res => {
                if (res.data.status) {
                    this.list_study_group = res.data.data;
                }
            });
        },
    }
}
</script>