<template>
<div id="app" :class="$route.meta.class_parent">
    <component :is="$route.meta.layout || 'div'">
    </component>
    <!-- <vue-page-transition> -->
    <router-view>
    </router-view>
    <!-- </vue-page-transition> -->
</div>
</template>

<script>
export default {
    data () {
        return {
            loading : true
        }
    },
    updated() {
        this.setStyle();
    },
    created() {
        if(['signin', 'home'].includes(this.$route.name)) {
            this.setStyle();
        }
    },
    methods: {
        setStyle: function () {
            var role = localStorage.getItem('role');
            var theme = 'theme1';
            if (role && role != 'student' && !['signin', 'home'].includes(this.$route.name)) {
                theme = 'theme2';
            }
            let file = document.createElement('link');
            file.rel = 'stylesheet';
            file.href = 'css/' + theme + '/style.css?v=1.0.0'
            document.head.appendChild(file)
        },
    },
    mounted () {
        setTimeout(() => {
            this.loading = false
        }, 900);
    }
}
</script>
