<template>
<div class="page-wrapper">
    <navbar></navbar>
    <div class="page-content">
        <!-- Top Header Dashboard -->
        <div class="d-flex justify-content-between align-items-center flex-wrap grid-margin">
            <div>
                <h4 class="mb-3 mb-md-0">Welcome to Dashboard</h4>
            </div>
            <div class="d-none align-items-center flex-wrap text-nowrap">
                <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-md-none d-xl-flex" id="dashboardDate">
                    <span class="input-group-addon bg-transparent"><i class="feather icon-calendar text-primary tx-15"></i></span>
                    <input type="text" class="form-control">
                </div>
                <button type="button" class="btn btn-outline-info btn-icon-text mr-2 d-none d-md-block">
                    <i class="btn-icon-prepend feather icon-download tx-15"></i>
                    Import
                </button>
                <button type="button" class="btn btn-outline-primary btn-icon-text mr-2 mb-2 mb-md-0">
                    <i class="btn-icon-prepend feather icon-printer tx-15"></i>
                    Print
                </button>
                <button type="button" class="btn btn-primary btn-icon-text mb-2 mb-md-0">
                    <i class="btn-icon-prepend feather icon-download-cloud tx-15"></i>
                    Download Report
                </button>
            </div>
        </div>

        <!-- Card stastic QUIZ, LESSONS, ASSIGNMENT, EXAM -->
        <div class="row">
            <div class="col-12 col-xl-12 stretch-card">
                <div class="row flex-grow">
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-primary wd-60 ht-60 text-white">
                                        <i class="feather icon-airplay my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h2 class="mt-2 font-weight-bold">{{statistic.total_courses}}</h2>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of courses</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-success wd-60 ht-60 text-white">
                                        <i class="feather icon-file my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h2 class="mt-2 font-weight-bold">{{statistic.total_theory}}</h2>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of materials</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-warning wd-60 ht-60 text-white">
                                        <i class="feather icon-clipboard my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h1 class="mt-2 font-weight-bold">{{statistic.total_assignment}}</h1>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of assignments</h6>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-3 grid-margin stretch-card">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="d-flex mb-3 justify-content-center">
                                    <div class="d-flex justify-content-center align-items-center rounded-circle bg-info wd-60 ht-60 text-white">
                                        <i class="feather icon-server my-0 tx-25"></i>
                                    </div>
                                </div>
                                <div class="d-block">
                                    <h1 class="mt-2 font-weight-bold">{{statistic.total_quiz}}</h1>
                                </div>
                                <h6 class="card-title mb-0 text-capitalize">Number of quiz</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Card list annoucement -->
        <div class="row">
            <div class="col-12 col-xl-12">
                <div class="d-flex mb-3 align-items-center"><i class="mdi mdi-newspaper h4 mr-3"></i>
                    <h5>Announcement</h5>
                </div>
                <div class="d-block stretch-card" v-if="announcement.length > 0">
                    <div class="row flex-grow">
                        <div class="col-lg-4 col-xl-4 grid-margin stretch-card" v-for="row in announcement" :key="row.id">
                            <div class="card">
                                <div class="card-body">
                                    <h6 class="card-title mb-3 text-capitalize">{{row.name}}</h6>
                                    <div class="card-text text-muted text-ellipsis">
                                        {{row.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="announcement.length < 1">
                    <div class="rounded bg-light p-3 mb-3 text-center">
                        Announcement not available at this time.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <foot></foot>
</div>
</template>

<script>
export default {
    name: 'Dashboard',
    metaInfo: {
        title: 'Dashboard',
        titleTemplate: '%s - Elearning'
    },
    components: {},
    data() {
        return {
            dataFetch: true,
            fetchDashboard: true,
            fetchStatistic: true,
            statistic: [],
            announcement: [],
            courses: {
                reguler : [],
                non_reguler : []
            },
        }
    },
    created() {
        this.getStatistic();
        this.getAnnouncement();
    },
    methods: {
        getStatistic: function () {
            this.statistic = [];
            let uri = process.env.VUE_APP_APIHOST + 'admin/dashboard/statistic';

            this.dataFetch = true;
            this.$http.get(uri).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.statistic = getResponse;
                }
            });
        },
        getAnnouncement: function () {
            this.announcement = [];
            let uri = process.env.VUE_APP_APIHOST + 'admin/dashboard/announcement';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    limit: '3'
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.announcement = getResponse;
                }
            });
        },
        getCourses: function () {
            this.courses = [];
            let uri = process.env.VUE_APP_APIHOST + 'admin/dashboard/courses';

            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    limit: '4',
                }
            }).then(res => {
                if (res.data.status) {
                    let getResponse = res.data.data;
                    this.courses = getResponse;
                }
            });
        },
    }
}
</script>
