<template>
<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-body">
                <div class="d-block">
                    <div class="form-group d-flex row">
                        <div class="col">
                            <input type="text" class="form-control" v-model="keywords" placeholder="Search member here ..." v-on:keyup.enter="getData()" />
                        </div>
                        <div class="col-md-auto">
                            <button class="btn btn-outline-secondary" @click="getData()"><i class="feather icon-refresh-ccw mr-2"></i>Reload</button>
                        </div>
                    </div>

                    <div class="d-block p-3 rounded bg-light" v-if="dataFetch">
                        <div class="text-center">loading data...</div>
                    </div>

                    <div class="table-responsive mt-3" v-if="!dataFetch">
                        <table class="table" v-if="list_member.length > 0">
                            <thead>
                                <tr>
                                    <th width="3%">#</th>
                                    <th>Student</th>
                                    <th class="text-center">Correct</th>
                                    <th class="text-center">Wrong</th>
                                    <th class="text-center">Not Marked</th>
                                    <th class="text-center">Empty</th>
                                    <th class="text-center">Score</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in list_member" :key="row.id">
                                    <td>{{index + 1}}</td>
                                    <td>
                                        {{row.identity_number}}<br/>
                                        {{row.name}}
                                    </td>
                                    <td class="text-center">{{row.correct}}</td>
                                    <td class="text-center">{{row.wrong}}</td>
                                    <td class="text-center">{{row.waiting}}</td>
                                    <td class="text-center">{{row.empty}}</td>
                                    <td class="text-center">{{row.total_scored}}</td>
                                    <td width="15%">
                                        <button v-if="!row.manual_scored" type="button" class="btn btn-primary" data-toggle="modal" data-target="#result-modal" @click="resultDetail(row)">See Detail</button>
                                        <button v-if="row.manual_scored" type="button" class="btn btn-warning" data-toggle="modal" data-target="#set-manual-score" @click="setScoreManual(row)">Set Score</button>
                                        <button type="button" class="btn btn-secondary ml-2" @click="resetResult(row.id)">Reset Result Quiz</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="(!dataFetch && emptyState)" class="text-center">
                        <img src="images/empty-box.svg" class="mx-auto d-block width mb-2" style="width:300px">
                        <p style="font-size:18px">Data not found</p>
                    </div>

                    <div class="d-block mt-3" v-if="!dataFetch & list_member.length > 0">
                        <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="result-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Result Detail <strong>{{detail_answer.identity_number}} | {{detail_answer.name}}</strong></h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body bg-light">
                    <div class="card d-flex my-2" v-for="(row, index) in list_answer" :key="row.id">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-10">
                                    <div class="d-flex">
                                        <button type="button" class="btn btn-light tx-17 btn-icon mr-4">{{index + 1}}</button>
                                        <div class="d-block w-100">
                                            <div class="d-block border-bottom pb-3 mb-3">
                                                <div class="text-muted">{{row.type_Text}}</div>
                                                <div class="text-dark tx-16 mt-2" v-html="row.questions"></div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6" v-if="row.type != 'essay'">
                                                    <div class="text-muted">Correct Answer</div>
                                                    <div class="text-dark tx-16" v-if="row.correct_answer" v-html="row.correct_answer"></div>
                                                    <div class="text-danger" v-if="!row.correct_answer"><i class="feather icon-info"></i> The correct answer has not been set</div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="text-muted">Student Answer</div>
                                                    <div class="text-dark tx-16" v-if="row.your_answer" v-html="row.your_answer"></div>
                                                    <div class="text-danger" v-if="!row.your_answer"><i class="feather icon-info"></i> Student did not fill in the answer</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="d-flex justify-content-between rounded bg-light px-3 py-2 mb-2">
                                        <div class="text-dark font-weight-bold tx-14" v-if="row.type == 'multiple_choice'"><i class="feather icon-info mr-1"></i> Multiple Choice</div>
                                        <div class="text-dark font-weight-bold tx-14" v-if="row.type == 'essay'"><i class="feather icon-info mr-1"></i> Essay</div>
                                        <div class="text-dark font-weight-bold tx-14" v-if="row.type == 'match'"><i class="feather icon-info mr-1"></i> Match</div>
                                    </div>
                                    <div class="d-flex justify-content-between rounded bg-light px-3 py-2 mb-3">
                                        <div class="text-muted">Point</div>
                                        <div class="text-dark tx-16">{{row.point}}</div>
                                    </div>
                                    <div class="form-group">
                                        <div class="text-muted">Ratings</div>
                                        <select class="form-control" v-if="row.your_answer && (row.type == 'multiple_choice' || row.type == 'match')" v-model="list_answer[index].scored" @change="saveScored(row.courses_quiz_answer_id, row.courses_quiz_answer_detail_id, row.scored)">
                                            <option :value="row.point">Correct</option>
                                            <option value="0">Wrong</option>
                                        </select>
                                        <input type="number" class="form-control" v-if="row.your_answer && row.type == 'essay'" placeholder="Enter score" v-model="list_answer[index].scored" @blur="saveScored(row.courses_quiz_answer_id, row.courses_quiz_answer_detail_id, row.scored)" v-on:keyup="setScoreEssay(row.point, index)">
                                        <div class="text-gray" v-if="!row.your_answer">Student did not fill in the answer</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <button type="button" class="btn btn-white-naked" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="set-manual-score" tabindex="-1" role="dialog" aria-labelledby="set-manual-score" aria-hidden="true">
        <div class="modal-dialog modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Set Manual Score - {{detail.name}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="text-muted">Score</div>
                            <div class="input-group col-xs-12 mt-2">
                                <input type="number" :max="100" :min="0" class="form-control file-upload-info" v-model="detail.total_scored" placeholder="Insert score here..." v-on:keyup="setScored">
                                <span class="input-group-append">
                                    <button class="file-upload-browse btn btn-primary" type="button" @click="postScoreManual(detail.users_id, detail.total_scored)">Save Score</button>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-3">
                        <button type="button" id="btnCloseModalManual" class="btn btn-light" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import vPagination from 'vue-plain-pagination'

export default {
    name: 'QuizStatus',
    metaInfo: {
        title: 'Quiz Status',
        titleTemplate: '%s - Elearning'
    },
    components: {
        vPagination,
    },
    data() {
        return {
            emptyState  : false,
            dataFetch: true,
            list_member: [],
            list_answer: [],
            keywords: '',
            detail_answer: {},
            pagination: {
                currentPage : 1,
                totalPages  : 1,
                limit       : 50,
                bootstrapPaginationClasses: {
                    ul: 'pagination justify-content-end',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                paginationAnchorTexts: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            },
            detail: {
                scored : 0
            }
        }
    },
    props: ['params', 'fetchDetailClass'],
    created() {
        this.getData();
    },
    methods: {
        getData: function () {
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/result';
            this.dataFetch = true;
            this.$http.get(uri, {
                params: {
                    courses_id          : this.params.courses_id,
                    courses_lessons_id  : this.params.id,
                    with_answer         : '1',
                    page                : this.pagination.currentPage,
                    limit               : this.pagination.limit,
                    keywords            : this.keywords
                }
            }).then(res => {
                this.dataFetch      = false;
                this.emptyState     = false;

                this.list_member = [];
                if (res.data.status) {
                    this.list_member            = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                }

                if(this.list_member.length < 1) {
                    this.emptyState = true;
                }
            });
        },
        resultDetail: function (data) {
            this.detail_answer = data;
            let uri = process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/result-detail';

            this.$http.get(uri, {
                params: {
                    courses_id: this.params.courses_id,
                    courses_lessons_id: this.params.id,
                    courses_quiz_answer_id: data.id,
                }
            }).then(res => {
                this.list_answer = [];
                if (res.data.status) {
                    this.list_answer = res.data.data;
                }
            });
        },
        saveScored: function(quiz_answer_id, quiz_answer_detail_id, scored) {
            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/save-scored', {
                    courses_quiz_answer_id: quiz_answer_id,
                    courses_quiz_answer_detail_id: quiz_answer_detail_id,
                    scored: scored
            })
            .then(response => {
                if (response.data.status) {
                    this.$toast.open({
                        type: 'success',
                        message: response.data.message,
                        duration: 1000
                    });
                    this.list();
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                }
            })
        },
        resetResult: function (id) {
            this.$swal({
                icon: 'info',
                title: 'Reset Result Quiz Student',
                text: 'Are you sure you want to reset result quiz ?',
                showCancelButton: true,
                confirmButtonText: 'Reset',
                cancelButtonText: 'Cancel',
            }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/result-reset', {
                        courses_id: this.params.courses_id,
                        courses_lessons_id: this.params.id,
                        courses_quiz_answer_id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.list();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }
                    })
                }
            });
        },
        setScoreEssay: function(point="", index) {
            if(this.list_answer[index].scored > point) {
                this.list_answer[index].scored = point;
            }
        },
        setScoreManual: function (data) {
            this.detail = data;
        },
        setScored: function() {
            if(this.detail.scored > 100) {
                this.detail.scored = 100;
            }

            if(this.detail.scored < 0) {
                this.detail.scored = 0;
            }
        },
        postScoreManual: function (users_id, scored) {
            this.$http.post(process.env.VUE_APP_APIHOST + 'teacher/courses-lessons/quiz/save-scored-manual', {
                users_id            : users_id,
                courses_lessons_id  : this.params.id,
                scored              : scored
            })
            .then(response => {
                if (response.data.status) {
                    this.$swal({
                        icon: 'success',
                        title: 'Success',
                        text: response.data.message,
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.value) {
                            document.getElementById("btnCloseModalManual").click();                                
                            this.getData();
                        }
                    });
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: response.data.message,
                    });
                }
            })
        }
    }
}
</script>
