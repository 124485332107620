<template>
    <div>
        <div v-if="dataFetch" class="page-wrapper">
            <div class="page-content">
                <div class="row">
                    <div class="col-lg-12 col-xl-12">
                        <div class="d-block mb-3">
                            <p>Loading...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!dataFetch" class="page-wrapper">
                <div class="page-content">
                    <div v-if="!detail.data_presence.id" class="alert alert-warning">
                    <p><i class="mdi mdi-information mr-2"></i> You haven't made an presence.</p>
                </div>

                <div v-if="detail.data_presence.id">
                    <div class="alert" :class="detail.data_presence.presence == '1' ? 'alert-success':'alert-danger'">            
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <p v-if="detail.data_presence.presence == '1'"><i class="feather icon-check"></i> You've been presence in this lesson.</p>
                                <p v-if="detail.data_presence.presence == '0'"><i class="feather icon-x"></i> You are not present in this lesson.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- List of courses -->
                <div class="row">
                    <div class="col-lg-12 col-xl-12">
                        <div class="d-block mb-3">
                            <div class="card">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="d-block">
                                            <h6 id="default">{{detail.name}}</h6>
                                            <p class="text-muted tx-14 text-uppercase" v-if="detail.type != 'fgd'">{{detail.format}}</p>
                                            <p class="text-muted tx-14 text-uppercase" v-if="detail.type == 'fgd'">FGD</p>
                                        </div>
                                        <div class="d-flex align-items-center justify-content-end w-25">
                                            <router-link class="btn btn-light" :to="{name: 'learningpath', params: {id: courses_id}}">Back</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="d-block rounded bg-light p-3 mb-3" v-if="detail.note"><span class="font-weight-bold">Note : </span> {{detail.note}}</div>
                                        <a :href="detail.webinar.url" v-if="detail.type == 'fgd'" target="_blank">
                                            <div class="d-flex justify-content-between rounded bg-light p-3 align-items-center">
                                                <div class="d-block">
                                                    <div class="text-muted">
                                                        <div class="d-block">
                                                            Date: {{detail.webinar.date | formatDate('MMM, DD YYYY hh:mm')}}
                                                        </div>
                                                        <div class="d-block">
                                                            Invitation Link: {{detail.webinar.invitation_link}}
                                                        </div>
                                                    </div>
                                                    <div class="d-flex align-items-center">
                                                        <i class="mdi mdi-link tx-16 mr-2"></i>{{detail.webinar.url}}
                                                    </div>
                                                </div>
                                                <div class="font-weight-bold">
                                                    Visit URL
                                                </div>
                                            </div>
                                        </a>
                                        <p class="text-justify" v-if="detail.format == 'text'" v-html='detail.description'></p>
                                        <div v-if="detail.attachment">
                                            <iframe :src="detail.attachment" v-if="detail.format == 'file'" width="100%" height="1000"></iframe>
                                        </div>
                                        
                                        <div v-if="detail.format == 'youtube'" class="col-md-12">
                                            <vue-plyr>
                                                <div class="plyr__video-embed">
                                                    <iframe
                                                    :src="detail.description"
                                                    allowfullscreen
                                                    allowtransparency
                                                    allow="autoplay"
                                                    ></iframe>
                                                </div>
                                            </vue-plyr>
                                        </div>
                                        
                                        <div v-if="detail.format == 'video'" class="col-md-12">
                                            <vue-plyr v-if="detail.attachment" class="mt-3">
                                                <video
                                                    controls
                                                    crossorigin
                                                    playsinline>
                                                    <source
                                                        size="1080"
                                                        :src="detail.attachment"
                                                        type="video/mp4"
                                                    />
                                                    </video>
                                            </vue-plyr>
                                        </div>
                                        <div v-if="detail.format == 'audio'" class="col-md-12">
                                            <vue-plyr>
                                                <audio controls crossorigin playsinline>
                                                    <source
                                                        size="1080"
                                                        :src="detail.attachment"
                                                    />
                                                </audio>
                                            </vue-plyr>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> <!-- row -->
            </div>
            <foot></foot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ViewMaterial',
        metaInfo: {
            title: 'Detail Material',
            titleTemplate: '%s - Elearning'
        },
        components: {},
        data() {
            return {
                dataFetch: true,
                courses_id: this.$route.params.courses_id,
                courses_lessons_id: this.$route.params.id,
                detail: {}
            }
        },
        created() {
            this.getDataByID(this.courses_id, this.courses_lessons_id);
        },
        methods: {
            getDataByID: function(courses_id, id) {
                let uri     = process.env.VUE_APP_APIHOST+'student/courses-lessons/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        courses_id : courses_id,
                        courses_lessons_id : id
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataFetch      = false;
                        let getResponse     = res.data.data;
                        this.detail         = getResponse;
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'learningpath', params: {id: this.courses_id}                                    
                                });
                            }
                        });
                    }
                });
            },
        }
    }
</script>