<template>
    <div class="card-body py-5 text-center">
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    mounted() {
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('auth_token_lms');
        axios.defaults.headers.common['Authorization'] = '';
        location.href='/'
    }
}
</script>