<template>
    <div class="page-wrapper">
        <navbar></navbar>
        <div class="page-content">
            <div class="row justify-content-center">
                <div class="col-md-8 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="card-title mb-0">Form Study Group</div>
                                <router-link :to="{name: 'study_group'}" class="btn btn-light">Back</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div class="card-subtitle mt-0">Please complete the form below.</div>
                            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                                
                                <div class="form-group">
                                    <label for="program_study_id">Study Program</label>
                                    <multiselect v-model="form.program_study_id" :options="list_study_program" placeholder="Choose Study Program" :show-labels="false" label="name" track-by="name" :class="{ 'is-invalid': submitted && $v.form.program_study_id.$error }">
                                        <template slot="option" slot-scope="props"><strong>{{ props.option.name }}</strong></template>
                                    </multiselect>
                                    <div v-if="submitted && !$v.form.program_study_id.required" class="invalid-feedback">Study Program is required!</div>
                                </div>
                                <div class="form-group">
                                    <label for="code">Code</label>
                                    <input type="text" class="form-control" id="code" name="code" autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.code.$error }" v-model="form.code" placeholder="Enter code ...">
                                    <div v-if="submitted && !$v.form.code.required" class="invalid-feedback">Code is required!</div>
                                </div>
                                <div class="form-group">
                                    <label for="name">Name</label>
                                    <input type="text" class="form-control" id="name" name="name" :class="{ 'is-invalid': submitted && $v.form.name.$error }" v-model="form.name" placeholder="Enter name ...">
                                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Name is required!</div>
                                </div>
                                <div class="d-flex justify-content-end">
                                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save</button>
                                    <router-link :to="{name: 'study_group'}" class="btn btn-light mt-2">Back</router-link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <foot></foot>
    </div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'StudyGroupForm',
        metaInfo: {
            title: 'Form Study Group',
            titleTemplate: '%s - Unfari E-learning'
        },
        components : {
        },
        created() {
            this.getDataByID(this.$route.params.id);
            this.getStudyProgram();
        },
        data() {
            return {
                id              : this.$route.params.id,
                form : {
                    id          : "",
                    program_study_id    : [],
                    code        : "",
                    name        : "",
                },
                list_study_program : [],
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                program_study_id: {
                    required
                },
                code: {
                    required
                },
                name: {
                    required
                },
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Please complete the form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();
                    this.form.program_study_id = this.form.program_study_id['id'];
                    
                    for (var index in this.form) {
                        formData.append(index, this.form[index]);
                    }

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/study-group/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'study_group' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Update";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/study-group/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse = res.data.data;
                            this.form       = getResponse;
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'study_group' });
                                }
                            });
                        }
                    });
                }
            },
            getStudyProgram: function() {
                let uri     = process.env.VUE_APP_APIHOST+'admin/program-study/list';

                this.$http.get(uri,{
                }).then(res => {
                    if(res.data.status) {
                        this.list_study_program = res.data.data;
                    }
                });
            }
        }
    }
</script>