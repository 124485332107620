<template>
	<div class="horizontal-menu">
		<nav class="navbar top-navbar">
			<div class="container">
				<div class="navbar-content">
					<button class="navbar-toggler navbar-toggler-left mr-3 d-lg-none align-self-center border" type="button" data-toggle="horizontal-menu-toggle" v-on:click="expandMenu()">
						<i class="feather icon-menu"></i>
					</button>
					<div class="navbar-brand" v-on:click="setCurrent('dashboard')">
						<router-link :to="{name: 'dashboard'}">
							<img class="img-fluid wd-100" src="images/logo.png" />
						</router-link>
					</div>
					<ul class="navbar-nav">
						<li class="nav-item dropdown nav-notifications">
							<a class="nav-link dropdown-toggle" href="#" id="notificationDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i class="feather icon-bell tx-20"></i>
								<div class="indicator">
									<div class="circle"></div>
								</div>
							</a>
							<div class="dropdown-menu" aria-labelledby="notificationDropdown">
								<div class="dropdown-header d-flex align-items-center justify-content-between">
									<p class="mb-0 font-weight-medium">Notifications</p>
									<!-- <a href="javascript:;" class="text-muted">Clear all</a> -->
								</div>
								<div class="dropdown-body">
									<!--a href="javascript:;" class="dropdown-item">
										<div class="icon">
											<i data-feather="user-plus"></i>
										</div>
										<div class="content">
											<p>New customer registered</p>
											<p class="sub-text text-muted">2 sec ago</p>
										</div>
									</a>
									<a href="javascript:;" class="dropdown-item">
										<div class="icon">
											<i data-feather="gift"></i>
										</div>
										<div class="content">
											<p>New Order Recieved</p>
											<p class="sub-text text-muted">30 min ago</p>
										</div>
									</a>
									<a href="javascript:;" class="dropdown-item">
										<div class="icon">
											<i data-feather="alert-circle"></i>
										</div>
										<div class="content">
											<p>Server Limit Reached!</p>
											<p class="sub-text text-muted">1 hrs ago</p>
										</div>
									</a>
									<a href="javascript:;" class="dropdown-item">
										<div class="icon">
											<i data-feather="layers"></i>
										</div>
										<div class="content">
											<p>Apps are ready for update</p>
											<p class="sub-text text-muted">5 hrs ago</p>
										</div>
									</a>
									<a href="javascript:;" class="dropdown-item">
										<div class="icon">
											<i data-feather="download"></i>
										</div>
										<div class="content">
											<p>Download completed</p>
											<p class="sub-text text-muted">6 hrs ago</p>
										</div>
									</a-->
									<div class="dropdown-item tx-8 p-3 text-muted">No notifications at this time</div>									
								</div>
								<!--div class="dropdown-footer d-flex align-items-center justify-content-center">
									<a href="javascript:;">View all</a>
								</div-->
							</div>
						</li>
						<li class="nav-item dropdown nav-profile">
							<a class="nav-link dropdown-toggle" href="#" id="profileDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<img src="images/default_user.png" alt="profile">
							</a>
							<div class="dropdown-menu" aria-labelledby="profileDropdown">
								<div class="dropdown-header d-flex flex-column align-items-center">
									<div class="figure mb-3">
										<img src="images/default_user.png" alt="">
									</div>
									<div class="info text-center">
										<p class="name font-weight-bold mb-0">{{users.name}}</p>
										<p class="email text-muted mb-3 text-capitalize">{{users.role}}</p>
									</div>
								</div>
								<div class="dropdown-body">
									<ul class="profile-nav p-0 pt-3">
										<li class="nav-item">
											<router-link class="nav-item" :to="{name: 'my_profile_student'}">
												<div class="nav-link">
													<i class="feather icon-user mr-2"></i>
													<span>My Profile</span>
												</div>
											</router-link>
										</li>
										<li class="nav-item">
											<router-link class="nav-link" :to="{name: 'signout'}">
												<i class="feather icon-log-out mr-2"></i>
												<span>Log Out</span>
											</router-link>
										</li>
									</ul>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
		<nav class="bottom-navbar" :class="toggledMenu">
			<div class="container">
				<ul class="nav page-navigation">
					<li class="nav-item" :class="activeClass == 'dashboard' ? 'active' : ''" v-on:click="setCurrent('dashboard')">
						<router-link class="nav-link" :to="{name: 'dashboard'}">
							<i class="link-icon feather icon-grid tx-20"></i>
							<span class="menu-title mt-1">Dashboard</span>
						</router-link>
					</li>
					<li class="nav-item" :class="activeClass == 'courses' ? 'active' : ''" v-on:click="setCurrent('courses')">
						<router-link class="nav-link" :to="{name: 'courses'}">
							<i class="link-icon feather icon-airplay tx-20"></i>
							<span class="menu-title mt-1">Courses</span>
						</router-link>
					</li>
					<li class="nav-item" :class="activeClass == 'schedule' ? 'active' : ''" v-on:click="setCurrent('schedule')">
						<router-link class="nav-link" :to="{name: 'schedule'}">
							<i class="link-icon feather icon-calendar tx-20"></i>
							<span class="menu-title mt-1">Schedule</span>
						</router-link>
					</li>
					<li class="nav-item" :class="activeClass == 'webinar' ? 'active' : ''" v-on:click="setCurrent('webinar')">
						<router-link class="nav-link" :to="{name: 'webinar'}">
							<i class="link-icon feather icon-video tx-20"></i>
							<span class="menu-title mt-1">Webinar</span>
						</router-link>
					</li>
					<li class="nav-item" :class="activeClass == 'reports' ? 'active' : ''" v-on:click="setCurrent('reports')">
						<router-link class="nav-link" :to="{name: 'reports'}">
							<i class="link-icon feather icon-clipboard tx-20"></i>
							<span class="menu-title mt-1">Reports</span>
						</router-link>
					</li>
					<li class="nav-item" :class="activeClass == 'presence_report' ? 'active' : ''" v-on:click="setCurrent('presence_report')">
						<router-link class="nav-link" :to="{name: 'presence_report'}">
							<i class="link-icon feather icon-users tx-20"></i>
							<span class="menu-title mt-1">Presence Report</span>
						</router-link>
					</li>
				</ul>
			</div>
		</nav>
	</div>
</template>
<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'Main',
        data() {
            return {
                activeClass     : this.$route.name,
				toggledMenu		: "",
                activeMenu      : "",
                activeSubMenu   : "",
                lastMenu        : "",
                dataMenu        : [],
                users           : [],
                form : {
                    old_password        : '',
                    new_password        : '',
                    retype_new_password : '',
                },
                submitted   : false,
                waiting     : false
            }
        },
        validations: {
            form: {
                old_password: {
                    required
                },
                new_password: {
                    required
                },
                retype_new_password: {
                    required
                },
            }
        },
        created() {
        	this.fetchLocalData();
        },
        methods: {
			fetchLocalData: function() {
				this.users.name = localStorage.getItem('name');
				this.users.role = localStorage.getItem('role');
			},
            setCurrent: function(params = "") {
                this.activeClass    = params;
				this.toggledMenu 	= '';
            },
            getMenu: function() {
                let uri = process.env.VUE_APP_APIHOST+'user/menu';
                
                this.$http.get(uri).then(response => {
                    this.dataMenu   = response.data.list_menu;
                    this.lastMenu   = response.data.last_menu;

                    let app = document.createElement('script')
                    app.setAttribute('src', 'js/appAdmin.js')                    
                    document.head.appendChild(app)
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'user/profile/change_password', {
                        old_password        : this.form.old_password,
                        new_password        : this.form.new_password,
                        retype_new_password : this.form.retype_new_password,
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.waiting    = false;
                                    this.submitted  = false;
                                    document.getElementById("btnCloseModal").click();
                                    this.form.old_password        = '';
                                    this.form.new_password        = '';
                                    this.form.retype_new_password = '';
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                            this.waiting = false;
                        }
                    })
                }
            },
			expandMenu: function() {
				if (this.toggledMenu == '') {
					this.toggledMenu = 'header-toggled';
				} else {
					this.toggledMenu = '';
				}
			}
        }
    }
</script>