<template>
    <div class="card">
        <div class="card-body">
            <div class="card-subtitle mt-0">Please complete the form below.</div>
            <form class="forms-sample" v-on:submit.prevent="onSubmit">
                <div class="form-group">
                    <label for="identity_number">Identity Number</label>
                    <input type="text" class="form-control" id="identity_number" name="identity_number"
                        autocomplete="off" :class="{ 'is-invalid': submitted && $v.form.identity_number.$error }"
                        v-model="form.identity_number" placeholder="Enter Identity Number ...">
                    <div v-if="submitted && !$v.form.identity_number.required" class="invalid-feedback">Identity Number
                        is required!</div>
                    <div class="alert alert-info mt-2"><i class="mdi mdi-information mr-2"></i>Please enter a unique
                        Identity Number, this will be used as your primary username</div>
                </div>
                <div class="form-group">
                    <label for="fullname">Name</label>
                    <input type="text" class="form-control" id="fullname" name="fullname"
                        :class="{ 'is-invalid': submitted && $v.form.fullname.$error }" v-model="form.fullname"
                        placeholder="Enter name ...">
                    <div v-if="submitted && !$v.form.fullname.required" class="invalid-feedback">Name is required!</div>
                </div>
                <div class="form-group">
                    <label for="gender">Gender</label>
                    <select class="form-control w-25" id="gender" name="gender"
                        :class="{ 'is-invalid': submitted && $v.form.gender.$error }" v-model="form.gender">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <div v-if="submitted && !$v.form.gender.required" class="invalid-feedback">Gender is required!</div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="place_birth">Place Birth</label>
                        <input type="text" class="form-control" id="place_birth" name="place_birth"
                            v-model="form.place_birth" placeholder="Enter place birth ...">
                    </div>
                    <div class="col-md-6">
                        <label for="date_birth">Date Birth</label>
                        <div class="d-block">
                            <date-picker v-model="form.date_birth" class="w-100" valueType="format"></date-picker>
                        </div>
                    </div>
                </div>
                <div class="form-group row pt-0 mb-2">
                    <div class="col-md-6">
                        <label for="email">Email</label>
                        <input type="text" class="form-control" id="email" name="email"
                            :class="{ 'is-invalid': submitted && $v.form.email.$error }" v-model="form.email"
                            placeholder="Enter email ...">
                        <div v-if="submitted && !$v.form.email.required" class="invalid-feedback">Email is required!
                        </div>
                        <div class="alert alert-info mt-2"><i class="mdi mdi-information mr-2"></i>This will be used as
                            your secondary username</div>
                    </div>
                    <div class="col-md-6">
                        <label for="religion">Religion</label>
                        <select class="form-control" id="religion" name="religion" v-model="form.religion">
                            <option value="">Choose Religion</option>
                            <option value="islam">Islam</option>
                            <option value="hindu">Hindu</option>
                            <option value="buddha">Buddha</option>
                            <option value="kristen">Kristen</option>
                            <option value="katolik">Katholik</option>
                            <option value="lainnya">Lainnya</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row pt-0">
                    <div class="col-md-6">
                        <label for="phone">Phone Number</label>
                        <input type="text" class="form-control" id="phone" name="phone"
                            :class="{ 'is-invalid': submitted && $v.form.phone.$error }" v-model="form.phone"
                            placeholder="Enter phone ...">
                        <div v-if="submitted && !$v.form.phone.required" class="invalid-feedback">Phone is required!
                        </div>
                    </div>
                    <div class="col-md-6">
                        <label for="postal_code">Postal Code</label>
                        <input type="text" class="form-control" id="postal_code" name="postal_code"
                            v-model="form.postal_code" placeholder="Enter postal_code ...">
                    </div>
                </div>
                <div class="form-group">
                    <label for="address">Address</label>
                    <ckeditor :config="editorConfig" v-model="form.address" class="mt-3"></ckeditor>
                </div>
                <div class="d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary mr-2 mt-2">Save & Next</button>
                    <router-link :to="{ name: 'student' }" class="btn btn-light mt-2">Back</router-link>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import {
    required
} from "vuelidate/lib/validators";

export default {
    name: 'Profile',
    metaInfo: {
        title: 'Form Student',
        titleTemplate: '%s - Unfari E-learning'
    },
    props: {
        form: Object
    },
    created() {
    },
    data() {
        return {
            submitted: false,
            waiting: false,
            editorConfig: {
                toolbar: [{
                    name: 'basicstyles',
                    items: ['Bold', 'Italic', 'Strike', 'Subscript', 'Superscript', '-', 'RemoveFormat',
                        'SpecialChar', 'EqnEditor', 'PasteFromWord'
                    ]
                }, {
                    name: 'paragraph',
                    items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote']
                }, {
                    name: 'links',
                    items: ['Link', 'Unlink']
                }, {
                    name: 'insert',
                    items: ['Image', 'EmbedSemantic', 'Table']
                }, {
                    name: 'tools',
                    items: ['Maximize']
                }],
                extraPlugins: 'autoembed,embedsemantic,specialchar,image2,uploadimage,uploadfile,widget',
                imageUploadUrl: process.env.VUE_APP_APIHOST + 'uploader/images',
                uploadUrl: process.env.VUE_APP_APIHOST + 'uploader/files',
            }
        }
    },
    validations: {
        form: {
            identity_number: {
                required
            },
            fullname: {
                required
            },
            gender: {
                required
            },
            phone: {
                required
            },
            email: {
                required
            },
        }
    },
    methods: {
        onSubmit: function () {
            this.waiting = true;
            this.submitted = true;

            // stop here if form is invalid
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$swal({
                    icon: 'warning',
                    title: 'Ups!',
                    text: 'Please complete the form',
                });

                this.waiting = false;
                return;
            } else {
                this.$emit("listenerChild", this.form);
            }
        },
    }
}
</script>
