<template>
    <div class="page-wrapper">
        <div class="page-content">
            <div class="d-block mb-3">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex align-items-center">
                            <input type="text" class="form-control w-100" placeholder="Enter the keyword then press enter ..." v-on:keyup.enter="getData()" v-model="keywords"/>
                        </div>
                    </div>
                </div>
            </div>
            
            <div v-if="dataFetch" class="row">            
                <div class="col-md-12 mb-3">
                    Loading...
                </div>
            </div>
            <div v-if="!dataFetch" class="row">
                <div v-if="dataList.length > 0" v-for="(item, index) in dataList" :key="index" class="col-md-3 mb-3">
                    <div class="card">
                        <img v-if="!item.cover" src="images/cover_courses.jpg" class="card-img-top" alt="images/cover_courses.jpg">
                        <img v-if="item.cover" :src="item.cover" class="card-img-top" :alt="item.cover">
                        <div class="card-body p-3">
                            <h5 class="card-title text-capitalize mb-2 font-1rem text-short">{{item.name}}</h5>
                            <div class="card-block mt-2">
                                <div class="d-flex card-text align-items-center text-short"><div><i class="feather icon-award wd-15"></i></div> <div class="ml-2">{{item.teacher}}</div></div>
                                <div class="d-flex card-text align-items-center text-short mt-1"><div><i class="feather icon-clock wd-15"></i></div> <div class="ml-2">{{item.text_date}} WIB</div></div>
                            </div>
                            <!-- <router-link :to="{name: 'webinar_detail', params : {id : item.id}}" class="btn btn-primary btn-block mt-2">Enter Webinar</router-link> -->
                            <a :href="item.url" target="blank" class="btn btn-primary btn-block mt-2">Enter Webinar</a>
                        </div>
                    </div>
                </div>

                <div v-if="dataList.length < 1" class="col-md-12">
                    <div class="rounded bg-light p-3 mb-3 text-center">
                        {{keywords ? "Data not found":"You haven't taken any conference for now."}}
                    </div>
                </div>
            </div>

            <div v-if="dataList.length > 8" class="d-block mt-3">
                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'
    export default {
        name: 'Webinar',
        metaInfo: {
            title: 'Webinar',
            titleTemplate: '%s - Elearning'
        },
        components: {
            vPagination
        },
        data() {
            return {
                dataFetch: true,
                dataList: [],
                keywords: "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 1,
                    limit       : 8,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'student/conference/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.dataFetch              = false;
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;
                });
            },
            deleteData: function (id) {
                this.$swal({
                    icon: 'info',
                    title: 'Delete Data',
                    text: 'Are you sure you want to delete this data?',
                    showCancelButton: true,
                    confirmButtonText: 'Delete',
                    cancelButtonText: 'Cancel',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/program/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
        }
    }
</script>